<template>
        <v-container>

        <template v-if="!edit">
             <v-btn class="mx-16" fab dark color="success" title="Nuevo paciente" @click="edit=true" absolute right elevation="1">
                            <v-icon dark>mdi-account-plus</v-icon>
             </v-btn>
            <remote-search v-if="!id" ref="brpac" url="/api/pacientes/buscar/" label="Búsqueda de paciente" min="4" placeholder="Escriba el nombre del paciente" @change="onChange" @message="message" :text="text" :disabled="disabled"></remote-search>

        </template>
        <paciente @patient-saved="onSaved" @cancel="edit=false" @message="message" v-else></paciente>
        <v-snackbar v-model="snackbar" :color="sbcolor" :timeout="sbto">
            {{sbmsg}}
            <v-btn color="white" text @click="snackbar = false">
                Enterado
            </v-btn>
        </v-snackbar>
        </v-container>
</template>
<script>

import Paciente from '@/components/Paciente'
import RemoteSearch from '@/components/RemoteSearch'


export default {
    name:"Pacientes",
    components:{
        'remote-search':RemoteSearch,
        Paciente,
    },
    data:()=>({
        snackbar:false,
        sbmsg:'',
        sbcolor:'sucess',
        sbto:3000,
        edit:false,
        id:null,
        name:null,
        text:"name",
        disabled:false
    }),
    computed:{
        usuario: function () {
            return this.$store.state.usuario;
        },

    },
    methods:{
        onChange:function(val){
            if(val){
                this.id=val.id;
                this.name=val.name;
                if(this.usuario.roles.includes("ASI")){
                    this.$router.push({name: "pagos",
                        params: { pacienteId: this.id},
                    });
                }
                else{
                    this.$router.push({
                                name: "planes",
                                params: { pacienteId: this.id},
                            });
                }
            }
        },
        onSaved:function(id){
            this.message('Paciente guardado','success')
        },
        message:function(msg,level){
            this.sbcolor=level
            this.sbmsg=msg
            this.snackbar=true
        }

    }
}
</script>
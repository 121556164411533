export const formatearFechaIsoAMx = (fecha) => {
  if (!fecha) return null;
  const [year, month, day] = fecha.split("-");
  return day + "/" + month + "/" + year;
}

export const formatearDateAMx = (fecha) => {
  if (!fecha) return null;
  return new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(fecha);
}

export const formatearDateTimeIsoAMx = (fecha) => {
  if (!fecha) return null;
  return new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' }).format(new Date(fecha));
}

export const formatearFechaAIso = (fecha) => {
  if (!fecha) return null;
  var tzoffset = fecha.getTimezoneOffset() * 60000;
  var localISOTime = (new Date(fecha.getTime() - tzoffset)).toISOString().slice(0, 10);
  return localISOTime;
}

export const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
}

export const leftPad = (n) => {
  return Math.abs(n) < 10 ? "0" + n : n;
}

export const getIntervals = (placeSelected, date, log) => {
  const inter = parseInt(placeSelected.inter);
  var minTime=null, maxTime=null;
  const selected = new Date(date.split("-")[0], date.split("-")[1]-1, date.split("-")[2]);
  var aux = selected;
  console.log(selected, selected.getDay(), selected.getDate(), selected.getMonth(), selected.getFullYear());
  // min,max for 4day
  var dow, strinifin;
  for (let wd = 0; wd < 4; wd++) {
      dow = String(aux.getDay());
      const horarios = placeSelected[dow];//semana
      if(horarios != null && horarios.length>0) {
          for (let index = 0; index < horarios.length; index++) {
              strinifin = horarios[index] == null ? [] : horarios[index].replace(/:/g, "").split("-");
              if (strinifin.length == 2) {
                  const element = [parseFloat(strinifin[0]), parseFloat(strinifin[1])];
                  if (minTime==null || element[0]<minTime) {
                      minTime=element[0];
                  }
                  if(maxTime==null || element[1]>maxTime) {
                      maxTime=element[1];
                  }
              }
          }
      }
      aux = addDays(aux,1);
  }
  if(minTime==null&&maxTime==null){
    minTime=0; maxTime=2359;
  }
  if (log===true) {
    //console.log(date+" ---min-max del lugar=> " + minTime + " - " + maxTime, "dows=>", dows);
  }
  //const horas = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
  var minutos = ["60"];
  switch (inter) {
      case 30:
          minutos=["00", "30"];
          break;
      case 20:
          minutos=["00", "20", "40"];
          break;
      case 15:
          minutos=["00", "15", "30", "45"];
          break;
  }
  var intervalos = new Array();
  var complete = new Array();
  var minIndex = 0, rowstarttime, rowendtime;
  for (let h = 0; h < 24; h++) {
      for (let m = 0; m < minutos.length; m++) {
          rowstarttime = parseFloat(h+""+minutos[m]);
          if (m+1 == minutos.length) {//is-last-min
              rowendtime = (h+1==24) ? 2359 : parseFloat((h+1)+""+minutos[0])
          }else{
              rowendtime = parseFloat(h+""+minutos[m+1])
          }
          if (rowstarttime >= minTime && rowstarttime < maxTime) {
              intervalos.push([rowstarttime, rowendtime]);
              if (intervalos.length == 1) {
                minIndex = complete.length;
              }
          }
          complete.push([rowstarttime, rowendtime]);
      }
  }
  if (log===true) {
    //console.log("items(intervalos)", intervalos.length);
  }
  return { 'first-index': minIndex, 'items': intervalos}
}

export const getFirstInterval = (placeSelected, date) => {
  const obj = getIntervals(placeSelected, date);
  return obj['first-index'];
}

export const getTop = (placeSelected, date) => {
  var pixeles = '-50';
  const intervalos = getIntervals(placeSelected, date).items;
  const height=60;
  const n = new Date();
  let currTime = parseFloat(n.getHours()+new String(leftPad(n.getMinutes())));
  var rowtime0, rowtime1;
  if (intervalos.length>0) {
    for (let index = 0; index < intervalos.length; index++) {
      rowtime0 = intervalos[index][0];
      rowtime1 = intervalos[index][1];
      if(currTime >= rowtime0 && currTime < rowtime1) {
        const plus = ((currTime-rowtime0)*height)/placeSelected.inter;
        pixeles = (index * height)+(plus);
        //console.log(currTime+ " encontrado entre " + rowtime0 + " y " + rowtime1 + " pixeles " + pixeles + " row="+index);
        break;
      }
    }
  }
  return pixeles+'px';
}
<template>
    <v-menu
        v-model="menuFecha"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="fechaFormateada"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules ? rules : []"

            ></v-text-field>
        </template>
        <v-date-picker
            v-model="fecha"
            @input="
                menuFecha = false;
                $emit('input', fecha);
            "
            locale="es"
            :max="maxDate"
            :min="minDate"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import { formatearFechaIsoAMx } from "../utils.js";
export default {
    data: () => ({
        menuFecha: false,
        fecha: "",
    }),
    props: {
        fechaString: String,
        label: String,
        rules: Array,
        minDate:undefined,
        maxDate:undefined
    },
    computed: {
        fechaFormateada() {
            return formatearFechaIsoAMx(this.fechaString);
        },
    },
};
</script>

<template>
    <v-dialog v-model="dialog" max-width="800px" @input="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="headline"> Nuevo asistente </span>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="validform" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="6" lg="6">
                                <v-text-field
                                    v-model="asistente.nombres"
                                    label="* Nombres"
                                    maxlength="32"
                                    autofocus
                                    :rules="[rules.required, rules.formatoNombreInvalido]"
                                    required
                                    ref="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6">
                                <v-text-field
                                    v-model="asistente.apellidos"
                                    label="* Apellidos"
                                    maxlength="64"
                                    :rules="[rules.required, rules.formatoNombreInvalido]"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" lg="6">
                                <v-select
                                    v-model="asistente.genero"
                                    :items="[
                                        { text: 'Masculino', value: 'M' },
                                        { text: 'Femenino', value: 'F' },
                                    ]"
                                    label="* Género"
                                    required
                                    :rules="[rules.generoRequired]"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6" lg="6">
                                <date-picker
                                    label="* Fecha de nacimiento"
                                    :fechaString="asistente.fechaNacimiento"
                                    v-model="asistente.fechaNacimiento"
                                    :rules="[rules.required]"
                                    :maxDate="maxDate"
                                    :minDate="minDate"
                                ></date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" lg="6">
                                <v-text-field
                                    v-model="asistente.telefono"
                                    label="* Teléfono"
                                    :maxlength="maxTel"
                                    :rules="ruleTelefono"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6">
                                <v-text-field
                                    v-model="asistente.direccion"
                                    label="* Dirección"
                                    maxlength="256"
                                    :rules="[
                                                        (v) => !!v || 'Este campo es obligatorio',
                                                        (v) =>
                                                          (v && v.trim().length >= 2) || 'Al menos 2 caracteres',
                                                      ]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" lg="6">
                                <v-text-field
                                    v-model="asistente.correo"
                                    label="* Correo"
                                    maxlength="64"
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6">
                                <div class="d-flex">
                                        <v-switch :disabled="!esEdicion"  v-model="editPwd"></v-switch>
                                        <v-text-field
                                            :disabled="esEdicion && !editPwd"
                                            :label="(!esEdicion || editPwd ? '*' : '') + 'Contraseña'"
                                            placeholder="Contraseña"
                                            :append-icon="showPwdText ? 'mdi-eye' : 'mdi-eye-off'"
                                            type="password"
                                            v-model.trim="asistente.contrasena"
                                            :rules="!esEdicion || editPwd ? [rules.required, rules.min6]:[]"
                                            :type="showPwdText ? 'text' : 'password'"
                                            counter
                                            @click:append="showPwdText = !showPwdText"
                                        ></v-text-field>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('close')">
                    Cancelar
                </v-btn>
                <v-btn color="green darken-1" text @click="guardar" :disabled="!validform">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { Urls } from "@/consts.js";
import DatePicker from "../components/DatePicker.vue";
export default {
    data() {
        return {
            validform: false,
            dialog: true,
            rules: {
                required: function (val) {
                    return !!val || "Campo requerido";
                },
                number: function (val) {
                    return !isNaN(val) || "Formato inválido";
                },
                email: function (val) {
                    if (!val) {
                        return false;
                    }
                    var re = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(val.toLowerCase()) || "Email inválido";
                },
                min6: function (val) {
                    if (!val) return false;
                    return val.length >= 6 || "Por lo menos 6 caracteres";
                },
                formatoNombreInvalido:function(val){
                    if(!val){
                        return false;
                    }

                    return val.length >=3 && /^([a-zA-zñÑáéíóúÁÉÍÓÚ]+\s?)+$/.test(val) || "Sólo se aceptan caracteres, mínimo 3";
                },
                generoRequired:function(val){
                    if(!val) return false;
                    return  val.length>0 || "Campo requerido"

                }
            },
            editPwd: false,
            showPwdText:false,
        };
    },
    components: {
        DatePicker,
    },
    props: {
        asistente: Object,
    },
    computed:{
        ruleTelefono() {
            return this.$store.state.usuario.isoPais == "MX"
            ? [
                (v) =>
                  (v && v.length == 10 && /^[0-9]{8,11}$/.test(v)) ||
                  `Campo requerido, sólo se permiten ${this.minTel} números`,
              ]
            : [
                (v) =>
                  (v &&
                    v.length >= 8 &&
                    v.length <= 11 &&
                    /^[0-9]{8,11}$/.test(v)) ||
                  "Campo requerido, sólo se permiten números",
              ];
        },
        minTel() {
          return this.$store.state.usuario.isoPais == "MX" ? 10 : 8;
        },
        maxTel() {
          //IsoPais
          return this.$store.state.usuario.isoPais == "MX" ? 10 : 11;
        },
        esEdicion() {

            return this.asistente && this.asistente.id;
        },
        minDate: function () {
            var d = new Date();
            d.setYear(d.getYear() - 110); // Máximo 110 años de edad
            return d.toISOString().substring(0, 10);
        },
        maxDate: function () {
            return new Date().toISOString().substring(0, 10); // Nace hoy
        },
    },
    methods: {
        guardar() {

            if (!this.$refs.form.validate()) return;

            var url = Urls.ASISTENTES_URL + "/guardar";
            if(this.esEdicion){
                url= Urls.ASISTENTES_URL + "/update";
            }
            this.$http
                .post(url, this.asistente)
                .then((data) => {

                    if (data != undefined) {
                        if(data > 0){

                            this.$root.showMessage(
                                "Asistente guardado exitosamente", 'success'
                            );
                            this.$emit("success");
                        }
                        else{
                            this.$root.showMessage(
                                "La asistente no pudo guardarse", 'warning'
                            );
                            this.$emit("close");
                        }


                    }else {
                        console.log('Here ---> the response status is not ok');
                    }
                });
        }
    },
    beforeMount(){
        if (this.asistente && this.asistente.id) {
            this.editPwd = false;
        }
        else{

            setTimeout(() => {
                    this.$refs.nombre.focus();
                  }, 1200);
        }
    },
    mounted() {


    },
};
</script>
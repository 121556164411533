<template>
  <div style="width:100%;">


    <!--v-toolbar-title class="text-color-title text--darken-4 text-xs-subtitle-2 text-lg-h5 text-wrap text-left" style="position:fixed">
                  Documentos e imágenes
    </v-toolbar-title-->

        <v-btn
          class="mx-16 mt-12"
          dark
          top
          fixed
          right
          fab
          color="success"
          title="Subir imagen, video ó pdf"
          @click="uploadDialog=true"
        >
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>

    <v-row
      id="inboxrow"
      ref="inboxrow"
      justify="center"
      class="overflow-y-auto-2 mt-10"
      :style="`overflow-y: auto; height:${outerHeight * 0.8}px;max-height:${
        outerHeight * 0.8
      }px;`"
    >
      <v-col cols="12" class="indigo-lighten-5">
        <v-col md="10" offset-md="1" class="blue2">
          <v-row class="">
            <v-col
              v-for="(item, index) in items"
              :key="item.identificador"
              class="d-flex child-flex"
              cols="2"
              ref="container"
            >
              <item-galeria
                :data="item"
                :index="index"
                class="ms-galeria"
                @selected="visualize"
                @delete-it="deleteItem"
              ></item-galeria>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="10" class="d-flex d-align-end">
        <v-progress-linear
          v-if="loading"
          :size="20"
          color="cyan"
          indeterminate
        ></v-progress-linear>
        <v-spacer></v-spacer>
        <v-btn
          v-if="items.length > 0"
          @click="next"
          class="info mr-4"
          :loading="loading"
          :disabled="disableLoadMore"
          >Cargar más</v-btn
        >

      </v-col>
    </v-row>

    <!--Visor de documentos-->
    <v-overlay
          id="adjuntos-visor"
          v-if="documentViewer > 0"
          color="#484848"
          opacity="0.95"
          class=""
          :style="`height:${outerHeight}px;`"
        >
        <div class="d-block maximize-ms">
        <div
          class="d-block text-left pt-2 pl-2 pb-2"
          :height="outerHeight * 0.2"
        >
          <v-btn color="success" title="Cerrar" @click="documentViewer = 0">
            <v-icon>mdi-arrow-left</v-icon> Regresar</v-btn
          >
          <v-btn
            class="ml-12"
            icon
            title="previo"
            :disabled="currentIdx == 0"
            @click="prev_or_next(currentIdx - 1)"
          >
            <v-icon>mdi-arrow-left-circle-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            title="siguiente"
            :disabled="currentIdx == items.length - 1"
            @click="prev_or_next(currentIdx + 1)"
          >
            <v-icon>mdi-arrow-right-circle-outline</v-icon>
          </v-btn>
        </div>
        <v-container fluid>
          <v-row class="fondo-gris-ms mb-0 pb-0">
            <v-col cols="9" class="" id="img-visor" ref="imgVisor">
              <div v-if="adjunto && adjunto.url" class="">
                <video
                  v-if="adjunto.contentType.indexOf('video') > -1"
                  class="d-flex flex-column justify-space-between align-center"
                  width="100%"
                  :height="outerHeight * 0.8"
                  :autoplay="false"
                  controls
                >
                  <source :src="adjunto.url" />
                </video>
                <object
                  width="100%"
                  :height="outerHeight * 0.8"
                  v-else-if="
                    adjunto.contentType.indexOf('application/pdf') > -1
                  "
                  :data="adjunto.url"
                ></object>
                <div
                  v-else-if="adjunto.contentType.indexOf('image/') > -1"
                  width="100%"
                  class=""
                >
                  <v-row class="" dark>
                    <v-col cols="1" class="pa-0 ma-0">
                      <v-slider
                        class="ma-0 pa-0 align-self-content-stretch-"
                        vertical
                        v-model="width"
                        max-width="50"
                        :height="imgvHeight"
                        min="100"
                        :max="maxAnchoParaImagenes()"
                        step="1"
                      ></v-slider>
                    </v-col>
                    <v-col
                      cols="11"
                      class="pa-0 d-flex flex-column justify-space-between align-center"
                    >
                      <v-img
                        ref="gImagen"
                        class="none fondo-gris-ms"
                        @mousemove="mouseover"
                        @mouseleave="estilo = 'display: none;'"
                        contain
                        :aspect-ratio="16 / 9"
                        :width="width"
                        :max-width="imgvWidth"
                        :max-heigth="imgvHeight"
                        :src="adjunto.url"
                      ></v-img>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <v-progress-linear
                  v-if="progreso > 0"
                  v-model="progreso"
                  color="cyan"
                  indeterminate
                >
                </v-progress-linear>
              </div>
            </v-col>
            <v-col cols="3" class="fill-height pt-2 mb-0 pb-0" dark>
              <v-card
                v-if="adjunto"
                :height="outerHeight * 0.8"
                class="overflow-y-auto"
                :max-height="outerHeight * 0.8"
              >
                <div class="d-flex flex-row-reverse" justify="space-around">
                  <v-menu offset-y transition="scale-transition" dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small darken v-bind="attrs" v-on="on">
                        <v-icon> mdi-dots-horizontal-circle-outline </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-btn
                          block
                          depressed
                          v-if="!edit"
                          @click="editar()"
                          title="Editar descripción"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>Editar
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          block
                          depressed
                          v-else
                          @click="cancelar()"
                          title="Cancelar edición de descripción"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>Cancelar edición
                          <v-spacer></v-spacer>
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn block depressed @click="confirmDelete = true"
                          ><v-icon>mdi-trash-can-outline</v-icon
                          >Eliminar<v-spacer></v-spacer
                        ></v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <v-card-title class="title-1 mt-n6">
                  {{ adjunto.nombre }}
                </v-card-title>
                <v-card-subtitle
                  class="text-left pb-0"
                  v-if="adjunto.tipoDocumento"
                >
                  {{ adjunto.tipoDocumento }}
                </v-card-subtitle>
                <v-card-subtitle class="text-left caption pt-0">
                  {{ adjunto.fechaHora }}
                </v-card-subtitle>
                <v-card-subtitle class="text-left">
                  <v-btn
                    small
                    title="Edita la descripción"
                    :color="!edit ? '' : 'info'"
                    @click="editar()"
                  >
                    {{ !edit ? "Editar" : "Edición terminada" }}
                  </v-btn>
                  <v-btn
                    v-if="edit"
                    class="ml-1"
                    small
                    title="Cancelar edición"
                    @click="cancelar()"
                    >Cancelar</v-btn
                  >
                </v-card-subtitle>
                <v-card-text
                  class="text-justify break-line-ms fill-height"
                  v-if="!edit"
                >
                  {{
                    description && adjunto.descripcion != description
                      ? description
                      : adjunto.descripcion
                  }}
                </v-card-text>
                <v-textarea
                  ref="inputDescription"
                  v-else
                  class="ma-1"
                  v-model.trim="description"
                  label="Descripción"
                  counter="256"
                  :rules="rules"
                  outlined
                >
                </v-textarea>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-overlay>




    <v-dialog v-model="uploadDialog" light :persistent="true" max-width="800">
        <file-upload :p="id" @cancel="closeUploadDialog" @add="addFile" :t="total"></file-upload>
    </v-dialog>

    <confirm
          v-if="confirmDelete"
          @cancel="confirmDelete = false"
          @confirm="confirmDelete = false;deleteEvt(null);"
        ></confirm>
  </div>
</template>

<script>
import AdjuntoService from "@/services/adjunto.service.js"
import axios from 'axios'

export default {
  components:{
        'file-upload': () => import('@/components/FileUpload'),
        'item-galeria':() => import('@/components/ItemGaleria'),
         Confirm: () => import("@/components/Confirm.vue")
  },
  data: () => ({
    uploadDialog: false,
    loading:false,
    items: [],
    confirmDelete:false,
    currentIdx: -1,
    disableLoadMore: true,
    documentViewer:0,
    edit: false,
    description: null,
    progreso: 0,
    imgvHeight: 500,
    imgvWidth: 500,
    width: 200,
    magnifying: false,
    lupasize: 200,
    estilo: " display:none; ",
    rules: [
          (v) => (!!v && v.length > 0) || "Descripción requerida",
          (v) => (!!v && v.length < 256) || "Máximo 256 caracteres",
        ],
    total:0
  }),
  computed: {
    outerHeight() {
      return window.outerHeight;
    },
    outerWidth() {
      return window.outerWidth;
    },
    adjunto: function () {
    console.log('adjunto: '+this.items[this.currentIdx].contentType);
        return this.currentIdx >= 0 ? this.items[this.currentIdx] : undefined;
    }

  },
  watch:{
    currentIdx(v) {
        let des = v && v > 0 ? this.items[v].descripcion : "";
        this.descripcion = des;
        if (this.edit) {
          this.cancelar();
        }
      },
      /*items:{
        deep: true,
        immediate: true,
        handler(){
            console.log('items: '+this.total);
             this.$nextTick(() => {
            this.total = this.obtTotal();
            console.log('items after: '+this.total);
            });
        }
      }*/
  },
  methods: {
    visualize:function(identificador){
        let idx = this.findIndex(identificador);
        if (this.currentIdx != idx) {
            this.currentIdx = idx;
        }
        this.documentViewer = 1;
        if (this.items[idx].url || this.items[idx].err != undefined) {
            //Ya fue descargado
        }
        else{
            this.progreso = 1;
            this.download(identificador, idx);
        }
    },
    deleteItem:function(identificador){
        //are you sure?
      let idx = this.findIndex(identificador);
      if (idx >= 0) {
        this.currentIdx = idx;
        this.confirmDelete = true;
      }

    },
    findIndex: function (identificador) {
      return this.items.findIndex(function (t) {
        return t.identificador === identificador;
      });
    },
    loadMore:function(){
    },
    finalized:function(){
        this.loading = false;
        console.log('finalizado');
    },
    getAdjuntos:function() {
      this.loading = true;
      AdjuntoService.getAdjuntos(this.id, this.items.length).then((data)=>{
        if(data){
            let prev = this.items.length;
            Array.prototype.push.apply(this.items, data);
            this.total = this.obtTotal();

            if (prev > 0) this.scrollToBottom();
        }
      }).then(this.finalized);


    },
    addFile:function(obj){
        console.log('addFile: '+obj);
        this.items.unshift(obj);

        this.$root.showMessage("Archivo guardado [ " + obj.nombre + "]");

        this.setNuevoTotal(obj.t + 1);
        this.scrollToTop();
    },
    scrollToBottom: function () {
      setTimeout(() => {
        let sh = this.$el.querySelector("#inboxrow").scrollHeight;
        //let top = this.$el.querySelector("#inboxrow").scrollTop;
        this.$el.querySelector("#inboxrow").scrollTop = sh;
      }, 50);
    },
    scrollToTop: function () {
      this.$nextTick(() => {
        this.$el.querySelector("#inboxrow").scrollTop = 0;
      });
    },
    download:function(identificador, idx){
        axios
          .get("/api/adjuntos/download/" + this.id + "/" + identificador, {
            responseType: "blob",
          })
          .then(
            function (response) {
              var ct = response.headers["content-type"];
              ct = ct.split(";")[0]; //ignorado ; chartset=utf8

              this.items[idx].contentType = ct;
              var blob = new Blob([response.data], { type: ct });
              let url = window.URL.createObjectURL(blob);
              this.items[idx].url = url;
            }.bind(this)
          )
          .catch(
            function (error) {
            console.log('error');
              let msg =
                error.response && error.response.headers && error.response.headers["err-msg"]
                  ? error.response.headers["err-msg"]
                  : error.message
                  ? error.message
                  : error;
              //this.error(msg);
              this.items[idx].err = msg;
            }.bind(this)
          )
          .then(
            function () {
              this.progreso = 0;

            }.bind(this)
          );
    },
    deleteEvt:function(){
        let idx = this.currentIdx;
        let identificador = this.items[idx].identificador;
        AdjuntoService.deleteAdjunto(this.id, identificador).then((data)=>{
              let curr = this.currentIdx;
              if (this.documentViewer > 0 && idx == curr && this.items.length >= 2) {
                //Si el visor está abierto, abrir siguiente o previo adjunto
                //Si es último, ver previo, sino el siguiente
                let newidx = curr == this.items.length - 1 ? curr - 1 : curr + 1;
                this.prev_or_next(newidx);
              }
              this.$root.showMessage("El archivo [" + this.items[idx].nombre + "] ha sido eliminado");

              this.$nextTick(() => {
                const removed = this.items.splice(idx, 1);
                if (this.items.length == 0) {
                  this.documentViewer = 0;
                  this.currentIdx = -1;
                } else {
                  this.setNuevoTotal(this.items[0].t - 1);
                }
              });
        });

    },
    next: function () {
          if (!this.loading) {
            this.getAdjuntos();
          }
    },
    obtTotal: function ()
    {

          return this.items.length > 0 ? this.items[0].t : 0;
    },
    setNuevoTotal(total) {
        console.log('nuevoTotal:'+total);
        this.total = total;
          for (let x = 0; x < this.items.length; x++) {
            this.items[x].t = total;
          }
    },
    maxAnchoParaImagenes: function () {
      return this.imgvWidth * 0.91; //porcentaje de 11 columnas
    },
    editar() {
      if (this.edit) {
        var warning = new Array();
        let param = this.description;
        for (let j = 0; j < this.rules.length; j++) {
          let probado = this.rules[j](param);
          if (!(probado === true)) {
            warning.push(probado);
            break;
          }
        }
        if (warning.length > 0) {
          this.$root.showMessage(warning[0]);
          return;
        }
        let identificador = this.items[this.currentIdx].identificador;
        this.actualizarDescripcion(identificador, param);
      }

      this.edit = !this.edit;
      if (this.edit) {
        this.focusOnDescription();
      }
    },
    focusOnDescription() {
      this.$nextTick(() => {
        this.$refs.inputDescription.focus();
      });
    },
    mouseover(e) {
      if (!this.magnifying) {
        return;
      }
      var hoversize = this.lupasize;
      var radioCursor = 20; //radio cursor-lente
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      pos = this.getCursorPos(e);
      x = pos.x - radioCursor * 1.5; // radioCursor/2;
      y = pos.y - radioCursor * 1.5;
      let img = this.$refs.gImagen["$el"];
      //  offsetWidth,     offsetHeight
      if (x > img.offsetWidth - radioCursor) {
        x = img.offsetWidth - radioCursor;
      }
      if (x < 0) {
        x = 0;
      }
      if (y > img.offsetHeight - radioCursor) {
        y = img.offsetHeight - radioCursor;
      }
      if (y < 0) {
        y = 0;
      }

      var cx = hoversize / radioCursor; //ancho
      var cy = hoversize / radioCursor; //alto
      //result.style.backgroundImage = "url('" + img.src + "')";
      this.estilo = //
        " background-size: " +
        img.offsetWidth * cx + //
        "px " +
        img.offsetHeight * cy +
        "px; " + //
        "  background-position: -" +
        x * cx +
        "px -" +
        y * cy +
        "px; " + //
        "   top:  " +
        pos.clientY +
        "px; " + //
        " left:  " +
        pos.clientX +
        "px; " + //
        " width: " +
        hoversize +
        "px; " + //
        " height:" +
        hoversize +
        "px; "; //
    },
    getCursorPos: function (e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      //console.log(e);
      /*get the x and y positions of the image:*/
      let gimagen = this.$refs.gImagen["$el"];
      let left = gimagen.offsetLeft;
      let top = gimagen.offsetTop;
      x = e.pageX - left;
      y = e.pageY - top;
      ///consider any page scrolling:
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      /* */
      return { x: x, y: y, clientY: e.clientY, clientX: e.clientX };
    },
    actualizarDescripcion(identificador, p_desc) {
        AdjuntoService.updateDescription(this.id, identificador, {descripcion:p_desc}).then((data)=>{
            if(data && data > 0){
                let idx = this.findIndex(identificador);
                if(idx && idx >= 0){
                    this.items[idx].descripcion = "";
                    this.$nextTick(() => {
                      this.items[idx].descripcion = p_desc;
                    });

                }
                this.$root.showMessage("La descripción ha sido actualizada");
            }
            else{
                this.$root.showMessage("La descripción no pudo guardarse");
            }
        });

    },
    cancelar: function () {
      this.$nextTick(() => {
        this.edit = true;
        setTimeout(() => {
          this.description = this.items[this.currentIdx].descripcion;
          this.edit = false;
        }, 100);
      });
    },
    prev_or_next: function (newindex) {
      this.visualize(this.items[newindex].identificador);
    },
    closeUploadDialog(){
        this.uploadDialog= false;
        this.scrollToTop();
    },
  },
  mounted(){
    //this.next();
  },
  beforeMount(){
    this.id = this.$route.params.pacienteId;
    this.next();
  },
  updated() {
    if (this.$refs.imgVisor) {
      if (
        this.$refs.imgVisor.offsetHeight != this.imgvHeight ||
        this.$refs.imgVisor.offsetWidth != this.imgvWidth
      ) {
        this.imgvHeight = this.$refs.imgVisor.offsetHeight;
        this.imgvWidth = this.$refs.imgVisor.offsetWidth;
        this.width = this.maxAnchoParaImagenes() * 0.75; //iniciarlo al 75% porcierto
      }
    }
    if (this.items.length > 0) {
      let d = parseInt(this.items.length) >= parseInt(this.items[0].t);
      if (d != this.disableLoadMore) {
        this.disableLoadMore = d;
      }
    }
  },

}
</script>

<style scoped>
.break-line-ms {
  white-space: pre-line;
}
.v-slider--vertical {
  min-height: 80%;
  top: 0;
  left: 0;
}
.maximize-ms {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.fondo-gris-ms {
  background: rgb(42, 42, 46);
}
.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  position: fixed;
  width: 150px;
  height: 150px;
  margin-top: 2px;
  top: -1000px;
  left: -1000px;
  background-repeat: no-repeat;
  /*border-radius: 50%;*/
}
.none {
  /*cursor: none;*/
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},on:{"input":function($event){return _vm.$emit('close')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.prestacion.id ? "Editar prestación" : "Nueva prestación"))])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",on:{"submit":_vm.guardar}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre de la prestación","maxlength":"128","autofocus":"","rules":[_vm.rules.required]},model:{value:(_vm.prestacion.nombre),callback:function ($$v) {_vm.$set(_vm.prestacion, "nombre", $$v)},expression:"prestacion.nombre"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Precio","maxlength":"11","prefix":"$","rules":[_vm.rules.required, _vm.rules.number]},model:{value:(_vm.prestacion.precio),callback:function ($$v) {_vm.$set(_vm.prestacion, "precio", $$v)},expression:"prestacion.precio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.simbologiaTratamientos,"label":"Simbología","item-text":"nombre","item-value":"id","return-object":"","clearable":""},scopedSlots:_vm._u([_vm._l((['item', 'selection']),function(s){return {key:s,fn:function(data){return [_c(s == 'item' ? 'v-row' : 'div',{key:s,tag:"component",class:s == 'item' ? '' : 'd-flex'},[(
                                                s == 'item' || data.item.id
                                            )?_c(s == 'item'
                                                    ? 'v-col'
                                                    : 'div',{tag:"component",staticClass:"text-center",attrs:{"cols":s == 'item' ? 1 : ''}},[(
                                                    data.item.datos.src &&
                                                    !data.item.datos.text
                                                )?_c('img',{attrs:{"src":data.item.datos.src,"width":"35","height":"15"}}):_vm._e(),(
                                                    data.item.datos.icon &&
                                                    !data.item.datos.text
                                                )?_c('v-icon',{attrs:{"color":data.item.datos.color}},[_vm._v(_vm._s(data.item.datos.icon)+" ")]):_vm._e(),(
                                                    data.item.datos
                                                        .iconCorona
                                                )?_c('v-icon',{attrs:{"color":data.item.datos.stroke}},[_vm._v(_vm._s(data.item.datos .iconCorona)+" ")]):_vm._e(),(data.item.datos.text)?_c('span',{staticClass:"font-weight-bold",style:(data.item.datos.style)},[_vm._v(_vm._s(data.item.datos.text)+" ")]):_vm._e()],1):_vm._e(),_c(s == 'item'
                                                    ? 'v-col'
                                                    : 'div',{tag:"component",staticClass:"align-self-center"},[_c('span',{class:s == 'item' ||
                                                    data.item.id
                                                        ? 'ml-4'
                                                        : ''},[_vm._v(" "+_vm._s(data.item.nombre)+" ")])])],1)]}}})],null,true),model:{value:(_vm.simbologiaTratamiento),callback:function ($$v) {_vm.simbologiaTratamiento=$$v},expression:"simbologiaTratamiento"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.guardar}},[_vm._v(" Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
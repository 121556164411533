var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-html2pdf',{ref:"html2pdf",attrs:{"paginate-elements-by-height":1400,"enable-download":false,"preview-modal":_vm.imprimir,"html-to-pdf-options":{
        margin: 7,
        html2canvas: {
            scale: 3,
            useCORS: true,
            allowTaint: true,
            scrollX: 0,
            scrollY: 0,
        },
        jsPDF: {
            format: 'letter',
            orientation: 'portrait',
        },
    }},on:{"beforeDownload":function($event){return _vm.beforeDownload($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticStyle:{"font-family":"'Roboto', sans-serif"}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"4"}},[_c('img',{staticStyle:{"max-height":"100px","max-width":"250px"},attrs:{"src":_vm.logotipo}})]),_c('v-col',{staticStyle:{"padding-top":"20px"},attrs:{"cols":"8"}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"21px"}},[_vm._v("Plan de tratamiento #"+_vm._s(_vm.plan.id))])])],1),_c('div',{staticStyle:{"margin":"20px 0 20px 10px"}},[_c('div',{staticStyle:{"display":"flex","margin-bottom":"1px"}},[_c('h4',{staticStyle:{"margin-right":"3px"}},[_vm._v("Médico:")]),_c('span',[_vm._v(" "+_vm._s(_vm.usuario.nombres + " " + _vm.usuario.apellidos)+" ")])]),_c('div',{staticStyle:{"display":"flex","margin-bottom":"1px"}},[_c('h4',{staticStyle:{"margin-right":"3px"}},[_vm._v("Paciente:")]),_c('span',[_vm._v(" "+_vm._s(_vm.paciente.nombres + " " + _vm.paciente.apellidos)+" ")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('h4',{staticStyle:{"margin-right":"3px"}},[_vm._v("Fecha:")]),_c('span',[_vm._v(" "+_vm._s(_vm.fecha)+" ")])])]),_c('div',[[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticStyle:{"text-align":"left"}},[_c('th',[_vm._v("Prestación")]),_c('th',[_vm._v("Dientes")]),_c('th',[_vm._v("Total")])])]),_c('tbody',[_vm._l((_vm.prestacionesPlan),function(pp,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(pp.prestacion.nombre))]),(
                                            pp.dientes.find(
                                                (diente) => diente.caras
                                            )
                                        )?_c('td',_vm._l((pp.dientes),function(diente,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente))+" ")])}),0):_c('td',_vm._l((pp.dientes),function(diente,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente) + (i == pp.dientes.length - 1 ? "" : ","))+" ")])}),0),_c('td',[_vm._v(_vm._s("$" + pp.total.toFixed(2)))])])}),_c('tr',[_c('td'),_c('td',{staticStyle:{"font-weight":"bold","padding-right":"80px","text-align":"right"}},[_vm._v(" Total: ")]),_c('td',{staticStyle:{"padding":"20px auto"}},[_vm._v(" "+_vm._s(_vm.getTotalFormateado())+" ")])])],2)]},proxy:true}])})]],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
const MONTHS = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
const PATRON_NUMEROS = /^\d+$/;

const PacienteUtils = {
    str_as_plural:function (entero, singular, contact_as_pural) {
        if(entero == 1)
          return entero + " " + singular;
        else
          return entero + " " + singular+contact_as_pural;
      },
    obt_edad:function(birthday, fechaDeCitaOActual) {
        //const vsFecha = new Date();
        const birth_date = birthday.getDate();
        const birth_month = birthday.getMonth();
        const birth_year = birthday.getFullYear();
        var current_date = fechaDeCitaOActual.getDate();

        var current_month = fechaDeCitaOActual.getMonth();
        var current_year = fechaDeCitaOActual.getFullYear();
        // if birth date is greater then current birth
        // month then do not count this month and add 30
        // to the date so as to subtract the date and
        // get the remaining days
        if (birth_date > current_date) {
          current_date = current_date + MONTHS[birth_month]; //
          //current_month = current_month;/// - 1;
        }
        // if birth month exceeds current month, then do
        // not count this year and add 12 to the month so
        // that we can subtract and find out the difference
        if (birth_month > current_month) {
          current_year = current_year - 1;
          current_month = current_month + 12;
        }
        // calculate date, month, year
        var calculated_date = current_date - birth_date;
        var calculated_month = current_month - birth_month;
        var calculated_year = current_year - birth_year;
        const diasxmonth = 365 / 12;
        const ym = calculated_year * 12;
        const daysinmonths = calculated_date / diasxmonth;
        //console.log(ym); console.log(daysinmonths);
        const edad_en_meses = ym + calculated_month + daysinmonths;
        //console.log("Edad-en-meses" + edad_en_meses);
        //console.log(calculated_year +"<=years |   Edad-en-meses" + calculated_month);

        var decimales =
          calculated_year >= 1.0
            ? (calculated_year + (calculated_month*(1/12)))
            : (((1/12)*edad_en_meses) + /*age.dias*/ ((1/365) * calculated_date));
        decimales = decimales.toFixed(1) * 1;

        var human_str;
        if(calculated_year < 1) {
          if(calculated_month < 1)
            human_str = this.str_as_plural(calculated_date, "día", "s");
          else
            human_str = this.str_as_plural(calculated_month, "mes", "es")+" y "+this.str_as_plural(calculated_date, "día", "s");
        }
        else
          human_str = this.str_as_plural(calculated_year, "año", "s")+" y "+this.str_as_plural(calculated_month, "mes", "es");

        const age = {
          anios: calculated_year,
          meses: calculated_month,
          dias: calculated_date,
          birthday: birthday,
          edad_en_meses: edad_en_meses.toFixed(1)*1,
          edad_con_decimales: decimales,
          human: human_str
        };

        //console.log(age);
        return age;
      },
      getValue:function(val, oldValue, max){
              if(val == null || val.length == 0){
                  return "";
              }
              else if(!PATRON_NUMEROS.test(val)){
                  return oldValue;
              }
              else {
                  if(val.length > max){
                      return val.substring(0, max);
                  }
                  else{
                      return val;
                  }
              }


      }

};

export default PacienteUtils;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-3"},[_c('v-card',{staticClass:"mr-3",attrs:{"tile":""}},[_c('v-navigation-drawer',{attrs:{"width":"262","permanent":""}},[_c('div',{staticClass:"d-flex flex-column justify-space-between fill-height"},[_c('div',[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.editarPaciente}},[_c('v-list-item-content',{staticClass:"text-center"},[_c('v-list-item-title',{staticClass:"title text-wrap"},[_vm._v(" "+_vm._s(_vm.nombreCompleto)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.paciente.years + " años " + (_vm.paciente.months ? _vm.paciente.months + " meses" : "")))]),_c('v-list-item-title',{staticClass:"overline mt-5"},[_vm._v("Fecha de nacimiento")]),_c('v-list-item-subtitle',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.fechaNacimiento))])],1)],1)],1),_c('v-divider'),(_vm.showAllergies || _vm.showDiseases)?_c('v-expansion-panels',{attrs:{"accordion":""}},[(_vm.showAllergies)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-1",attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-subheader',[_vm._v("Alergias"+_vm._s(_vm.textAllergies)+":")])],1),_c('v-expansion-panel-content',[_c('v-row',_vm._l((_vm.allergies),function(allergy){return _c('v-col',{key:allergy.id,staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange darken-2","text-color":"white","x-small":""}},[(
                                                    allergy.catalogoSugeridoId &&
                                                    allergy.catalogoSugeridoId >
                                                        0
                                                )?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-bookmark-multiple-outline ")]):_vm._e(),_c('span',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","font-weight":"bold"},attrs:{"title":allergy.nombre}},[_vm._v(" "+_vm._s(allergy.nombre)+" ")])],1)],1)}),1),(
                                        _vm.otherAllergies &&
                                        _vm.otherAllergies.length > 0
                                    )?_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-caption",attrs:{"title":_vm.otherAllergies}},[_vm._v(" "+_vm._s(_vm.otherAllergies.substring( 0, 128 ))+_vm._s(_vm.otherAllergies.length > 128 ? "..." : "")+" ")])])],1):_vm._e()],1)],1):_vm._e(),(_vm.showDiseases)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-1",attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-subheader',[_vm._v(" Enfermedades que padece"+_vm._s(_vm.textDiseases)+" ")])],1),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"d-flex":""}},_vm._l((_vm.diseases),function(disease,idx){return (disease.show)?_c('v-col',{key:idx,staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"yellow darken-2","text-color":"black","x-small":""}},[_c('span',{staticClass:"text-caption",staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","font-weight":"bold"},attrs:{"title":disease}},[_vm._v(" "+_vm._s(disease.texto)+" ")])])],1):_vm._e()}),1),(
                                        _vm.otherDiseases &&
                                        _vm.otherDiseases.length > 0
                                    )?_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-caption",attrs:{"title":_vm.otherDiseases}},[_vm._v(" "+_vm._s(_vm.otherDiseases.substring( 0, 128 ))+_vm._s(_vm.otherDiseases.length > 128 ? "..." : "")+" ")])])],1):_vm._e()],1)],1):_vm._e()],1):_vm._e(),_c('v-spacer'),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.filteredItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":{
                                    name: item.to,
                                    params: {
                                        pacienteId: _vm.pacId,
                                        tareaId: _vm.tareaId,
                                    },
                                },"exact":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),(_vm.tarea)?_c('div',{staticClass:"ml-6 mr-6"},[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":"Costo consulta","prefix":"$","maxlength":"11","rules":[_vm.rules.required, _vm.rules.number],"disabled":_vm.tarea.status == 'TERMINADA'},model:{value:(_vm.tarea.infoCita.costo),callback:function ($$v) {_vm.$set(_vm.tarea.infoCita, "costo", $$v)},expression:"tarea.infoCita.costo"}})],1)],1):_vm._e(),(_vm.tarea && (!_vm.tarea.infoCita.pagoEfectuado || _vm.tarea.infoCita.facturada != 1))?_c('div',{staticClass:"text-center mt-6 mb-4"},[_c('v-btn',{attrs:{"outlined":"","color":"blue darken-2"},on:{"click":function($event){_vm.tarea.status == 'TERMINADA'
                                ? (_vm.dialogOpciones = true)
                                : _vm.terminarConsulta()}}},[_vm._v(" "+_vm._s(_vm.tarea.status == "TERMINADA" ? "Opciones de consulta" : "Terminar consulta")+" ")])],1):_vm._e()])])],1),_c('v-row',{staticStyle:{"height":"fit-content"}},[_c('v-col',{staticClass:"text-right font-weight-medium mt-1 pb-0",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.getCitaText()))])]),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12"}},[_c('router-view',{attrs:{"fc":_vm.fechaNacimiento},on:{"updated-allergies":_vm.onUpdateAllergies,"updated-diseases":_vm.onUpdateDiseases}})],1)],1),(_vm.tarea && _vm.dialogOpciones && (!_vm.tarea.infoCita.pagoEfectuado || _vm.tarea.infoCita.facturada != 1))?_c('dialog-opciones',{attrs:{"tarea":_vm.tarea},on:{"cobro-efectuado":_vm.setCobroEfectuado,"cancel":_vm.cancelarDialogOpciones}}):_vm._e(),(_vm.tareaId  && _vm.tareaId > 0 && _vm.facturar)?_c('opcion-cfdi',{attrs:{"uuid":_vm.tarea.infoCita.uuid,"typename":_vm.typename},on:{"cancel":function($event){_vm.facturar= false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card class="mx-16 mt-6">
        <v-card-title> Logotipo </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <img
                            ref="logotipo"
                            :src="logotipo"
                            style="max-height: 100px"
                        />
                    </v-col>
                    <v-col cols="12" class="text-center pt-0">
                        <input
                            type="file"
                            ref="fileLogotipo"
                            accept="image/jpeg, image/png"
                            v-show="false"
                            @change="handleFile"
                        />
                        <v-btn
                            text
                            small
                            class="grey lighten-3 primary--text"
                            @click="$refs.fileLogotipo.click()"
                        >
                            <v-icon left dark>mdi-cloud-upload</v-icon>Cambiar
                            logotipo
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { Urls } from "@/consts.js";

export default {
    data: () => ({
        logotipo: "",
    }),
    methods: {
        loadLogotipo() {
            this.$http.get("/api/misc/logotipo").then((data) => {
                if (!data) return;
                this.logotipo = "data:image/png;base64," + data;
            });
        },
        actualizarLogotipo(logotipo) {
            this.$http
                .post("/api/misc/logotipo", btoa(logotipo), {
                    headers: { "Content-Type": "application/json" },
                })
                .then((data) => {
                    if (!data) return;
                    this.loadLogotipo();
                    this.$root.showMessage("Logotipo actualizado exitosamente");
                });
        },
        handleFile(e) {
            let file = e.target.files[0];
            if (!file) return;
            let reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = function (e) {
                this.actualizarLogotipo(e.target.result);
            }.bind(this);
        },
    },
    mounted() {
        this.loadLogotipo();
    },
};
</script>

<template>
    <g>
        <path
            v-if="n != 5"
            :fill="fillLocal || fill || fillDefault"
            stroke="black"
            stroke-width="3"
            :d="d"
            @click="
                $event.stopPropagation();
                seleccionado = !seleccionado;
                $emit('seleccionado');
            "
        />
        <ellipse
            v-else
            stroke="black"
            ry="90"
            rx="90"
            cy="202.95313"
            cx="203.33333"
            stroke-opacity="null"
            stroke-width="3"
            :fill="fillLocal || fill || fillDefault"
            @click="
                $event.stopPropagation();
                seleccionado = !seleccionado;
                $emit('seleccionado');
            "
        />
    </g>
</template>

<style scoped>
path,
ellipse {
    /* stroke: black; */
    stroke-width: 8;
    cursor: pointer;
}
</style>

<script>
export default {
    data() {
        return {
            fillDefault: "white",
            fillLocal: null,
            seleccionado: false,
            fillSeleccionado: "#A7AAAB",
        };
    },
    props: ["n", "d", "fill"],
    watch: {
        seleccionado() {
            this.fillLocal = this.seleccionado
                ? this.fillSeleccionado
                : this.fill || this.fillDefault;
        },
    },
    methods: {},
    mounted() {},
};
</script>
<template>
    <v-dialog v-model="dialog" max-width="400px" @input="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="headline">{{ titulo }}</span>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-container>
                    <v-form ref="form" @submit="enviar">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="correo"
                                    label="Correo"
                                    autofocus
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('close')">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="enviar">
                    Enviar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { Urls } from "@/consts.js";
export default {
    data() {
        return {
            dialog: true,
            rules: {
                required: function (val) {
                    return !!val || "Campo requerido";
                },
                email: function (val) {
                    if (!val) {
                        return false;
                    }
                    var re = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(val.toLowerCase()) || "Email inválido";
                },
            },
            correo: undefined,
        };
    },
    props: ["html2pdf", "titulo"],
    methods: {
        enviar(event) {
            event.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.$emit("enviar", this.correo);
        },
    },
    mounted() {},
};
</script>
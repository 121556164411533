const API_URL = '/api'
export const Urls = Object.freeze({
    PRESTACIONES_URL: API_URL + '/prestaciones',
    DIAGNOSTICOS_URL: API_URL + '/diagnosticos',
    PLANES_URL: API_URL + '/planes',
    EVOLUCIONES_URL: API_URL + '/evoluciones',
    PAGOS_URL: API_URL + '/pagos',
    PACIENTE_URL: API_URL + '/pacientes',
    DOCS_URL: API_URL + '/adjuntos',
    USUARIOS_URL: API_URL + '/usuarios',
    ASISTENTES_URL: API_URL + '/asistentes',
    CITA_URL: API_URL + '/cita',
    AGENDA_URL: API_URL + '/agenda',
    CONSULTORIO_URL: API_URL + '/consultorios',
});

export const ESTADOS = [
    { text: "PENDIENTE", value: 0 },
    { text: "COMPLETADO", value: 1 },
];

export const ESTADOS_DIAGNOSTICO = [
    { text: "NO SOLUCIONADO", value: 0 },
    { text: "SOLUCIONADO", value: 1 },
];

const V = 'Vestibular', M = 'Mesial', P = 'Palatina', L = 'Lingual', O = 'Oclusal', D = 'Distal', I = 'Incisal';
export const DIENTES = Object.freeze({
    18: { 1: V, 2: M, 3: P, 4: D, 5: O },
    17: { 1: V, 2: M, 3: P, 4: D, 5: O },
    16: { 1: V, 2: M, 3: P, 4: D, 5: O },
    15: { 1: V, 2: M, 3: P, 4: D, 5: O },
    14: { 1: V, 2: M, 3: P, 4: D, 5: O },
    13: { 1: V, 2: M, 3: P, 4: D, 5: I },
    12: { 1: V, 2: M, 3: P, 4: D, 5: I },
    11: { 1: V, 2: M, 3: P, 4: D, 5: I },

    21: { 1: V, 2: D, 3: P, 4: M, 5: I },
    22: { 1: V, 2: D, 3: P, 4: M, 5: I },
    23: { 1: V, 2: D, 3: P, 4: M, 5: I },
    24: { 1: V, 2: D, 3: P, 4: M, 5: O },
    25: { 1: V, 2: D, 3: P, 4: M, 5: O },
    26: { 1: V, 2: D, 3: P, 4: M, 5: O },
    27: { 1: V, 2: D, 3: P, 4: M, 5: O },
    28: { 1: V, 2: D, 3: P, 4: M, 5: O },

    55: { 1: V, 2: M, 3: P, 4: D, 5: O },
    54: { 1: V, 2: M, 3: P, 4: D, 5: O },
    53: { 1: V, 2: M, 3: P, 4: D, 5: I },
    52: { 1: V, 2: M, 3: P, 4: D, 5: I },
    51: { 1: V, 2: M, 3: P, 4: D, 5: I },

    61: { 1: V, 2: D, 3: P, 4: M, 5: I },
    62: { 1: V, 2: D, 3: P, 4: M, 5: I },
    63: { 1: V, 2: D, 3: P, 4: M, 5: I },
    64: { 1: V, 2: D, 3: P, 4: M, 5: O },
    65: { 1: V, 2: D, 3: P, 4: M, 5: O },

    85: { 1: V, 2: M, 3: L, 4: D, 5: O },
    84: { 1: V, 2: M, 3: L, 4: D, 5: O },
    83: { 1: V, 2: M, 3: L, 4: D, 5: I },
    82: { 1: V, 2: M, 3: L, 4: D, 5: I },
    81: { 1: V, 2: M, 3: L, 4: D, 5: I },

    71: { 1: V, 2: D, 3: L, 4: M, 5: I },
    72: { 1: V, 2: D, 3: L, 4: M, 5: I },
    73: { 1: V, 2: D, 3: L, 4: M, 5: I },
    74: { 1: V, 2: D, 3: L, 4: M, 5: O },
    75: { 1: V, 2: D, 3: L, 4: M, 5: O },

    48: { 1: V, 2: M, 3: L, 4: D, 5: O },
    47: { 1: V, 2: M, 3: L, 4: D, 5: O },
    46: { 1: V, 2: M, 3: L, 4: D, 5: O },
    45: { 1: V, 2: M, 3: L, 4: D, 5: O },
    44: { 1: V, 2: M, 3: L, 4: D, 5: O },
    43: { 1: V, 2: M, 3: L, 4: D, 5: I },
    42: { 1: V, 2: M, 3: L, 4: D, 5: I },
    41: { 1: V, 2: M, 3: L, 4: D, 5: I },

    31: { 1: V, 2: D, 3: L, 4: M, 5: I },
    32: { 1: V, 2: D, 3: L, 4: M, 5: I },
    33: { 1: V, 2: D, 3: L, 4: M, 5: I },
    34: { 1: V, 2: D, 3: L, 4: M, 5: O },
    35: { 1: V, 2: D, 3: L, 4: M, 5: O },
    36: { 1: V, 2: D, 3: L, 4: M, 5: O },
    37: { 1: V, 2: D, 3: L, 4: M, 5: O },
    38: { 1: V, 2: D, 3: L, 4: M, 5: O },
});
<template>
    <v-dialog v-model="dialog" max-width="1000px" @input="$emit('cancel')">
        <v-card>
            <v-card-title>
                <span class="headline">Editar pago - {{ plan.nombre }}</span>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <pagar-component
                    :pagoPlan="pagoPlan"
                    @success="$emit('success')"
                ></pagar-component>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('cancel')">
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { Urls } from "@/consts.js";
import PagarComponent from "@/components/PagarComponent.vue";

export default {
    data() {
        return {
            dialog: true,
        };
    },
    props: {
        plan: Object,
        pagoPlan: Object,
    },
    components: { PagarComponent },
    methods: {},
    mounted() {},
};
</script>
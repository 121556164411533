<template>
    <v-card width="100%">
        <v-card-title>Diagnósticos y preexistencias</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                <div class="d-flex">
                <v-checkbox v-model="optDxByText">
                </v-checkbox>
                <v-combobox
                    multiple
                    persistent-hint
                    small-chips
                    prepend-icon="mdi-tooth-outline"
                    :items="dientesDictados"
                    v-model="dientesDictados"
                    hide-selected
                    deletable-chips
                    item-color="green"
                    hint="Ejemplo: 28 ó 28.1 ó 28.1,2,3"
                    label="Indique el diente a diagnosticar"
                    persistent-hint
                    :disabled="!optDxByText"
                    ref="tooth"
                >
                    <template v-slot:no-data v-if="dientesDictados.length == 0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Presione <kbd>enter</kbd> para agregar el diente especificado
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{   attrs,
                                                    item,
                                                    parent,
                                                    selected,
                                                }"
                    >

                        <v-chip v-bind="attrs"
                                :input-value="selected"
                                label
                                small
                                :title="item"
                                color="blue lighten-4"
                        >
                            <span class="pr-2">{{item}}</span>
                            <v-icon small @click="parent.selectItem(item)">
                                mdi-close
                            </v-icon>
                        </v-chip>
                    </template>
                </v-combobox>
                </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-autocomplete
                        :items="simbologiaDiagnosticos"
                        v-model="simbologiaDiagnostico"
                        label="Diagnóstico"
                        item-text="nombre"
                        return-object
                        clearable
                    >
                        <template
                            v-slot:[s]="data"
                            v-for="s in ['item', 'selection']"
                        >
                            <component
                                :is="s == 'item' ? 'v-row' : 'div'"
                                :key="s"
                                :class="s == 'item' ? '' : 'd-flex'"
                            >
                                <component
                                    :is="s == 'item' ? 'v-col' : 'div'"
                                    v-if="s == 'item' || data.item.id"
                                    :cols="s == 'item' ? 1 : ''"
                                    class="text-center"
                                >
                                    <img
                                        v-if="
                                            data.item.datos.src &&
                                            !data.item.datos.text
                                        "
                                        :src="data.item.datos.src"
                                        width="35"
                                        height="15"
                                    />

                                    <v-icon
                                        v-if="
                                            data.item.datos.fill &&
                                            !data.item.datos.text
                                        "
                                        :color="data.item.datos.fill"
                                        >mdi-square
                                    </v-icon>
                                    <v-icon
                                        v-if="
                                            data.item.datos.icon &&
                                            !data.item.datos.text
                                        "
                                        :color="data.item.datos.color"
                                        >{{ data.item.datos.icon }}
                                    </v-icon>
                                    <v-icon
                                        v-if="data.item.datos.iconCorona"
                                        :color="data.item.datos.stroke"
                                        >{{ data.item.datos.iconCorona }}
                                    </v-icon>
                                    <span
                                        v-if="data.item.datos.text"
                                        class="font-weight-bold"
                                        :style="data.item.datos.style"
                                        >{{ data.item.datos.text }}
                                    </span>
                                </component>
                                <component
                                    :is="s == 'item' ? 'v-col' : 'div'"
                                    class="align-self-center"
                                >
                                    <span :class="s == 'item' ? '' : 'ml-4'">
                                        {{ data.item.nombre }}
                                    </span>
                                </component>
                            </component>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="4">
                    <v-textarea
                        solo
                        label="Observaciones"
                        v-model="observaciones"
                        rows="4"
                    ></v-textarea>
                </v-col>
                <v-col cols="2">
                    <v-btn color="success" width="100%" @click="addDiagnostico"
                        >Agregar</v-btn
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                    <odontograma
                        ref="o"
                        :diagnosticos="diagnosticos"
                    ></odontograma>
                    <v-overlay :value="optDxByText" :absolute="absolute" :opacity="opacity" color="#757575">
                    </v-overlay>
                    </v-card>




                </v-col>
            </v-row>
        </v-card-text>

        <template>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Diagnóstico</th>
                            <th class="text-left">Dientes</th>
                            <th class="text-left">Observaciones</th>
                            <th class="text-left">Fecha</th>
                            <th class="text-left" style="width: 250px">
                                Estado
                            </th>
                            <th class="text-left">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody v-if="diagnosticos.length > 0">
                        <tr v-for="(d, i) in diagnosticos" :key="i">
                            <td>
                                {{ d.simbologia.nombre }}
                            </td>
                            <td v-if="d.dientes.find((diente) => diente.caras)">
                                <div v-for="(diente, i) in d.dientes" :key="i">
                                    {{ formatDiente(diente) }}
                                </div>
                            </td>
                            <td v-else>
                                <span v-for="(diente, i) in d.dientes" :key="i">
                                    {{
                                        formatDiente(diente) +
                                        (i == d.dientes.length - 1 ? "" : ",")
                                    }}
                                </span>
                            </td>
                            <td>{{ d.observaciones }}</td>
                            <td>{{ formatearFechaIsoAMx(d.fecha) }}</td>
                            <td>
                                <v-select
                                    v-model="d.estado"
                                    :items="estados"
                                    @change="cambiarEstado(d)"
                                >
                                    <template
                                        v-for="e in ['item', 'selection']"
                                        v-slot:[e]="data"
                                    >
                                        <div :key="e">
                                            <v-icon
                                                :color="
                                                    data.item.value
                                                        ? 'green'
                                                        : 'red'
                                                "
                                                class="mr-2"
                                            >
                                                mdi-circle
                                            </v-icon>
                                            <span>
                                                {{ data.item.text }}
                                            </span>
                                        </div>
                                    </template>
                                </v-select>
                            </td>
                            <td>
                                <v-icon
                                    @click="
                                        diagnosticoDelete = d;
                                        confirmDelete = true;
                                    "
                                    color="red"
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                deleteDiagnostico();
            "
        ></confirm>
    </v-card>
</template>

<style scoped>
/* .col .fit-content {
    max-width: fit-content;
} */
</style>

<script>
import Odontograma from "@/components/Odontograma.vue";
import Confirm from "@/components/Confirm.vue";
import DiagnosticosService from "@/services/diagnosticos.service.js";
import { DIENTES } from "@/consts.js";
import { Urls } from "@/consts.js";
import { formatearFechaIsoAMx } from "../utils.js";
import { ESTADOS_DIAGNOSTICO } from "@/consts.js";
const PATTERN_TOOTH =/^[1-9]{2}(\.([1-5]{1},?)+)?$/;

export default {
    data: () => ({
        diagnosticos: [],
        simbologiaDiagnostico: null,
        simbologiaDiagnosticos: [],
        observaciones: null,
        confirmDelete: false,
        diagnosticoDelete: undefined,
        dientesDictados:[],
        optDxByText:false,
        absolute: true,
        opacity:0.8
    }),
    components: {
        Odontograma,
        Confirm,
    },
    computed: {
        SIMBOLOGIA() {
            return this.$store.state.simbologia;
        },
        estados() {
            return ESTADOS_DIAGNOSTICO;
        },
    },
    watch:{
        dientesDictados:function(val, prev){
            if(val.length > prev.length){ //Agregando
                let longitud = val.length;
                if(longitud > 0){
                    let valor = val[longitud -1];
                    if(!PATTERN_TOOTH.test(valor)){
                        this.setDientesDictados(prev);
                    }
                    else{
                        let value = val[longitud -1].replace(/,\s*$/, "");
                        let valores = value.split('.');
                        let tooth = DIENTES[valores[0]];
                        if(!tooth){
                            this.setDientesDictados(prev);
                            return;
                        }

                        var toothWithFaces  = valores[0];
                        let selectedTooth = {"numero":valores[0], "caras":""};
                        if(valores.length > 1){
                            let faces =Array.from(new Set(valores[1].split(",").map((c) => tooth[c]))).join(",");
                            if(!faces){
                                this.setDientesDictados(prev);
                                return;
                            }
                            else{
                                toothWithFaces  += '.'+faces;
                                selectedTooth.caras = valores[1];
                            }
                        }

                        this.$nextTick(() => {
                            this.dientesDictados[longitud-1] = toothWithFaces;
                            //this.$refs.o.setSelected(selectedTooth, true);
                        });
                    }

                }

            }

        }
    },
    methods: {
        getDientes(){
            let dientes = new Map();

            this.dientesDictados.forEach((dd) => {
                let values = dd.split(".");

                let faces = "";
                if(values.length > 1){
                    faces = values[1].split(",").map((c) => Object.keys(DIENTES[values[0]]).find(key => DIENTES[values[0]][key] === c)).sort().join(",");
                }

                if(dientes.size == 0){
                    dientes.set(values[0], faces? faces:"");
                }else if(dientes.has(values[0]) && faces){
                    let faces_actuales = dientes.get(values[0]) +","+faces;
                    faces = Array.from(new Set(faces_actuales.split(","))).sort().join(",");

                    dientes.set(values[0], faces?faces:"");
                }


            });


            return dientes;

        },
        setDientesDictados(value){
            this.$nextTick(() => {
                this.dientesDictados = value;
            });
        },
        getDiagnosticos() {
            this.pacId = this.$route.params.pacienteId;
            if (!this.pacId) return;

            DiagnosticosService.getDiagnosticos(this.pacId).then((data) => {
                if (!data) return;
                this.diagnosticos = data;
            });
        },
        addDiagnostico() {
            let dientes = [];
            if(this.optDxByText){
                if(this.dientesDictados.length < 1){
                    this.$root.showMessage("Debes indicar al menos 1 diente con/sin caras, por favor", 'warn');
                    this.$refs.tooth.focus();
                    return;
                }

               let preformat = this.getDientes();
               preformat.forEach(function(value, key) {
                    let dienteSeleccionado = {};
                    dienteSeleccionado.numero = key;
                    if(value && value != ""){
                        dienteSeleccionado.caras = value;
                    }
                    dientes.push(dienteSeleccionado);
               });


            }
            else{
                if (this.$refs.o.getDientesSeleccionados().length < 1) {
                    this.$root.showMessage("Debes seleccionar al menos 1 diente o 1 cara en el odontrograma, por favor", 'warn');
                    return;
                }
                dientes = this.$refs.o.getDientesSeleccionados();
            }

            if (this.simbologiaDiagnostico == null || !this.simbologiaDiagnostico.id) {
                this.$root.showMessage("Selecciona un diagnóstico", "warning");
                return;
            }
            let diagnostico = {
                pacienteId: this.$route.params.pacienteId,
                tareaId: this.$route.params.tareaId,
                simbologia: { id: this.simbologiaDiagnostico.id },
                observaciones: this.observaciones,
                dientes: dientes,
            };

            this.$http.post(Urls.DIAGNOSTICOS_URL, diagnostico).then((data) => {
                if (!data) return;
                this.$root.showMessage("Diagnóstico agregado exitosamente", "success");
                this.clean();
                this.getDiagnosticos();
            });
        },
        deleteDiagnostico() {
            this.$http
                .delete(Urls.DIAGNOSTICOS_URL + "/" + this.diagnosticoDelete.id)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage(
                        "Diagnóstico eliminado exitosamente", "success"
                    );
                    this.getDiagnosticos();
                });
        },
        cambiarEstado(d) {
            this.$http
                .patch(
                    Urls.DIAGNOSTICOS_URL + "/cambiarEstado/" + d.id + "/" + d.estado,
                )
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Se cambió el estado exitosamente", "success");
                    this.getDiagnosticos();
                });
        },
        formatDiente(diente) {
            if (!diente.caras) return diente.numero;
            let caras = diente.caras
                .split(",")
                .map((c) => DIENTES[diente.numero][c])
                .join(", ");
            return diente.numero + " - " + caras;
        },
        formatearFechaIsoAMx(fecha) {
            return formatearFechaIsoAMx(fecha);
        },
        clean() {
            this.observaciones = null;
            this.dientesDictados = [];
        },
    },

    mounted() {
        this.getDiagnosticos();
        this.simbologiaDiagnosticos = this.SIMBOLOGIA.filter(
            (s) => s.datos.diagnostico
        );

    },
};
</script>

<template>
    <div style="width: 100%">
        <v-data-table
            :headers="headers"
            :items="planes"
            class="elevation-1"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:[`item.fecha`]="{ item }">
                <span>{{ formatearFechaIsoAMx(item.fecha) }}</span>
            </template>
            <template v-slot:[`item.usuario`]="{ item }">
                <span>{{
                    item.usuario.nombres + " " + item.usuario.apellidos
                }}</span>
            </template>
            <template v-slot:[`item.costo`]="{ item }">
                <span>{{ getTotalFormateado(item) }}</span>
            </template>
            <template v-slot:[`item.saldo`]="{ item }">
                <span
                    :class="
                        getSaldo(item) > 0
                            ? 'green--text'
                            : getSaldo(item) < 0
                            ? 'red--text'
                            : ''
                    "
                    class="font-weight-bold"
                    >${{ getSaldo(item).toFixed(2) }}</span
                >
            </template>
            <template v-slot:[`item.progreso`]="{ item }">
                <span>{{ getProgreso(item) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="editar(item)" color="blue">
                    mdi-pencil
                </v-icon>
                <v-icon
                    @click="
                        plan = item;
                        confirmDelete = true;
                    "
                    color="red"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Planes de tratamiento</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mb-2" @click="nuevoPlan">
                        Nuevo plan de tratamiento</v-btn
                    >
                </v-toolbar>
            </template>
            <template slot="no-data">
                <div>Sin planes de tratamiento</div>
            </template>
        </v-data-table>
        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminar();
            "
        ></confirm>
    </div>
</template>

<script>
import { Urls } from "@/consts.js";
import { formatearFechaIsoAMx } from "@/utils.js";
import PlanesService from "@/services/planes.service.js";
import Confirm from "../components/Confirm.vue";

export default {
    data: () => ({
        planes: [],
        plan: undefined,
        pacId: undefined,
        tareaId: undefined,
        confirmDelete: false,
        headers: [
            { text: "Título", value: "nombre" },
            { text: "Creado por", value: "usuario" },
            { text: "Fecha creación", value: "fecha" },
            { text: "Progreso", value: "progreso" },
            { text: "Costo total", value: "costo" },
            { text: "Saldo", value: "saldo" },
            { text: "Acciones", value: "actions", sortable: false },
        ],
    }),
    components: {
        Confirm,
    },
    methods: {
        loadPlanes() {
            this.pacId = this.$route.params.pacienteId;
            this.tareaId = this.$route.params.tareaId;
            PlanesService.getPlanes(this.pacId).then((data) => {
                if (!data) return;
                this.planes = data;
            });
        },
        formatearFechaIsoAMx(fecha) {
            return formatearFechaIsoAMx(fecha);
        },
        nuevoPlan() {
            let plan = {
                pacienteId: this.pacId,
                tareaId: this.tareaId,
            };
            this.$http.post(Urls.PLANES_URL, plan).then((data) => {
                if (!data) return;
                let planId = data;
                this.goToPlan(planId);
            });
        },
        goToPlan(planId) {
            this.$router.push({
                name: "plan",
                params: {
                    pacienteId: this.pacId,
                    tareaId: this.tareaId,
                    planId: planId,
                },
            });
        },
        editar(plan) {
            this.goToPlan(plan.id);
        },
        getTotalFormateado(item) {
            if (item.prestaciones.length == 0) return null;
            let total = item.prestaciones.reduce((a, pp) => a + pp.total, 0);
            return "$" + total.toFixed(2);
        },
        getAbonadoPrestacion(pp) {
            return pp.pagos.reduce((a, p) => a + p.monto, 0);
        },
        getAbonado(item) {
            if (item.prestaciones.length == 0) return null;
            let abonado = item.prestaciones.reduce(
                (total, pp) => total + this.getAbonadoPrestacion(pp),
                0
            );
            return abonado;
        },
        getRealizado(item) {
            if (item.prestaciones.length == 0) return null;
            let realizado = item.prestaciones.reduce(
                (a, pp) => (pp.estadoNombre == "COMPLETADO" ? a + pp.total : a),
                0
            );
            return realizado;
        },
        getSaldo(item) {
            if (item.prestaciones.length == 0) return null;
            return this.getAbonado(item) - this.getRealizado(item);
        },
        getProgreso(item) {
            if (item.prestaciones.length == 0) return null;
            let totalPrestaciones = item.prestaciones.length;
            let totalCompletados = item.prestaciones.filter(
                (pp) => pp.estadoNombre == "COMPLETADO"
            ).length;
            let progreso = (totalCompletados * 100) / totalPrestaciones;
            return progreso.toFixed(2) + "%";
        },
        eliminar() {
            this.$http
                .delete(Urls.PLANES_URL + "/" + this.plan.id)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Plan eliminado exitosamente");
                    this.loadPlanes();
                });
        },
    },
    mounted() {
        this.loadPlanes();
    },
};
</script>

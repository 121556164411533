<template>
    <vue-html2pdf
        :paginate-elements-by-height="1400"
        :enable-download="false"
        :preview-modal="imprimir"
        @beforeDownload="beforeDownload($event)"
        :html-to-pdf-options="{
            margin: 7,
            html2canvas: {
                scale: 3,
                useCORS: true,
                allowTaint: true,
                scrollX: 0,
                scrollY: 0,
            },
            jsPDF: {
                format: 'letter',
                orientation: 'portrait',
            },
        }"
        ref="html2pdf"
    >
        <section slot="pdf-content">
            <div
                style="font-family: 'Roboto', sans-serif"
                v-if="pagoPlan && plan"
            >
                <v-row class="align-center">
                    <v-col cols="4"
                        ><img
                            :src="logotipo"
                            style="max-height: 100px; max-width: 250px"
                    /></v-col>
                    <v-col cols="4" style="text-align: center">
                        <v-row>
                            <v-col style="padding-top: 20px">
                                <span style="font-weight: bold; font-size: 22px"
                                    >Comprobante de pago</span
                                >
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span style="font-weight: bold; font-size: 16px"
                                    >Plan de tratamiento #{{ plan.id }}</span
                                ></v-col
                            >
                        </v-row>
                    </v-col>
                </v-row>

                <div style="margin: 20px 0 20px 10px">
                    <div style="display: flex; margin-bottom: 1px">
                        <h4 style="margin-right: 3px">Médico:</h4>
                        <span>
                            {{ usuario.nombres + " " + usuario.apellidos }}
                        </span>
                    </div>
                    <div style="display: flex; margin-bottom: 1px">
                        <h4 style="margin-right: 3px">Paciente:</h4>
                        <span>
                            {{ paciente.nombres + " " + paciente.apellidos }}
                        </span>
                    </div>
                </div>

                <template>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr style="text-align: left">
                                    <th>Fecha de pago</th>
                                    <th>Medio de pago</th>
                                    <th>Total presupuesto</th>
                                    <th>Total abonado al {{ now }}</th>
                                    <th>Total pendiente al {{ now }}</th>
                                    <th>Monto abonado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{
                                            formatearDateTimeIsoAMx(
                                                pagoPlan.fechaRegistro
                                            )
                                        }}
                                    </td>
                                    <td>
                                        {{ pagoPlan.medioPago.nombre }}
                                    </td>
                                    <td>${{ getTotalPlan.toFixed(2) }}</td>
                                    <td>${{ getTotalAbonado.toFixed(2) }}</td>
                                    <td>${{ getTotalPendiente.toFixed(2) }}</td>
                                    <td>${{ getTotalPago.toFixed(2) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <div
                    style="
                        font-weight: bold;
                        font-size: 18px;
                        margin: 30px 0 8px 10px;
                    "
                >
                    <span>Detalle del pago </span>
                </div>

                <v-data-table
                    :headers="headers"
                    :items="
                        pagoPlan.pagoPrestaciones.filter((ppp) => ppp.status)
                    "
                    hide-default-footer
                    style="text-align: left"
                >
                    <template v-slot:[`item.dientes`]="{ item }">
                        <div
                            v-if="
                                item.prestacionPlan.dientes.find(
                                    (diente) => diente.caras
                                )
                            "
                        >
                            <div
                                v-for="(diente, i) in item.prestacionPlan
                                    .dientes"
                                :key="i"
                            >
                                {{ formatDiente(diente) }}
                            </div>
                        </div>
                        <div v-else>
                            <span
                                v-for="(diente, i) in item.prestacionPlan
                                    .dientes"
                                :key="i"
                            >
                                {{
                                    formatDiente(diente) +
                                    (i == item.prestacionPlan.dientes.length - 1
                                        ? ""
                                        : ",")
                                }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                        ${{ item.prestacionPlan.total.toFixed(2) }}
                    </template>
                    <template v-slot:[`item.monto`]="{ item }">
                        ${{ item.monto.toFixed(2) }}
                    </template>
                </v-data-table>
            </div>
        </section>
    </vue-html2pdf>
</template>

<script>
import { Urls } from "@/consts.js";
import { DIENTES } from "@/consts.js";
import { formatearDateAMx } from "@/utils.js";
import { formatearDateTimeIsoAMx } from "@/utils.js";
import VueHtml2pdf from "vue-html2pdf";

export default {
    data: () => ({
        now: undefined,
        logotipo: "",
        headers: [
            {
                text: "Prestación",
                value: "prestacionPlan.prestacion.nombre",
            },
            { text: "Dientes", value: "dientes" },
            { text: "Total", value: "total" },
            { text: "Monto abonado", value: "monto" },
        ],
    }),
    props: ["prestacionesPlan", "plan", "pagoPlan", "imprimir", "correo"],
    components: {
        VueHtml2pdf,
    },
    computed: {
        usuario: function () {
            return this.$store.state.usuario || {};
        },
        paciente: function () {
            return this.$store.state.paciente || {};
        },
        getTotalAbonado() {
            return this.plan.prestaciones.reduce(
                (total, pp) =>
                    total + pp.pagos.reduce((a, p) => a + p.monto, 0),
                0
            );
        },
        getTotalRealizado() {
            return this.plan.prestaciones.reduce(
                (total, pp) =>
                    pp.estadoNombre == "COMPLETADO" ? total + pp.total : total,
                0
            );
        },
        getTotalPendiente() {
            let pendiente = this.getTotalRealizado - this.getTotalAbonado;
            return pendiente < 0 ? 0 : pendiente;
        },
        getTotalPago() {
            let total = this.pagoPlan.pagoPrestaciones
                .filter((pp) => (this.pagoPlan.status ? pp.status : true))
                .reduce((total, pp) => total + parseFloat(pp.monto || 0), 0);
            return total;
        },
        getTotalPlan() {
            if (this.plan.prestaciones.length == 0) return null;
            let total = this.plan.prestaciones.reduce(
                (a, pp) => a + pp.total,
                0
            );
            return total;
        },
    },
    methods: {
        loadLogotipo() {
            this.$http.get("/api/misc/logotipo").then((data) => {
                if (!data) return;
                this.logotipo = "data:image/png;base64," + data;
            });
        },
        formatDiente(diente) {
            if (!diente.caras) return diente.numero;
            let caras = diente.caras
                .split(",")
                .map((c) => DIENTES[diente.numero][c])
                .join(", ");
            return diente.numero + " - " + caras;
        },
        formatearDateTimeIsoAMx(fecha) {
            return formatearDateTimeIsoAMx(fecha);
        },
        formatearDateAMx(fecha) {
            return formatearDateAMx(fecha);
        },

        async beforeDownload({ html2pdf, options, pdfContent }) {
            if (this.imprimir) return;

            await html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .output("blob")
                .then((pdf) => {
                    let paciente = this.$store.state.paciente;
                    let fd = new FormData();
                    fd.append("pdf", pdf);
                    fd.append("plan_id",this.plan.id);
                    fd.append("correo", this.correo);
                    fd.append(
                        "nombrePaciente",
                        paciente.nombres + " " + paciente.apellidos
                    );
                    fd.append("type", 1);
                    this.$http
                        .post("/api/misc/enviarCorreoPdf", fd)
                        .then((data) => {
                            if (!data) return;
                            this.$root.showMessage(
                                "Correo enviado correctamente"
                            );
                            this.$emit("close");
                        });
                });
        },
    },
    mounted() {
        this.now = formatearDateAMx(new Date());
        this.loadLogotipo();
    },
};
</script>

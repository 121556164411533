import axios from "axios";
import { Urls } from "@/consts.js";

const PrestacionesService = {
    getPrestaciones: function (categoriaId) {
        let url = categoriaId ? Urls.PRESTACIONES_URL + "/" + categoriaId : Urls.PRESTACIONES_URL;
        return axios.get(url);
    }
};

export default PrestacionesService;

import Vue from 'vue'
import axios from "axios";
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false

var vue = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  data: {
    messageAlert: '',
    alert: false,
    colorAlert: 'primary',
    timeoutAlert: 5000,
    loaded: false,
  },
  methods: {
    showMessage(message, color, timeout) {
      this.messageAlert = message;
      this.colorAlert = color || 'primary';
      this.timeoutAlert = timeout || 5000;
      this.alert = true;
    },
    logout() {
      this.$http.get("/session/logout").then((data) => {
        this.$store.commit("usuario", null);
        this.$router.push({ name: "inicio" });
      });
    },
    setLoaded(v) {
      this.loaded = v;
    }
  }
});
Vue.prototype.$http = axios;

// axios.interceptors.request.use(function (config) {
//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });

axios.interceptors.response.use(function (response) {
  if (response.request.responseURL.includes("/api/adjuntos/download") || response.request.responseURL.includes("/api/adjuntos/upload") || response.request.responseURL.includes("/session/sign-up-activation")) {
    return response;
  }

  return response.data;
}, function (error) {

  //revisar header
  if (error.response.status == 401) {
    store.commit('usuario', null);
    window.location.href = process.env.VUE_APP_SSO_URL;
  } else if (error.response.status == 402) {
    let u = store.state.usuario;
    var msg = 'Se requiere tener una suscripción vigente';
    if (error.response.headers && error.response.headers["err-msg"]) {
      msg = error.response.headers["err-msg"];
    }
    vue.showMessage(msg, 'warning', 5000);
    if (u && u.roles.includes("MED") && vue.$route.name !== 'comprar') {
      vue.$router.replace({ name: 'comprar' });
    }
  } else if (error.response.status == 403) {
    vue.showMessage('No tiene permitido acceder al recurso solicitado.', 'red', 5000);
  }
  else if (error.response.status == 405) {
    vue.showMessage('No tiene permiso para realizar esta acción ¿Tiene el rol permitido, el permiso y suscripción vigente?', 'red', 5000);
  }
  else {
    if (error.response.request.responseURL.includes("/session/sign-up-activation")) {
      return Promise.reject(error);
    }
    var msg = "Error interno, comunique al proveedor";
    if (error.response.headers && error.response.headers["err-msg"]) {
      msg = error.response.headers["err-msg"];
    }
    else if (error.response.data.errorMsg) {
      msg = error.response.data.errorMsg;
    }
    vue.showMessage(msg, 'red', 10000);
  }

  return Promise.reject(error);

});

vue.$mount('#app')
<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    @click:outside="close"
    @keydown.esc="close"
    scrollable
    close-delay="250"
  >
    <v-card>
      <v-card-title class="text-wrap">
        Compartir agenda con: {{ asistente.nombres }} {{asistente.apellidos}}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="validform" lazy-validation>
          <v-container>
            <v-row no-gutter>
              <v-col cols="12">
                <v-card-subtitle>Selecciona la agenda que deseas compartir</v-card-subtitle>
                <v-data-table
                  class="elevation-1"
                  style="width: 100%"
                  loading-text="Obteniendo datos... por favor espere un momento"
                  no-data-text="No tiene consultorios"
                  no-results-text=" sin resultados. "
                  v-model="selected"
                  show-select
                  item-key="id"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  hide-default-footer
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Cancelar</v-btn>
        <v-btn
          v-if="items && items.length > 0"
          color="green darken-1"
          text
          :disabled="!validform"
          :loading="saving"
          @click="guardar"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Urls } from "@/consts.js";
export default {
  props: ["asistente"],
  data: () => ({
    dialog: true,
    validform: false,
    model: {},
    loading: true,
    saving: false,
    items: [],
    selected: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "consultorio",
        sortable: true,
        width: "90%",
        fixed: true,
      },
    ],
  }),
  beforeMount() {
    this.getConsultorios();
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    getConsultorios: function () {
         this.$http.get(Urls.ASISTENTES_URL+'/share/'+this.asistente.id).then((data) => {
            if (!data) return;
            this.items = data;
            this.items.forEach((element) => {
                if (element.compartido && element.compartido === true) {
                    this.selected.push(element);
                }
            });
         }).then(function () {
            this.loading = false;
            if (this.items.length == 0) {
                this.$root.showMessage("Para compartir consultorios es necesario registrar uno.","warning");
                setTimeout(() => {this.close();}, 500);
            }
         }.bind(this));
    },
    guardar: function () {
      var param = ","; ;
      if (this.selected.length > 0) {
        this.selected.forEach((element) => {
            param += element.id + ",";
        });
      param = param.replace(/,$/, "");

      }
      this.saving = true;
      this.$http.put(Urls.ASISTENTES_URL+'/share/'+this.asistente.id+'/'+param).then((data) => {
        if (!data) return;
        this.$root.showMessage("Configuración guardada", "success");
        this.$nextTick(() => {
            this.close();
        });
      }).then(function () {
            this.saving = false;
      }.bind(this));
    },
  },
};
</script>
<template>
    <v-dialog v-model="dialog" max-width="600px" @input="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="headline">{{
                    prestacion.id ? "Editar prestación" : "Nueva prestación"
                }}</span>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-container>
                    <v-form ref="form" @submit="guardar">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="prestacion.nombre"
                                    label="Nombre de la prestación"
                                    maxlength="128"
                                    autofocus
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="prestacion.precio"
                                    label="Precio"
                                    maxlength="11"
                                    prefix="$"
                                    :rules="[rules.required, rules.number]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    :items="simbologiaTratamientos"
                                    v-model="simbologiaTratamiento"
                                    label="Simbología"
                                    item-text="nombre"
                                    item-value="id"
                                    return-object
                                    clearable
                                >
                                    <template
                                        v-slot:[s]="data"
                                        v-for="s in ['item', 'selection']"
                                    >
                                        <component
                                            :is="s == 'item' ? 'v-row' : 'div'"
                                            :key="s"
                                            :class="s == 'item' ? '' : 'd-flex'"
                                        >
                                            <component
                                                :is="
                                                    s == 'item'
                                                        ? 'v-col'
                                                        : 'div'
                                                "
                                                v-if="
                                                    s == 'item' || data.item.id
                                                "
                                                :cols="s == 'item' ? 1 : ''"
                                                class="text-center"
                                            >
                                                <img
                                                    v-if="
                                                        data.item.datos.src &&
                                                        !data.item.datos.text
                                                    "
                                                    :src="data.item.datos.src"
                                                    width="35"
                                                    height="15"
                                                />

                                                <v-icon
                                                    v-if="
                                                        data.item.datos.icon &&
                                                        !data.item.datos.text
                                                    "
                                                    :color="
                                                        data.item.datos.color
                                                    "
                                                    >{{ data.item.datos.icon }}
                                                </v-icon>
                                                <v-icon
                                                    v-if="
                                                        data.item.datos
                                                            .iconCorona
                                                    "
                                                    :color="
                                                        data.item.datos.stroke
                                                    "
                                                    >{{
                                                        data.item.datos
                                                            .iconCorona
                                                    }}
                                                </v-icon>
                                                <span
                                                    v-if="data.item.datos.text"
                                                    class="font-weight-bold"
                                                    :style="
                                                        data.item.datos.style
                                                    "
                                                    >{{ data.item.datos.text }}
                                                </span>
                                            </component>
                                            <component
                                                :is="
                                                    s == 'item'
                                                        ? 'v-col'
                                                        : 'div'
                                                "
                                                class="align-self-center"
                                            >
                                                <span
                                                    :class="
                                                        s == 'item' ||
                                                        data.item.id
                                                            ? 'ml-4'
                                                            : ''
                                                    "
                                                >
                                                    {{ data.item.nombre }}
                                                </span>
                                            </component>
                                        </component>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('close')">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="guardar">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { Urls } from "@/consts.js";
const DEFAULT_SELECT = "Sin simbología";
export default {
    data() {
        return {
            dialog: true,
            rules: {
                required: function (val) {
                    return !!val || "Campo requerido";
                },
                number: function (val) {
                    return !isNaN(val) || "Formato inválido";
                },
            },
            defaultSelect: DEFAULT_SELECT,
            simbologiaTratamientos: [],
            simbologiaTratamiento: null,
        };
    },
    props: {
        prestacion: Object,
    },
    computed: {
        SIMBOLOGIA() {
            return this.$store.state.simbologia;
        },
    },
    methods: {
        guardar(event) {
            event.preventDefault(); //Previene que se refresque la página al presionar enter
            if (!this.$refs.form.validate()) return;

            let endpoint = this.prestacion.id ? "/update" : "/insert";

            this.$http({
                method: this.prestacion.id ? "put" : "post",
                url: Urls.PRESTACIONES_URL + endpoint,
                data: {
                    id: this.prestacion.id,
                    nombre: this.prestacion.nombre,
                    precio: this.prestacion.precio,
                    simbologiaId: this.simbologiaTratamiento.id,
                    categoriaId: this.prestacion.categoriaId,
                },
            }).then((data) => {
                if (data) {
                    this.$root.showMessage("Prestación guardada exitosamente");
                    this.$emit("success");
                } else {
                    this.$emit("close");
                }
            });
        },
    },
    mounted() {
        this.simbologiaTratamientos = this.SIMBOLOGIA.filter(
            (s) => s.datos.tratamiento
        );
        this.simbologiaTratamientos.splice(0, 0, {
            nombre: DEFAULT_SELECT,
            datos: { type: "icon" },
        });

        this.simbologiaTratamiento = this.prestacion.simbologiaId
            ? { id: this.prestacion.simbologiaId }
            : { nombre: DEFAULT_SELECT };
    },
};
</script>
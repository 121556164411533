<template>
    <div style="width: 100%">
        <v-data-table
            :headers="headers"
            :items="planes"
            class="elevation-1"
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Pagos</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:[`item.usuario`]="{ item }">
                {{ item.usuario.nombres + " " + item.usuario.apellidos }}
            </template>
            <template v-slot:[`item.presupuesto`]="{ item }">
                ${{
                    item.prestaciones
                        .reduce((total, pp) => total + pp.total, 0)
                        .toFixed(2)
                }}
            </template>
            <template v-slot:[`item.realizado`]="{ item }">
                ${{ getRealizado(item).toFixed(2) }}
            </template>
            <template v-slot:[`item.abonado`]="{ item }">
                ${{ getAbonado(item).toFixed(2) }}
            </template>
            <template v-slot:[`item.saldo`]="{ item }">
                <span
                    :class="
                        getSaldo(item) > 0
                            ? 'green--text'
                            : getSaldo(item) < 0
                            ? 'red--text'
                            : ''
                    "
                    class="font-weight-bold"
                >
                    ${{ getSaldo(item).toFixed(2) }}
                </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div style="margin-left: -7px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    $router.push({
                                        name: 'pagosPlan',
                                        params: { planId: item.id },
                                    })
                                "
                            >
                                <v-icon color="primary"> mdi-eye </v-icon>
                            </v-btn>
                        </template>
                        <span>Ver pagos</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    $router.push({
                                        name: 'pagar',
                                        params: { planId: item.id },
                                    })
                                "
                            >
                                <v-icon color="success">
                                    mdi-currency-usd
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Pagar</span>
                    </v-tooltip>
                </div>
            </template>

            <template slot="no-data">
                <div>Sin presupuestos</div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { Urls } from "@/consts.js";
import PlanesService from "@/services/planes.service.js";

export default {
    data: () => ({
        planes: [],
        pacId: undefined,
        headers: [
            { text: "Plan de tratamiento", value: "nombre" },
            { text: "Creado por", value: "usuario" },
            { text: "Total presupuesto", value: "presupuesto" },
            { text: "Abonado", value: "abonado" },
            { text: "Realizado", value: "realizado" },
            { text: "Saldo", value: "saldo" },
            { text: "Acciones", value: "actions", sortable: false },
        ],
    }),
    components: {},
    methods: {
        loadPlanes() {
            this.pacId = this.$route.params.pacienteId;
            PlanesService.getPlanes(this.pacId).then((data) => {
                if (!data) return;
                this.planes = data;
            });
        },
        goToPlan(planId) {
            this.$router.push({
                name: "plan",
                params: {
                    pacienteId: this.pacId,
                    tareaId: this.tareaId,
                    planId: planId,
                },
            });
        },
        getAbonado(item) {
            return item.prestaciones.reduce(
                (total, pp) =>
                    total + pp.pagos.reduce((a, p) => a + p.monto, 0),
                0
            );
        },
        getRealizado(item) {
            return item.prestaciones.reduce(
                (total, pp) =>
                    pp.estadoNombre == "COMPLETADO" ? total + pp.total : total,
                0
            );
        },
        getSaldo(item) {
            return this.getAbonado(item) - this.getRealizado(item);
        },
    },
    mounted() {
        this.loadPlanes();
    },
};
</script>

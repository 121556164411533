var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v("Plan de tratamiento #"+_vm._s(_vm.plan.id))]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"mr-4",staticStyle:{"max-width":"50%"},attrs:{"items":_vm.prestaciones,"item-text":"nombre","return-object":"","label":"Prestación","clearable":""},scopedSlots:_vm._u([_vm._l((['item', 'selection']),function(s){return {key:s,fn:function(data){return [_c(s == 'item' ? 'v-row' : 'div',{key:s,tag:"component",class:s == 'item' ? '' : 'd-flex'},[(
                                _vm.SIMBOLOGIA.find(
                                    (s) => s.id == data.item.simbologiaId
                                )
                            )?_c(s == 'item' ? 'v-col' : 'div',{tag:"component",staticClass:"text-center",attrs:{"cols":s == 'item' ? 1 : ''}},[(
                                    _vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.src &&
                                    !_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.text
                                )?_c('img',{attrs:{"src":_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.src,"width":"35","height":"15"}}):_vm._e(),(
                                    _vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.fill &&
                                    !_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.text
                                )?_c('v-icon',{attrs:{"color":_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.fill}},[_vm._v("mdi-square ")]):_vm._e(),(
                                    _vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.icon &&
                                    !_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.text
                                )?_c('v-icon',{attrs:{"color":_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.color}},[_vm._v(_vm._s(_vm.SIMBOLOGIA.find( (s) => s.id == data.item.simbologiaId ).datos.icon)+" ")]):_vm._e(),(
                                    _vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.iconCorona
                                )?_c('v-icon',{attrs:{"color":_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.stroke}},[_vm._v(_vm._s(_vm.SIMBOLOGIA.find( (s) => s.id == data.item.simbologiaId ).datos.iconCorona)+" ")]):_vm._e(),(
                                    _vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.text
                                )?_c('span',{staticClass:"font-weight-bold",style:(_vm.SIMBOLOGIA.find(
                                        (s) =>
                                            s.id == data.item.simbologiaId
                                    ).datos.style)},[_vm._v(_vm._s(_vm.SIMBOLOGIA.find( (s) => s.id == data.item.simbologiaId ).datos.text)+" ")]):_vm._e()],1):_vm._e(),(!data.item.simbologiaId && s == 'item')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('v-icon')],1):_vm._e(),_c(s == 'item' ? 'v-col' : 'div',{tag:"component",staticClass:"align-self-center"},[_c('span',{class:s == 'item' || !data.item.simbologiaId
                                        ? ''
                                        : 'ml-4'},[_vm._v(" "+_vm._s(data.item.nombre)+" ")])])],1)]}}})],null,true),model:{value:(_vm.prestacion),callback:function ($$v) {_vm.prestacion=$$v},expression:"prestacion"}}),_c('v-btn',{staticClass:"mt-3 mr-4",attrs:{"color":"success"},on:{"click":_vm.addPrestacionPlan}},[_vm._v(" Agregar ")]),_c('v-checkbox',{attrs:{"label":"Una prestación por cada diente"},model:{value:(_vm.prestacionPorDiente),callback:function ($$v) {_vm.prestacionPorDiente=$$v},expression:"prestacionPorDiente"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('odontograma',{ref:"o",attrs:{"diagnosticos":_vm.diagnosticos,"prestacionesPlan":_vm.prestacionesPlan}})],1)],1)],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Plan de tratamiento ")]),_c('v-tab',[_vm._v(" Evoluciones ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Prestación")]),_c('th',{staticClass:"text-left"},[_vm._v("Dientes")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"220px"}},[_vm._v(" Estado ")]),_c('th',{staticClass:"text-left"},[_vm._v("Total")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"100px"}},[_vm._v(" Eliminar ")])])]),_c('tbody',_vm._l((_vm.prestacionesPlan),function(pp,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(pp.prestacion.nombre))]),(
                                        pp.dientes.find(
                                            (diente) => diente.caras
                                        )
                                    )?_c('td',_vm._l((pp.dientes),function(diente,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente))+" ")])}),0):_c('td',_vm._l((pp.dientes),function(diente,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente) + (i == pp.dientes.length - 1 ? "" : ","))+" ")])}),0),_c('td',[_c('v-select',{attrs:{"items":_vm.estados},on:{"click":function($event){_vm.prevEstado = pp.estado},"change":function($event){return _vm.cambiarEstado(pp)}},scopedSlots:_vm._u([_vm._l(([
                                                'item',
                                                'selection',
                                            ]),function(e){return {key:e,fn:function(data){return [_c('div',{key:e},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":data.item.value
                                                            ? 'green'
                                                            : 'red'}},[_vm._v(" mdi-circle ")]),_c('span',[_vm._v(" "+_vm._s(data.item.text)+" ")])],1)]}}})],null,true),model:{value:(pp.estado),callback:function ($$v) {_vm.$set(pp, "estado", $$v)},expression:"pp.estado"}})],1),_c('td',[_vm._v(_vm._s("$" + pp.total.toFixed(2)))]),_c('td',[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){_vm.prestacionPlan = pp;
                                            _vm.confirmDelete = true;}}},[_vm._v(" mdi-delete ")])],1)])}),0)]},proxy:true}])})],(_vm.prestacionesPlan.length > 0)?_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"offset":"7"}},[[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-bold text--secondary"},[_vm._v(" Total presupuesto ")]),_c('td',[_vm._v(_vm._s(_vm.getTotalFormateado()))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold text--secondary"},[_vm._v(" Abonado ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getAbonadoFormateado())+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold text--secondary"},[_vm._v(" Realizado ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getRealizadoFormateado())+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold text--secondary"},[_vm._v(" Saldo ")]),_c('td',{staticClass:"font-weight-bold",class:_vm.getSaldo() > 0
                                                    ? 'green--text'
                                                    : _vm.getSaldo() < 0
                                                    ? 'red--text'
                                                    : ''},[_vm._v(" $"+_vm._s(_vm.getSaldo().toFixed(2))+" ")])])])]},proxy:true}],null,false,1280514386)})]],2)],1):_vm._e(),_c('v-row',{staticClass:"text-end mb-1 mr-1 mt-3"},[_c('v-col',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){_vm.dialogEnviar = true}}},[_vm._v("Enviar por correo")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.imprimirPlan}},[_vm._v("Imprimir")])],1)],1)],2),_c('v-tab-item',[[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Prestación")]),_c('th',{staticClass:"text-left"},[_vm._v("Dientes")]),_c('th',{staticClass:"text-left"},[_vm._v("Escrito por")]),_c('th',{staticClass:"text-left"},[_vm._v("Fecha")]),_c('th',{staticClass:"text-left"},[_vm._v("Evolución")])])]),_c('tbody',_vm._l((_vm.prestacionesPlan),function(pp,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(pp.prestacion.nombre))]),(
                                        pp.dientes.find(
                                            (diente) => diente.caras
                                        )
                                    )?_c('td',_vm._l((pp.dientes),function(diente,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente))+" ")])}),0):_c('td',_vm._l((pp.dientes),function(diente,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente) + (i == pp.dientes.length - 1 ? "" : ","))+" ")])}),0),_c('td',[_vm._v(" "+_vm._s(pp.evolucion ? pp.evolucion.usuario ? pp.evolucion.usuario .nombres + " " + pp.evolucion.usuario .apellidos : "" : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(pp.evolucion ? _vm.formatearFechaIsoAMx( pp.evolucion.fecha ) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(pp.evolucion ? pp.evolucion.evolucion : "")+" ")])])}),0)]},proxy:true}])})]],2)],1),(_vm.dialogEvolucionar)?_c('dialog-evolucionar',{attrs:{"prestacionPlan":_vm.prestacionPlan,"estado":_vm.currEstado},on:{"cancel":function($event){_vm.dialogEvolucionar = false},"success":function($event){_vm.dialogEvolucionar = false;
            _vm.getDiagnosticos();
            _vm.getPrestacionesPlan();}}}):_vm._e(),(_vm.dialogEnviar)?_c('dialog-enviar',{ref:"dialogEnviarCorreo",attrs:{"html2pdf":_vm.$refs.template.$refs.html2pdf,"titulo":"Envío de presupuesto"},on:{"close":function($event){_vm.dialogEnviar = false},"enviar":function($event){_vm.imprimir = false;
            _vm.correo = $event;
            _vm.$nextTick(function () {
                _vm.$refs.template.$refs.html2pdf.generatePdf();
            });}}}):_vm._e(),_c('plan-tratamiento-template',{ref:"template",attrs:{"prestacionesPlan":_vm.prestacionesPlan,"plan":_vm.plan,"imprimir":_vm.imprimir,"correo":_vm.correo},on:{"close":function($event){_vm.dialogEnviar = false}}}),(_vm.confirmDelete)?_c('confirm',{on:{"cancel":function($event){_vm.confirmDelete = false},"confirm":function($event){_vm.confirmDelete = false;
            _vm.eliminarPrestacionPlan();}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
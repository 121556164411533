<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" max-width="390" @input="$emit('cancel')">
            <v-card>
                <v-card-title class="headline" v-if="title">{{
                    title
                }}</v-card-title>
                <v-card-title class="headline" v-else
                    >¡Importante!</v-card-title
                >
                <v-card-text v-if="text">{{ text }}</v-card-text>
                <v-card-text v-else
                    >Esta operación es irreversible. ¿Está seguro que desea
                    continuar?</v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary" text @click="$emit('cancel')">
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="primary"
                        text
                        @click="$emit('confirm')"
                    >
                        Continuar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>


<script>
export default {
    data() {
        return {
            dialog: true,
        };
    },
    props: ["title", "text"],
};
</script>
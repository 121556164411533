<template>
  <v-container>
    <h2 class="mt-2">Planes</h2>
    <v-divider class="mb-4"></v-divider>
    <v-progress-linear indeterminate v-show="loading"></v-progress-linear>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" v-for="(p, i) in paquetes" :key="i">
        <paquete :ref="p.id" :paquete="p" @unknown-user="onUnknownUser" />
      </v-col>
    </v-row>
    <v-dialog v-model="find" max-width="420px" scrollable>
      <v-card :loading="findingGuest">
        <v-card-text>
          <div class="text-center">
            <div class="mt-4 mb-1">¿No estás registrado?</div>
            <v-btn small color="info" width="60%" @click="signUp"
              >Regístrate aquí</v-btn
            >
          </div>
          <v-divider class="mt-4 mb-2"></v-divider>
          <span>Si ya eres nuestro usuario</span>
          <v-form ref="form">
            <v-text-field
              label="Escribe tu correo de usuario"
              name="username"
              prepend-icon="mdi-account"
              type="text"
              v-model="mail"
              required
              clearable
              hint="Correo de usuario"
              :rules="[
                (v) => !!v || 'Este campo es obligatorio',
                (v) => (v && /.+@.+\..+/.test(v)) || 'Correo debe ser válido',
              ]"
              :counter="64"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            depressed
            class="primary"
            @click="search"
            :loading="findingGuest"
          >
            Continuar
          </v-btn>
          <v-btn depressed @click="find = false"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  components: {
    Paquete: () => import("@/components/Paquete"),
  },
  data: () => ({
    paquetes: [],
    loading: true,
    //
    find: false,
    mail: null,
    findingGuest: false,
    paqueteSelected: null,
    uuid: null,
  }),
  methods: {
    getPaquetes: function () {
      this.$http
        .get("/api/compras/paquetes")
        .then((data) => {
          if (data) this.paquetes = data;
        })
        .catch((e) => (this.loading = false))
        .then(() => {
          this.loading = false;
          this.$root.setLoaded(this.paquetes.length > 0);
        });
    },
    signUp: function () {
      return (window.location.href = "/registro.html");
    },
    search: function () {
      if (this.$refs.form.validate()) {
        this.findingGuest = true;
        this.$http
          .post("/api/compras/find-by-email/" + this.mail)
          .then(({ data }) => {
            if (data && data.length >= 36) {
              this.uuid = data;
              for (let index = 0; index < this.paquetes.length; index++) {
                this.$refs[this.paquetes[index].id].setToken(this.uuid);
              }
              this.find = false;
              this.$refs[this.paqueteSelected].goToComprar();
            } else {
              this.$root.showMessage(
                `${this.mail} aún no eres usuario del sistema`,
                "error"
              );
            }
          })
          .catch((e) => {})
          .then(() => (this.findingGuest = false));
      }
    },
    onUnknownUser(pId) {
      this.paqueteSelected = pId;
      this.find = true;
      this.findingGuest = false;
    },
  },
  beforeMount() {
    //if (!this.userInfo) base = "/public";
  },
  mounted() {
    this.getPaquetes();
  },
};
</script>

<template>
    <vue-html2pdf
        :paginate-elements-by-height="1400"
        :enable-download="false"
        :preview-modal="imprimir"
        @beforeDownload="beforeDownload($event)"
        :html-to-pdf-options="{
            margin: 7,
            html2canvas: {
                scale: 3,
                useCORS: true,
                allowTaint: true,
                scrollX: 0,
                scrollY: 0,
            },
            jsPDF: {
                format: 'letter',
                orientation: 'portrait',
            },
        }"
        ref="html2pdf"
    >
        <section slot="pdf-content">
            <div style="font-family: 'Roboto', sans-serif">
                <v-row class="align-center">
                    <v-col cols="4" class="ma-0 pa-0"
                        ><img
                            :src="logotipo"
                            style="max-height: 100px; max-width: 250px"
                    /></v-col>
                    <v-col cols="8" style="padding-top: 20px">
                        <span style="font-weight: bold; font-size: 21px"
                            >Plan de tratamiento #{{ plan.id }}</span
                        >
                    </v-col>
                </v-row>

                <div style="margin: 20px 0 20px 10px">
                    <div style="display: flex; margin-bottom: 1px">
                        <h4 style="margin-right: 3px">Médico:</h4>
                        <span>
                            {{ usuario.nombres + " " + usuario.apellidos }}
                        </span>
                    </div>
                    <div style="display: flex; margin-bottom: 1px">
                        <h4 style="margin-right: 3px">Paciente:</h4>
                        <span>
                            {{ paciente.nombres + " " + paciente.apellidos }}
                        </span>
                    </div>
                    <div style="display: flex">
                        <h4 style="margin-right: 3px">Fecha:</h4>
                        <span>
                            {{ fecha }}
                        </span>
                    </div>
                </div>

                <div>
                    <template>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr style="text-align: left">
                                        <th>Prestación</th>
                                        <th>Dientes</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(pp, i) in prestacionesPlan"
                                        :key="i"
                                    >
                                        <td>{{ pp.prestacion.nombre }}</td>
                                        <td
                                            v-if="
                                                pp.dientes.find(
                                                    (diente) => diente.caras
                                                )
                                            "
                                        >
                                            <div
                                                v-for="(
                                                    diente, i
                                                ) in pp.dientes"
                                                :key="i"
                                            >
                                                {{ formatDiente(diente) }}
                                            </div>
                                        </td>
                                        <td v-else>
                                            <span
                                                v-for="(
                                                    diente, i
                                                ) in pp.dientes"
                                                :key="i"
                                            >
                                                {{
                                                    formatDiente(diente) +
                                                    (i == pp.dientes.length - 1
                                                        ? ""
                                                        : ",")
                                                }}
                                            </span>
                                        </td>
                                        <td>{{ "$" + pp.total.toFixed(2) }}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td
                                            style="
                                                font-weight: bold;
                                                padding-right: 80px;
                                                text-align: right;
                                            "
                                        >
                                            Total:
                                        </td>
                                        <td style="padding: 20px auto">
                                            {{ getTotalFormateado() }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                </div>
            </div>
        </section>
    </vue-html2pdf>
</template>

<script>
import { Urls } from "@/consts.js";
import { DIENTES } from "@/consts.js";
import { formatearDateAMx } from "@/utils.js";
import VueHtml2pdf from "vue-html2pdf";

export default {
    data: () => ({ fecha: undefined, logotipo: "" }),
    props: ["prestacionesPlan", "plan", "imprimir", "correo"],
    components: {
        VueHtml2pdf,
    },
    computed: {
        usuario: function () {
            return this.$store.state.usuario || {};
        },
        paciente: function () {
            return this.$store.state.paciente || {};
        },
    },
    methods: {
        loadLogotipo() {
            this.$http.get("/api/misc/logotipo").then((data) => {
                if (!data) return;
                this.logotipo = "data:image/png;base64," + data;
            });
        },
        formatDiente(diente) {
            if (!diente.caras) return diente.numero;
            let caras = diente.caras
                .split(",")
                .map((c) => DIENTES[diente.numero][c])
                .join(", ");
            return diente.numero + " - " + caras;
        },
        getTotalFormateado() {
            if (this.prestacionesPlan.length == 0) return null;
            let total = this.prestacionesPlan.reduce(
                (a, pp) => a + pp.total,
                0
            );
            return "$" + total.toFixed(2);
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            if (this.imprimir) return;

            await html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .output("blob")
                .then((pdf) => {
                    let paciente = this.$store.state.paciente;
                    let fd = new FormData();
                    fd.append("pdf", pdf);
                    fd.append("correo", this.correo);
                    fd.append("plan_id",this.plan.id);
                    fd.append(
                        "nombrePaciente",
                        paciente.nombres + " " + paciente.apellidos
                    );
                    fd.append("type", 0);
                    this.$http
                        .post("/api/misc/enviarCorreoPdf", fd)
                        .then((data) => {
                            if (!data) return;
                            this.$root.showMessage(
                                "Correo enviado correctamente"
                            );
                            this.$emit("close");
                        });
                });
        },
    },
    mounted() {
        this.fecha = formatearDateAMx(new Date());
        this.loadLogotipo();
    },
};
</script>

<template>
    <v-card width="100%">
        <v-card-title>Plan de tratamiento #{{ plan.id }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <div class="d-flex">
                <v-autocomplete
                    :items="prestaciones"
                    v-model="prestacion"
                    item-text="nombre"
                    return-object
                    label="Prestación"
                    clearable
                    style="max-width: 50%"
                    class="mr-4"
                >
                    <template
                        v-slot:[s]="data"
                        v-for="s in ['item', 'selection']"
                    >
                        <component
                            :is="s == 'item' ? 'v-row' : 'div'"
                            :key="s"
                            :class="s == 'item' ? '' : 'd-flex'"
                        >
                            <component
                                :is="s == 'item' ? 'v-col' : 'div'"
                                v-if="
                                    SIMBOLOGIA.find(
                                        (s) => s.id == data.item.simbologiaId
                                    )
                                "
                                :cols="s == 'item' ? 1 : ''"
                                class="text-center"
                            >
                                <img
                                    v-if="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.src &&
                                        !SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.text
                                    "
                                    :src="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.src
                                    "
                                    width="35"
                                    height="15"
                                />

                                <v-icon
                                    v-if="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.fill &&
                                        !SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.text
                                    "
                                    :color="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.fill
                                    "
                                    >mdi-square
                                </v-icon>
                                <v-icon
                                    v-if="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.icon &&
                                        !SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.text
                                    "
                                    :color="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.color
                                    "
                                    >{{
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.icon
                                    }}
                                </v-icon>
                                <v-icon
                                    v-if="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.iconCorona
                                    "
                                    :color="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.stroke
                                    "
                                    >{{
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.iconCorona
                                    }}
                                </v-icon>
                                <span
                                    v-if="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.text
                                    "
                                    class="font-weight-bold"
                                    :style="
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.style
                                    "
                                    >{{
                                        SIMBOLOGIA.find(
                                            (s) =>
                                                s.id == data.item.simbologiaId
                                        ).datos.text
                                    }}
                                </span>
                            </component>
                            <v-col
                                v-if="!data.item.simbologiaId && s == 'item'"
                                cols="1"
                                class="text-center"
                            >
                                <v-icon> </v-icon>
                            </v-col>
                            <component
                                :is="s == 'item' ? 'v-col' : 'div'"
                                class="align-self-center"
                            >
                                <span
                                    :class="
                                        s == 'item' || !data.item.simbologiaId
                                            ? ''
                                            : 'ml-4'
                                    "
                                >
                                    {{ data.item.nombre }}
                                </span>
                            </component>
                        </component>
                    </template>
                </v-autocomplete>
                <v-btn
                    class="mt-3 mr-4"
                    color="success"
                    @click="addPrestacionPlan"
                >
                    Agregar
                </v-btn>
                <v-checkbox
                    v-model="prestacionPorDiente"
                    label="Una prestación por cada diente"
                ></v-checkbox>
            </div>
            <v-row>
                <v-col cols="12">
                    <odontograma
                        ref="o"
                        :diagnosticos="diagnosticos"
                        :prestacionesPlan="prestacionesPlan"
                    ></odontograma>
                </v-col>
            </v-row>
        </v-card-text>

        <v-tabs v-model="tab">
            <v-tab> Plan de tratamiento </v-tab>
            <v-tab> Evoluciones </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <!--v-btn class="mb-3 mt-6 ml-2 success" @click="1">
                    Agregar prestación
                </v-btn-->
                <template>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Prestación</th>
                                    <th class="text-left">Dientes</th>
                                    <th class="text-left" style="width: 220px">
                                        Estado
                                    </th>
                                    <th class="text-left">Total</th>
                                    <th class="text-left" style="width: 100px">
                                        Eliminar
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(pp, i) in prestacionesPlan"
                                    :key="i"
                                >
                                    <td>{{ pp.prestacion.nombre }}</td>
                                    <td
                                        v-if="
                                            pp.dientes.find(
                                                (diente) => diente.caras
                                            )
                                        "
                                    >
                                        <div
                                            v-for="(diente, i) in pp.dientes"
                                            :key="i"
                                        >
                                            {{ formatDiente(diente) }}
                                        </div>
                                    </td>
                                    <td v-else>
                                        <span
                                            v-for="(diente, i) in pp.dientes"
                                            :key="i"
                                        >
                                            {{
                                                formatDiente(diente) +
                                                (i == pp.dientes.length - 1
                                                    ? ""
                                                    : ",")
                                            }}
                                        </span>
                                    </td>
                                    <td>
                                        <v-select
                                            v-model="pp.estado"
                                            :items="estados"
                                            @click="prevEstado = pp.estado"
                                            @change="cambiarEstado(pp)"
                                        >
                                            <template
                                                v-for="e in [
                                                    'item',
                                                    'selection',
                                                ]"
                                                v-slot:[e]="data"
                                            >
                                                <div :key="e">
                                                    <v-icon
                                                        :color="
                                                            data.item.value
                                                                ? 'green'
                                                                : 'red'
                                                        "
                                                        class="mr-2"
                                                    >
                                                        mdi-circle
                                                    </v-icon>
                                                    <span>
                                                        {{ data.item.text }}
                                                    </span>
                                                </div>
                                            </template>
                                        </v-select>
                                    </td>
                                    <td>{{ "$" + pp.total.toFixed(2) }}</td>
                                    <td>
                                        <v-icon
                                            color="red"
                                            @click="
                                                prestacionPlan = pp;
                                                confirmDelete = true;
                                            "
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
                <v-row class="mt-6" v-if="prestacionesPlan.length > 0">
                    <v-col offset="7">
                        <template>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td
                                                class="font-weight-bold text--secondary"
                                            >
                                                Total presupuesto
                                            </td>
                                            <td>{{ getTotalFormateado() }}</td>
                                        </tr>
                                        <tr>
                                            <td
                                                class="font-weight-bold text--secondary"
                                            >
                                                Abonado
                                            </td>
                                            <td>
                                                {{ getAbonadoFormateado() }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                class="font-weight-bold text--secondary"
                                            >
                                                Realizado
                                            </td>
                                            <td>
                                                {{ getRealizadoFormateado() }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                class="font-weight-bold text--secondary"
                                            >
                                                Saldo
                                            </td>
                                            <td
                                                :class="
                                                    getSaldo() > 0
                                                        ? 'green--text'
                                                        : getSaldo() < 0
                                                        ? 'red--text'
                                                        : ''
                                                "
                                                class="font-weight-bold"
                                            >
                                                ${{ getSaldo().toFixed(2) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                    </v-col>
                </v-row>
                <v-row class="text-end mb-1 mr-1 mt-3">
                    <v-col>
                        <v-btn
                            class="mr-2"
                            color="success"
                            @click="dialogEnviar = true"
                            >Enviar por correo</v-btn
                        >
                        <v-btn color="primary" @click="imprimirPlan"
                            >Imprimir</v-btn
                        >
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <template>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Prestación</th>
                                    <th class="text-left">Dientes</th>
                                    <th class="text-left">Escrito por</th>
                                    <th class="text-left">Fecha</th>
                                    <th class="text-left">Evolución</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(pp, i) in prestacionesPlan"
                                    :key="i"
                                >
                                    <td>{{ pp.prestacion.nombre }}</td>
                                    <td
                                        v-if="
                                            pp.dientes.find(
                                                (diente) => diente.caras
                                            )
                                        "
                                    >
                                        <div
                                            v-for="(diente, i) in pp.dientes"
                                            :key="i"
                                        >
                                            {{ formatDiente(diente) }}
                                        </div>
                                    </td>
                                    <td v-else>
                                        <span
                                            v-for="(diente, i) in pp.dientes"
                                            :key="i"
                                        >
                                            {{
                                                formatDiente(diente) +
                                                (i == pp.dientes.length - 1
                                                    ? ""
                                                    : ",")
                                            }}
                                        </span>
                                    </td>
                                    <td>
                                        {{
                                            pp.evolucion
                                                ? pp.evolucion.usuario
                                                    ? pp.evolucion.usuario
                                                          .nombres +
                                                      " " +
                                                      pp.evolucion.usuario
                                                          .apellidos
                                                    : ""
                                                : ""
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            pp.evolucion
                                                ? formatearFechaIsoAMx(
                                                      pp.evolucion.fecha
                                                  )
                                                : ""
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            pp.evolucion
                                                ? pp.evolucion.evolucion
                                                : ""
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-tab-item>
        </v-tabs-items>

        <dialog-evolucionar
            v-if="dialogEvolucionar"
            :prestacionPlan="prestacionPlan"
            :estado="currEstado"
            @cancel="dialogEvolucionar = false"
            @success="
                dialogEvolucionar = false;
                getDiagnosticos();
                getPrestacionesPlan();
            "
        ></dialog-evolucionar>

        <dialog-enviar
            ref="dialogEnviarCorreo"
            v-if="dialogEnviar"
            :html2pdf="$refs.template.$refs.html2pdf"
            titulo="Envío de presupuesto"
            @close="dialogEnviar = false"
            @enviar="
                imprimir = false;
                correo = $event;
                $nextTick(function () {
                    $refs.template.$refs.html2pdf.generatePdf();
                });
            "
        ></dialog-enviar>

        <plan-tratamiento-template
            ref="template"
            :prestacionesPlan="prestacionesPlan"
            :plan="plan"
            :imprimir="imprimir"
            :correo="correo"
            @close="dialogEnviar = false"
        ></plan-tratamiento-template>

        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminarPrestacionPlan();
            "
        ></confirm>
    </v-card>
</template>

<script>
import Odontograma from "@/components/Odontograma.vue";
import DialogEvolucionar from "@/components/DialogEvolucionar.vue";
import DialogEnviar from "@/components/DialogEnviarCorreo.vue";
import Confirm from "@/components/Confirm.vue";
import PlanTratamientoTemplate from "@/components/PlanTratamientoTemplate.vue";
import DiagnosticosService from "@/services/diagnosticos.service.js";
import PrestacionesService from "@/services/prestaciones.service.js";
import PlanesService from "@/services/planes.service.js";
import { Urls } from "@/consts.js";
import { ESTADOS } from "@/consts.js";
import { DIENTES } from "@/consts.js";
import { formatearFechaIsoAMx } from "@/utils.js";

export default {
    data: () => ({
        pacId: undefined,
        tareaId: undefined,
        planId: undefined,
        plan: {},
        diagnosticos: [],
        prestaciones: [],
        prestacionesPlan: [],
        prestacion: undefined,
        prestacionPlan: {},
        dialogEvolucionar: false,
        confirmDelete: false,
        prevEstado: undefined,
        currEstado: undefined,
        tab: null,
        dialogEnviar: false,
        imprimir: true,
        correo: undefined,
        prestacionPorDiente: true,
    }),
    components: {
        Odontograma,
        DialogEvolucionar,
        DialogEnviar,
        PlanTratamientoTemplate,
        Confirm,
    },
    computed: {
        estados() {
            return ESTADOS;
        },
        SIMBOLOGIA() {
            return this.$store.state.simbologia;
        },
    },
    methods: {
        imprimirPlan() {
            this.imprimir = true;
            this.$nextTick(function () {
                this.$refs.template.$refs.html2pdf.generatePdf();
            });
        },
        formatearFechaIsoAMx(fecha) {
            return formatearFechaIsoAMx(fecha);
        },
        loadPlan() {
            this.pacId = this.$route.params.pacienteId;
            this.tareaId = this.$route.params.tareaId;
            this.planId = this.$route.params.planId;
            if (!this.planId || !this.pacId) return;

            PlanesService.getPlan(this.planId).then((data) => {
                if (!data) return;
                this.plan = data;
            });
        },
        getDiagnosticos() {
            this.pacId = this.$route.params.pacienteId;
            if (!this.pacId) return;

            DiagnosticosService.getDiagnosticos(this.pacId).then((data) => {
                if (!data) return;
                this.diagnosticos = data;
            });
        },
        getPrestaciones() {
            PrestacionesService.getPrestaciones().then((data) => {
                if (!data) return;
                this.prestaciones = data;
            });
        },
        getPrestacionesPlan() {
            PlanesService.getPrestacionesPlan(this.planId).then((data) => {
                if (!data) return;
                this.prestacionesPlan = data;
            });
        },
        addPrestacionPlan() {
            if (!this.prestacion) {
                this.$root.showMessage("Selecciona una prestación");
                return;
            }

            let dientesSeleccionados = this.$refs.o.getDientesSeleccionados();
            if (
                this.prestacion.simbologiaId &&
                dientesSeleccionados.length < 1
            ) {
                this.$root.showMessage(
                    "Debes seleccionar al menos 1 cara dental"
                );
                return;
            }

            let pps = [];
            if (this.prestacionPorDiente && dientesSeleccionados.length > 0) {
                dientesSeleccionados.forEach((d) => {
                    let pp = {
                        planId: this.planId,
                        prestacion: { id: this.prestacion.id },
                        tareaId: this.tareaId,
                        dientes: [{ numero: d.numero, caras: d.caras }],
                        total: this.prestacion.precio,
                    };
                    pps.push(pp);
                });
            } else {
                let pp = {
                    planId: this.planId,
                    prestacion: { id: this.prestacion.id },
                    tareaId: this.tareaId,
                    dientes: dientesSeleccionados,
                    total: this.prestacion.precio,
                };
                pps.push(pp);
            }

            this.$http
                .post(Urls.PLANES_URL + "/prestaciones", pps)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage(
                        "Prestaciones agregadas correctamente"
                    );
                    this.getPrestacionesPlan();
                });
        },
        eliminarPrestacionPlan() {
            this.$http
                .delete(
                    Urls.PLANES_URL +
                        "/prestacionPlan/" +
                        this.prestacionPlan.id
                )
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage(
                        "Prestación eliminada correctamente"
                    );
                    this.getPrestacionesPlan();
                });
        },
        getTotalFormateado() {
            if (this.prestacionesPlan.length == 0) return null;
            let total = this.prestacionesPlan.reduce(
                (a, pp) => a + pp.total,
                0
            );
            return "$" + total.toFixed(2);
        },
        getAbonado() {
            if (this.prestacionesPlan.length == 0) return null;
            let abonado = this.prestacionesPlan.reduce(
                (total, pp) => total + this.getAbonadoPrestacion(pp),
                0
            );
            return abonado;
        },
        getAbonadoPrestacion(pp) {
            return pp.pagos.reduce((a, p) => a + p.monto, 0);
        },
        getAbonadoFormateado() {
            return "$" + this.getAbonado().toFixed(2);
        },
        getRealizado() {
            if (this.prestacionesPlan.length == 0) return null;
            let realizado = this.prestacionesPlan.reduce(
                (a, pp) => (pp.estadoNombre == "COMPLETADO" ? a + pp.total : a),
                0
            );
            return realizado;
        },
        getRealizadoFormateado() {
            return "$" + this.getRealizado().toFixed(2);
        },
        getSaldo() {
            if (this.prestacionesPlan.length == 0) return null;
            return this.getAbonado() - this.getRealizado();
        },
        cambiarEstado(pp) {
            this.currEstado = pp.estado;
            this.prestacionPlan = pp;
            if (pp.estado == 1) {
                setTimeout(
                    function () {
                        pp.estado = this.prevEstado;
                    }.bind(this),
                    10
                );
                this.dialogEvolucionar = true;
            } else {
                pp.evolucion.usuario = null;
                this.$http
                    .put(Urls.PLANES_URL + "/desrealizarPrestacionPlan", pp)
                    .then((data) => {
                        if (!data) return;
                        this.$root.showMessage(
                            "Se ha eliminado la evolución correctamente"
                        );
                        this.getPrestacionesPlan();
                    });
            }
        },
        formatDiente(diente) {
            if (!diente.caras) return diente.numero;
            let caras = diente.caras
                .split(",")
                .map((c) => DIENTES[diente.numero][c])
                .join(", ");
            return diente.numero + " - " + caras;
        },
    },
    mounted() {
        this.loadPlan();
        this.getDiagnosticos();
        this.getPrestaciones();
        this.getPrestacionesPlan();
    },
};
</script>

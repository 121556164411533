import axios from "axios";
import { Urls } from "@/consts.js";

const AdjuntoService = {
    getAdjuntos: function (pacienteId, totalItems) {
        return axios.get(Urls.DOCS_URL+ "/galeria/"+pacienteId+"/"+totalItems);
    },
    getCatalogoTipoDocumento:function(){
        return axios.get(Urls.DOCS_URL+"/galeria/catalogo")
    },
    deleteAdjunto:function(pacienteId, identificador){
        return axios.delete(Urls.DOCS_URL+"/delete/"+pacienteId+"/"+identificador);
    },
    download:function(pacienteId, identificador){
        return axios.get(Urls.DOCS_URL+"/download/"+pacienteId+"/"+identificador, {responseType: "blob"});
    },
    updateDescription:function(pacienteId, identificador, params){
        return axios.put(Urls.DOCS_URL+"/descripcion/"+pacienteId+"/"+identificador, params);
    }

};

export default AdjuntoService;
<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="300px"
        @input="$emit('cancel')"
    >
        <v-card>
            <v-card-title> ¿Qué desea generar? </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-container>
                    <v-row class="text-center">
                        <v-col cols="12">
                            <v-btn color="orange"
                                        dark
                                        @click="go(1)"
                                      >
                            <v-icon>mdi-alpha-f-box-outline</v-icon>
                                actura
                            </v-btn>
                        </v-col>
                        <v-col cols="12">

                        <v-btn color="cyan"
                                        dark
                                        @click="go(0)"
                                      >
                            <v-icon>mdi-alpha-r-box-outline</v-icon>
                            ecibo por honorarios
                        </v-btn>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('cancel')">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.img:hover {
    cursor: pointer;
}
</style>

<script>


export default {
    data() {
        return {
            dialog: true,
        };
    },
    //typename (appt: appointment, pay:payment)
    props: ["uuid", "typename"],

    computed: {
        usuario: function () {
            return this.$store.state.usuario;
        },
    },
    methods: {
        go:function(opt){
            this.$emit('cancel');
            window.open(process.env.VUE_APP_COMPRAS_URL + "/cfdi/generar/"+opt+"/"+this.typename+"/"+this.uuid+"?at="+this.usuario.sessionUuid);
        }
    },
    beforeMount() {

    },
};
</script>
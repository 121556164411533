<template>
    <AgendaComponent :places="places" :events="events" @change="loadAgenda" ref="agenda">
    </AgendaComponent>
</template>

<script>
import AgendaComponent from "@/components/AgendaComponent.vue";

export default {
    data: () => ({
        places: [],
        events: [],
    }),
    components: {
        AgendaComponent,
    },
    computed: {
        usuario: function () {
            return this.$store.state.usuario;
        },

    },
    methods: {
        loadPlaces() {
            this.$http.get("/api/consultorios/horarios").then((data) => {
                if(data && data.length > 0){
                    this.places = data;
                }
                else{
                    if(this.usuario.roles.includes("ASI")){
                        this.$root.showMessage('No hay consultorios compartidos, le pedimos contactar al médico para más información', 'warning');
                        this.$root.logout();
                        return;
                    }

                    this.$root.showMessage('No tiene consultorios, le pedimos registre al menos un consultorio', 'warning');
                    this.$router.push({ name: "Consultorios" });

                }
            });
        },
        loadAgenda(date, lugar_id, zona_horaria) {

            this.$http
                .get("/api/agenda/listar", {
                    params: {
                        d: date,
                        c: lugar_id,
                        z: zona_horaria,
                    },
                })
                .then((data) => {
                    if (!data) {
                        this.events = [];
                        return;
                    }
                    this.events = data;
                    this.$refs.agenda.addListnrCalendarBodyPosition();


                });
        },
    },
    mounted() {
        this.loadPlaces();
    },
};
</script>

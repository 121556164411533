<template>
    <v-card class="mx-16 mt-6">
        <v-card-title> Asistentes </v-card-title>
        <v-data-table
            :headers="headers"
            :items="asistentes"
            :items-per-page="10"
            :search="busqueda"
            style="width: 100%"
            hide-default-footer

        >
            <template v-slot:top>
                <v-toolbar flat dense>
                    <div class="flex-grow-1"></div>
                    <v-btn icon color="success" large
                        title="Configuración de permisos"
                        @click="show=true">
                      <v-icon>mdi-account-cog-outline</v-icon>
                    </v-btn>
                    <v-btn color="success" icon large
                        title="Nuevo asistente"
                        @click="nuevo">
                      <v-icon>mdi-account-plus</v-icon>

                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.nombre`]="{ item }">
                {{ item.nombres + " " + item.apellidos }}
            </template>
            <template v-slot:[`item.correo`]="{ item }">
                {{ item.correo }}
            </template>
            <template v-slot:[`item.telefono`]="{ item }">
                <v-chip
                  v-if="item.telefono"
                  @click="call(item.telefono)"
                  color="primary"
                          outlined
                  >
                  <v-icon small left>
                              mdi-phone
                            </v-icon>
                  {{ item.telefono }}</v-chip
                >
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
                <v-icon color="green lighten-1" @click="asistente=item;dialogAsistente=true">mdi-pencil</v-icon>
                <v-icon color="amber"
                                    @click="asistente=item; share=true"
                                >
                                    mdi-share
                                </v-icon>
                <v-icon
                    color="red"
                    @click="
                        asistente = item;
                        confirmDelete = true;
                    "
                >
                    mdi-delete
                </v-icon>

            </template>
            <template slot="no-data">
                <div>Sin asistentes</div>
            </template>
        </v-data-table>

        <dialog-asistente
            ref="da"
            v-if="dialogAsistente"
            :asistente="asistente"
            @close="dialogAsistente = false "
            @success="
                dialogAsistente = false;
                loadAsistentes();
            "
        ></dialog-asistente>

        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminar();
            "
        ></confirm>

        <v-dialog v-if="show" v-model="show"  max-width="450" scrollable>
            <permisos @close="show=false"></permisos>
        </v-dialog>

         <dialog-compartir-agenda
              v-if="share"
              :asistente="asistente"
              @close="share = false"
            >
         </dialog-compartir-agenda>
    </v-card>
</template>

<script>
import DialogAsistente from "../components/DialogAsistente.vue";
import Confirm from "../components/Confirm.vue";
import { Urls } from "@/consts.js";
import DialogCompartirAgenda from "../components/DialogCompartirAgenda.vue";


export default {
    data: () => ({
        asistentes: [],
        asistente: {},
        dialogAsistente: false,
        confirmDelete: false,
        busqueda: "",
        headers: [
            { text: "Nombre", value: "nombre", width: "50%" },
            { text: "Correo", value: "correo" },
            { text: "Teléfono móvil", value: "telefono", sortable: false},
            { text: "Acciones", value: "acciones", sortable: false },
        ],
        show:false,
        share:false
    }),
    components: {
        DialogAsistente,
        Confirm,
        Permisos: () => import('@/components/Permisos'),
        DialogCompartirAgenda
    },
    methods: {
        loadAsistentes() {
            this.$http.get(Urls.ASISTENTES_URL).then((data) => {
                if (!data) return;
                this.asistentes = data;
            });
        },
        // editar(prestacion) {
        //     this.prestacion = JSON.parse(JSON.stringify(prestacion));
        //     this.dialogPrestacion = true;
        // },
        eliminar() {
            this.$http
                .delete(Urls.ASISTENTES_URL + "/" + this.asistente.id)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Asistente eliminado exitosamente");
                    this.loadAsistentes();
                });
        },
        nuevo(){
            this.asistente = Object.assign({}, {genero: "F",fechaNacimiento: null});
            this.dialogAsistente = true;
        },
        call: function (val) {
            window.location.href = "tel:" + val;
        },
    },
    mounted() {
        this.loadAsistentes();
    },
};
</script>

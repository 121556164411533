<template>
    <v-dialog v-model="dialog" max-width="1000px" @input="$emit('cancel')">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Detalle del pago - {{ plan.nombre }}</span
                >
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="pagoPrestaciones.filter((ppp) => ppp.status)"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:[`item.dientes`]="{ item }">
                        <div
                            v-if="
                                item.prestacionPlan.dientes.find(
                                    (diente) => diente.caras
                                )
                            "
                        >
                            <div
                                v-for="(diente, i) in item.prestacionPlan
                                    .dientes"
                                :key="i"
                            >
                                {{ formatDiente(diente) }}
                            </div>
                        </div>
                        <div v-else>
                            <span
                                v-for="(diente, i) in item.prestacionPlan
                                    .dientes"
                                :key="i"
                            >
                                {{
                                    formatDiente(diente) +
                                    (i == item.prestacionPlan.dientes.length - 1
                                        ? ""
                                        : ",")
                                }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                        ${{ item.prestacionPlan.total.toFixed(2) }}
                    </template>
                    <template v-slot:[`item.monto`]="{ item }">
                        ${{ item.monto.toFixed(2) }}
                    </template>
                    <template v-slot:[`item.eliminar`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="
                                        pagoPrestacion = item;
                                        confirmDelete = true;
                                    "
                                >
                                    <v-icon color="red"> mdi-delete </v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                    </template>
                    <template slot="no-data">
                        <div>Sin prestaciones pagadas</div>
                    </template>
                </v-data-table>
            </v-card-text>

            <div v-if="pagoPrestaciones.find((ppp) => !ppp.status)">
                <v-card-title>
                    <span class="headline">Pagos eliminados</span>
                </v-card-title>

                <v-divider class="mx-4"></v-divider>

                <v-card-text>
                    <v-data-table
                        :headers="headersEliminados"
                        :items="pagoPrestaciones.filter((ppp) => !ppp.status)"
                        hide-default-footer
                    >
                        <template v-slot:[`item.caras`]="{ item }">
                            {{
                                formatCaras(
                                    item.prestacionPlan.diente,
                                    item.prestacionPlan.caras
                                )
                            }}
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            ${{ item.prestacionPlan.total.toFixed(2) }}
                        </template>
                        <template v-slot:[`item.monto`]="{ item }">
                            ${{ item.monto.toFixed(2) }}
                        </template>
                        <template v-slot:[`item.fechaEliminacion`]="{ item }">
                            {{ formatearDateTimeIsoAMx(item.fechaEliminacion) }}
                        </template>
                        <template slot="no-data">
                            <div>Sin prestaciones a pagar</div>
                        </template>
                    </v-data-table>
                </v-card-text>
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('cancel')">
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminar();
            "
        ></confirm>
    </v-dialog>
</template>


<script>
import { Urls } from "@/consts.js";
import { DIENTES } from "@/consts.js";
import Confirm from "../components/Confirm.vue";
import { formatearDateTimeIsoAMx } from "@/utils.js";

export default {
    data() {
        return {
            dialog: true,
            pagoPrestaciones: [],
            headers: [
                {
                    text: "Prestación",
                    value: "prestacionPlan.prestacion.nombre",
                },
                { text: "Dientes", value: "dientes" },
                { text: "Total", value: "total" },
                { text: "Monto abonado", value: "monto" },
                {
                    text: "Eliminar",
                    value: "eliminar",
                    sortable: false,
                },
            ],
            headersEliminados: [
                {
                    text: "Prestación",
                    value: "prestacionPlan.prestacion.nombre",
                },
                { text: "Diente", value: "prestacionPlan.diente" },
                { text: "Caras", value: "caras" },
                { text: "Total", value: "total" },
                { text: "Monto abonado", value: "monto" },
                {
                    text: "Fecha de eliminación",
                    value: "fechaEliminacion",
                },
            ],
            confirmDelete: false,
            pagoPrestacion: undefined,
        };
    },
    props: {
        plan: Object,
        pagoPlan: Object,
    },
    components: {
        Confirm,
    },
    methods: {
        getPagoPrestaciones() {
            this.$http
                .get(Urls.PAGOS_URL + "/pagoPrestaciones/" + this.pagoPlan.id)
                .then((data) => {
                    if (!data) return;
                    this.pagoPrestaciones = data;
                });
        },
        formatDiente(diente) {
            if (!diente.caras) return diente.numero;
            let caras = diente.caras
                .split(",")
                .map((c) => DIENTES[diente.numero][c])
                .join(", ");
            return diente.numero + " - " + caras;
        },
        eliminar() {
            this.$http
                .patch(
                    Urls.PAGOS_URL +
                        "/pagoPrestacion/eliminar/" +
                        this.pagoPrestacion.id
                )
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Pago eliminado exitosamente");
                    this.$emit("success");
                });
        },
        formatearDateTimeIsoAMx(fecha) {
            return formatearDateTimeIsoAMx(fecha);
        },
    },
    mounted() {
        this.getPagoPrestaciones();
    },
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000px"},on:{"input":function($event){return _vm.$emit('cancel')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Detalle del pago - "+_vm._s(_vm.plan.nombre))])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pagoPrestaciones.filter((ppp) => ppp.status),"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.dientes`,fn:function({ item }){return [(
                            item.prestacionPlan.dientes.find(
                                (diente) => diente.caras
                            )
                        )?_c('div',_vm._l((item.prestacionPlan
                                .dientes),function(diente,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente))+" ")])}),0):_c('div',_vm._l((item.prestacionPlan
                                .dientes),function(diente,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(_vm.formatDiente(diente) + (i == item.prestacionPlan.dientes.length - 1 ? "" : ","))+" ")])}),0)]}},{key:`item.total`,fn:function({ item }){return [_vm._v(" $"+_vm._s(item.prestacionPlan.total.toFixed(2))+" ")]}},{key:`item.monto`,fn:function({ item }){return [_vm._v(" $"+_vm._s(item.monto.toFixed(2))+" ")]}},{key:`item.eliminar`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.pagoPrestacion = item;
                                    _vm.confirmDelete = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Sin prestaciones pagadas")])])],2)],1),(_vm.pagoPrestaciones.find((ppp) => !ppp.status))?_c('div',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Pagos eliminados")])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersEliminados,"items":_vm.pagoPrestaciones.filter((ppp) => !ppp.status),"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.caras`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatCaras( item.prestacionPlan.diente, item.prestacionPlan.caras ))+" ")]}},{key:`item.total`,fn:function({ item }){return [_vm._v(" $"+_vm._s(item.prestacionPlan.total.toFixed(2))+" ")]}},{key:`item.monto`,fn:function({ item }){return [_vm._v(" $"+_vm._s(item.monto.toFixed(2))+" ")]}},{key:`item.fechaEliminacion`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatearDateTimeIsoAMx(item.fechaEliminacion))+" ")]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Sin prestaciones a pagar")])])],2)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancelar ")])],1)],1),(_vm.confirmDelete)?_c('confirm',{on:{"cancel":function($event){_vm.confirmDelete = false},"confirm":function($event){_vm.confirmDelete = false;
            _vm.eliminar();}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
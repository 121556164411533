<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
        @input="$emit('cancel')"
    >
        <v-card>
            <v-card-title>
                <span style="word-break: break-word">
                    Evolucionar prestación -
                    {{ prestacionPlan.prestacion.nombre }}
                </span>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-container>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="prestacionPlan.evolucion.evolucion"
                                    label="Evolución escrita"
                                    maxlength="512"
                                    minlength="10"
                                    autofocus
                                    :rules="[rules.required]"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('cancel')">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="evolucionar">
                    Evolucionar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { Urls } from "@/consts.js";

export default {
    data() {
        return {
            dialog: true,
            rules: {
                required: function (val) {
                    return !!val || "Campo requerido";
                },
            },
        };
    },
    props: {
        prestacionPlan: Object,
        estado: Number,
    },
    methods: {
        evolucionar() {
            if (!this.$refs.form.validate()) return;
            let pp = JSON.parse(JSON.stringify(this.prestacionPlan));
            pp.estado = this.estado;
            pp.evolucion.pacienteId = this.$route.params.pacienteId;
            pp.evolucion.tareaId = this.$route.params.tareaId;
            pp.evolucion.prestacionPlanId = this.prestacionPlan.id;
            this.$http
                .put(Urls.PLANES_URL + "/evolucionarPrestacionPlan", pp)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage(
                        "Prestación evolucionada correctamente"
                    );
                    this.$emit("success");
                });
        },
    },
    beforeMount() {
        if (!this.prestacionPlan.evolucion) this.prestacionPlan.evolucion = {};
    },
};
</script>
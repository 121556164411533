<template>
    <div class="d-flex mt-3">
        <v-card tile class="mr-3">
            <v-navigation-drawer width="262" permanent>
                <div
                    class="d-flex flex-column justify-space-between fill-height"
                >
                    <div>
                        <v-list>
                            <v-list-item link @click="editarPaciente">
                                <v-list-item-content class="text-center">
                                    <v-list-item-title class="title text-wrap">
                                        {{ nombreCompleto }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{
                                            paciente.years +
                                            " años " +
                                            (paciente.months
                                                ? paciente.months + " meses"
                                                : "")
                                        }}</v-list-item-subtitle
                                    >
                                    <v-list-item-title class="overline mt-5"
                                        >Fecha de nacimiento</v-list-item-title
                                    >
                                    <v-list-item-subtitle class="text-center">{{
                                        fechaNacimiento
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>

                        <v-expansion-panels
                            accordion
                            v-if="showAllergies || showDiseases"
                        >
                            <v-expansion-panel v-if="showAllergies">
                                <v-expansion-panel-header
                                    expand-icon="mdi-menu-down"
                                    class="pa-1"
                                >
                                    <v-subheader
                                        >Alergias{{
                                            textAllergies
                                        }}:</v-subheader
                                    >
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <!--
                                        Opcion 1: color=#FED1B4 text-color=black
                                        Opción 2: orange - white
                                    -->
                                        <v-col
                                            class="pa-0"
                                            cols="auto"
                                            v-for="allergy in allergies"
                                            v-bind:key="allergy.id"
                                        >
                                            <v-chip
                                                class="ma-2"
                                                color="orange darken-2"
                                                text-color="white"
                                                x-small
                                            >
                                                <v-icon
                                                    small
                                                    v-if="
                                                        allergy.catalogoSugeridoId &&
                                                        allergy.catalogoSugeridoId >
                                                            0
                                                    "
                                                >
                                                    mdi-bookmark-multiple-outline
                                                </v-icon>
                                                <span
                                                    style="
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        font-weight: bold;
                                                    "
                                                    :title="allergy.nombre"
                                                >
                                                    {{ allergy.nombre }}
                                                </span>
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-if="
                                            otherAllergies &&
                                            otherAllergies.length > 0
                                        "
                                    >
                                        <v-col cols="auto" class="pa-0">
                                            <span
                                                class="text-caption"
                                                :title="otherAllergies"
                                            >
                                                {{
                                                    otherAllergies.substring(
                                                        0,
                                                        128
                                                    )
                                                }}{{
                                                    otherAllergies.length > 128
                                                        ? "..."
                                                        : ""
                                                }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="showDiseases">
                                <v-expansion-panel-header
                                    expand-icon="mdi-menu-down"
                                    class="pa-1"
                                >
                                    <v-subheader>
                                        Enfermedades que padece{{
                                            textDiseases
                                        }}
                                    </v-subheader>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row d-flex>
                                        <v-col
                                            class="pa-0"
                                            cols="auto"
                                            v-for="(disease, idx) in diseases"
                                            v-if="disease.show"
                                            v-bind:key="idx"
                                        >
                                            <v-chip
                                                class="ma-2"
                                                color="yellow darken-2"
                                                text-color="black"
                                                x-small
                                            >
                                                <span
                                                    style="
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        font-weight: bold;
                                                    "
                                                    :title="disease"
                                                    class="text-caption"
                                                >
                                                    {{ disease.texto }}
                                                </span>
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-if="
                                            otherDiseases &&
                                            otherDiseases.length > 0
                                        "
                                    >
                                        <v-col cols="auto" class="pa-1">
                                            <span
                                                class="text-caption"
                                                :title="otherDiseases"
                                            >
                                                {{
                                                    otherDiseases.substring(
                                                        0,
                                                        128
                                                    )
                                                }}{{
                                                    otherDiseases.length > 128
                                                        ? "..."
                                                        : ""
                                                }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-spacer></v-spacer>

                        <v-divider></v-divider>

                        <v-list nav dense>
                            <v-list-item-group color="primary">
                                <v-list-item
                                    v-for="(item, i) in filteredItems"
                                    :key="i"
                                    :to="{
                                        name: item.to,
                                        params: {
                                            pacienteId: pacId,
                                            tareaId: tareaId,
                                        },
                                    }"
                                    exact
                                >
                                    <v-list-item-icon>
                                        <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="item.text"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </div>
                    <div class="ml-6 mr-6" v-if="tarea">
                        <v-form ref="form">
                            <v-text-field label="Costo consulta" v-model="tarea.infoCita.costo"
                            prefix="$"
                            maxlength="11"
                            :rules="[rules.required, rules.number]"
                            :disabled="tarea.status == 'TERMINADA'"
                            ></v-text-field>
                        </v-form>
                    </div>
                    <div v-if="tarea && (!tarea.infoCita.pagoEfectuado || tarea.infoCita.facturada != 1)" class="text-center mt-6 mb-4">

                        <v-btn
                            outlined
                            color="blue darken-2"
                            @click="
                                tarea.status == 'TERMINADA'
                                    ? (dialogOpciones = true)
                                    : terminarConsulta()
                            "
                        >
                            {{
                                tarea.status == "TERMINADA"
                                    ? "Opciones de consulta"
                                    : "Terminar consulta"
                            }}
                        </v-btn>
                    </div>
                </div>
            </v-navigation-drawer>
        </v-card>
        <v-row style="height: fit-content">
            <v-col cols="12" class="text-right font-weight-medium mt-1 pb-0">
                <span>{{ getCitaText() }}</span>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
                <router-view
                    :fc="fechaNacimiento"
                    @updated-allergies="onUpdateAllergies"
                    @updated-diseases="onUpdateDiseases"
                ></router-view>
            </v-col>
        </v-row>
        <dialog-opciones
            v-if="tarea && dialogOpciones && (!tarea.infoCita.pagoEfectuado || tarea.infoCita.facturada != 1)"
            :tarea="tarea"
            @cobro-efectuado="setCobroEfectuado"
            @cancel="cancelarDialogOpciones"

        ></dialog-opciones>



        <opcion-cfdi v-if="tareaId  && tareaId > 0 && facturar" :uuid="tarea.infoCita.uuid" :typename="typename" @cancel="facturar= false"></opcion-cfdi>
    </div>
</template>

<script>
import { Urls } from "@/consts.js";
import { formatearDateTimeIsoAMx } from "@/utils.js";
import DialogOpciones from "@/components/DialogOpcionesConsulta.vue";
import OpcionCFDI from "@/components/OpcionCFDI.vue";

export default {
    data: () => ({
        edicion: false,
        paciente: {},
        pacId: 0,
        tareaId: 0,
        tarea: undefined,
        allergies: [],
        otherAllergies: null,
        dialogOpciones: false,
        diseases: [
            { texto: "Diabetes", show: false },
            { texto: "Obesidad", show: false },
            { texto: "Hipertensión arterial", show: false },
        ],
        otherDiseases: null,
        facturar:false,
        typename:"appt",
        rules: {
                required: function (val) {
                    return !!val || "Campo requerido";
                },
                number: function (val) {

                    return !isNaN(val) && parseInt(val) >= 0 || "Formato inválido";
                },
            },

    }),
    components: {
        DialogOpciones,
        "opcion-cfdi": OpcionCFDI,
    },
    computed: {
        showAllergies: function () {
            return (
                this.allergies.length > 0 ||
                (this.otherAllergies && this.otherAllergies.length > 0)
            );
        },
        showDiseases: function () {
            let total = this.diseases.filter((e) => e.show).length;
            return (
                total || (this.otherDiseases && this.otherDiseases.length > 0)
            );
        },
        textAllergies: function () {
            let total = this.allergies.length;
            return total > 0 ? " (" + total + ")" : "";
        },
        textDiseases: function () {
            let total = this.diseases.filter((e) => e.show).length;
            return total > 0 ? " (" + total + ")" : "";
        },
        nombreCompleto: function () {
            if (this.paciente) {
                return this.paciente.nombres + " " + this.paciente.apellidos;
            }
            return "";
        },
        usuario: function () {
            return this.$store.state.usuario;
        },
        fechaNacimiento: function () {
            if (this.$store.state.paciente) {
                return this.$store.state.paciente.nacimiento;
            }
            return "-";
        },
        items: function () {
            return [
                // { text: "Ficha", icon: "mdi-format-float-left", to: "agenda" },
                {
                    text: "Antecedentes",
                    icon: "mdi-card-account-details",
                    to: "antecedentes",
                    show: !this.usuario.roles.includes("ASI"),
                },
                {
                    text: "Datos personales",
                    icon: "mdi-account-edit",
                    to: "paciente",
                },
                {
                    text: "Alergias",
                    icon: "mdi-allergy",
                    to: "alergias",
                    show: !this.usuario.roles.includes("ASI"),
                },
                {
                    text: "Documentos e imágenes",
                    icon: "mdi-folder-image",
                    to: "galeria",
                },
                {
                    text: "Diagnósticos y preexistencias",
                    icon: "mdi-tooth-outline",
                    to: "diagnosticos",
                    show: !this.usuario.roles.includes("ASI"),
                },
                {
                    text: "Signos vitales y glucosa",
                    icon: "mdi-hand-heart",
                    to: "vitalsigns",
                    key: "vs",
                    show:
                        !this.usuario.roles.includes("ASI") &&
                        typeof this.tareaId !== "undefined" &&
                        this.tareaId > 0,
                },
                {
                    text: "Planes de tratamiento",
                    icon: "mdi-clipboard-list",
                    to: "planes",
                    show: !this.usuario.roles.includes("ASI"),
                },
                {
                    text: "Receta médica",
                    icon: "mdi-prescription",
                    to: "preescription",
                    show:
                        !this.usuario.roles.includes("ASI") &&
                        typeof this.tareaId !== "undefined" &&
                        this.tareaId > 0,
                },
                {
                    text: "Pagos",
                    icon: "mdi-currency-usd",
                    to: "pagos",
                },
                // {
                //     text: "Evoluciones",
                //     icon: "mdi-debug-step-over",
                //     to: "evoluciones",
                // },
            ];
        },
        filteredItems() {
            return this.items.filter(
                (item) => item.show || item.show == undefined
            );
        },
    },
    methods: {
        onUpdateDiseases: function (diabetes, obesidad, hipertension, otras) {
            this.diseases[0].show = diabetes;
            this.diseases[1].show = obesidad;
            this.diseases[2].show = hipertension;

            this.otherDiseases = otras;
        },

        onUpdateAllergies: function (evt, item) {
            if (evt == "add") {
                this.allergies.push(item);
            } else if (evt == "delete") {
                var cp = this.allergies;
                cp.splice(cp.indexOf(item), 1);
                this.allergies = cp;
            } else if (evt == "change-others") {
                this.otherAllergies = item;
            }
        },
        getPaciente: function () {
            this.$http
                .get("/api/pacientes/getPaciente/" + this.pacId)
                .then((data) => {
                    if (!data) return;
                    this.paciente = data;
                    if (data.alergias) {
                        this.allergies = data.alergias.alergias;
                        this.otherAllergies = data.alergias.otras;
                    }

                    if (data.antecedentes) {
                        this.onUpdateDiseases(
                            data.antecedentes.diabetes,
                            data.antecedentes.obesidad,
                            data.antecedentes.hipertension,
                            data.antecedentes.enfermedades
                        );
                    }
                    this.$store.commit("paciente", data);
                });
        },
        getTarea: function () {
            this.$http
                .get(Urls.AGENDA_URL + "/tarea/" + this.tareaId)
                .then((data) => {
                    if (!data) return;
                    this.tarea = data;
                });
        },
        terminarConsulta: function () {

            if (!this.$refs.form.validate()) {
                return;
            }

            var data = new FormData();
            data.append("costo", this.tarea.infoCita.costo);


            this.$http
                .post(Urls.AGENDA_URL + "/terminarConsulta/" + this.tareaId, data)
                .then((data) => {
                    if (!data) return;
                    this.dialogOpciones = true;
                });
        },
        getCitaText() {
            if (!this.tarea) return "";
            let dia = new Intl.DateTimeFormat("es-MX", {
                weekday: "long",
            }).format(new Date(this.tarea.fechaInicio));
            let fechaInicio = formatearDateTimeIsoAMx(this.tarea.fechaInicio);
            let horaFin = new Intl.DateTimeFormat("es-MX", {
                hour: "numeric",
                minute: "numeric",
            }).format(new Date(this.tarea.fechaFin));
            return "Agendado: " + dia + ", " + fechaInicio + " - " + horaFin;
        },
        editarPaciente: function () {
            this.$router.push({
                name: "paciente",
                params: { pacienteId: this.pacId, tareaId: this.tareaId },
            });
        },
        cancelarDialogOpciones:function(facturar){
            this.dialogOpciones = false;
            this.facturar = facturar;

        },
        setCobroEfectuado(){
            this.tarea.pagado = true;
            this.tarea.infoCita.pagoEfectuado= true;
        }

    },
    beforeMount() {
        this.pacId = this.$route.params.pacienteId;
        this.tareaId = this.$route.params.tareaId;
        this.getPaciente();
        if(!(this.tareaId == undefined || this.tareaId == 'pagos')){

            this.getTarea();
        }
    },
    mounted() {},
};
</script>


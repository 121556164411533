<template>
    <v-card width="100%">
        <v-card-title>
            <span class="headline">Pagos - {{ plan ? plan.nombre : "" }}</span>
            <v-spacer></v-spacer>
            <v-btn @click="$router.push({ name: 'pagos' })" color="success">
                Volver
            </v-btn>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="pagos.filter((pp) => pp.status)"
                hide-default-footer
            >
                <template v-slot:[`item.monto`]="{ item }">
                    {{ "$" + getTotalPago(item).toFixed(2) }}
                </template>
                <template v-slot:[`item.usuario`]="{ item }">
                    {{ item.usuario.nombres + " " + item.usuario.apellidos }}
                </template>
                <template v-slot:[`item.fechaRegistro`]="{ item }">
                    {{ formatearDateTimeIsoAMx(item.fechaRegistro) }}
                </template>
                <template v-slot:[`item.folioFiscal`]="{ item}">
                    <v-chip class="ma-2" color="info"  small
                        v-if="item.folioFiscal"
                        @click="verDetalle(item.folioFiscal)">
                        <v-icon left>mdi-details</v-icon>
                        {{item.folioFiscal}}
                    </v-chip>
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    pagoPlan = item;
                                    dialogDetallePago = true;
                                "
                            >
                                <v-icon color="success"> mdi-eye </v-icon>
                            </v-btn>
                        </template>
                        <span>Ver detalle</span>
                    </v-tooltip>
                    <template v-if="usuario.isoPais == 'MX' && item.folioFiscal == null">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    pagoPlan = item;
                                    openGenerarCFDI = true;
                                "
                            >
                                <v-icon color="black"> mdi-file </v-icon>
                            </v-btn>
                        </template>
                        <span>Generar CFDI</span>
                    </v-tooltip>
                    </template>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    pagoPlan = item;
                                    dialogEditarPago = true;
                                "
                            >
                                <v-icon color="primary"> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="imprimirPago(item)"
                            >
                                <v-icon color="black"> mdi-printer </v-icon>
                            </v-btn>
                        </template>
                        <span>Imprimir</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    pagoPlan = item;
                                    dialogEnviar = true;
                                "
                            >
                                <v-icon color="yellow darken-2">
                                    mdi-email
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Enviar por correo</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    pagoPlan = item;
                                    confirmDelete = true;
                                "
                            >
                                <v-icon color="red"> mdi-delete </v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template slot="no-data">
                    <div>Sin pagos</div>
                </template>
            </v-data-table>
        </v-card-text>

        <div v-if="pagos.find((pp) => !pp.status)">
            <v-card-title>
                <span class="headline">Pagos eliminados</span>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-data-table
                    :headers="headersEliminados"
                    :items="pagos.filter((pp) => !pp.status)"
                    hide-default-footer
                >
                    <template v-slot:[`item.monto`]="{ item }">
                        {{ "$" + getTotalPago(item).toFixed(2) }}
                    </template>
                    <template v-slot:[`item.usuario`]="{ item }">
                        {{
                            item.usuario.nombres + " " + item.usuario.apellidos
                        }}
                    </template>
                    <template v-slot:[`item.fechaRegistro`]="{ item }">
                        {{ formatearDateTimeIsoAMx(item.fechaRegistro) }}
                    </template>
                    <template v-slot:[`item.fechaEliminacion`]="{ item }">
                        {{ formatearDateTimeIsoAMx(item.fechaEliminacion) }}
                    </template>
                    <template slot="no-data">
                        <div>Sin prestaciones a pagar</div>
                    </template>
                </v-data-table>
            </v-card-text>
        </div>

        <dialog-editar-pago
            v-if="dialogEditarPago"
            :pagoPlan="pagoPlan"
            :plan="plan"
            @cancel="dialogEditarPago = false"
            @success="
                dialogEditarPago = false;
                getPagos();
            "
        ></dialog-editar-pago>

        <dialog-detalle-pago
            v-if="dialogDetallePago"
            :pagoPlan="pagoPlan"
            :plan="plan"
            @cancel="dialogDetallePago = false"
            @success="
                dialogDetallePago = false;
                getPagos();
            "
        ></dialog-detalle-pago>

        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminar();
            "
        ></confirm>

        <dialog-enviar
            ref="dialogEnviarCorreo"
            v-if="dialogEnviar"
            :html2pdf="$refs.template.$refs.html2pdf"
            titulo="Envío de comprobante de pago"
            @close="dialogEnviar = false"
            @enviar="
                imprimir = false;
                correo = $event;
                $nextTick(function () {
                    $refs.template.$refs.html2pdf.generatePdf();
                });
            "
        ></dialog-enviar>

        <pago-template
            ref="template"
            :pagoPlan="pagoPlan"
            :plan="plan"
            :imprimir="imprimir"
            :correo="correo"
            @close="dialogEnviar = false"
        ></pago-template>

        <opcion-cfdi v-if="openGenerarCFDI" :uuid="pagoPlan.uuid" :typename="typename" @cancel="openGenerarCFDI= false"></opcion-cfdi>
    </v-card>
</template>

<style scoped>
</style>

<script>
import { Urls } from "@/consts.js";
import { formatearDateTimeIsoAMx } from "@/utils.js";
import DialogEditarPago from "@/components/DialogEditarPago.vue";
import DialogDetallePago from "@/components/DialogDetallePago.vue";
import DialogEnviar from "@/components/DialogEnviarCorreo.vue";
import PagoTemplate from "@/components/PagoTemplate.vue";
import Confirm from "@/components/Confirm.vue";
import PlanesService from "@/services/planes.service.js";
import OpcionCFDI from "@/components/OpcionCFDI.vue";

export default {
    data() {
        return {
            pagos: [],
            headers: [
                { text: "Fecha pago", value: "fechaRegistro" },
                { text: "Medio de pago", value: "medioPago.nombre" },
                { text: "Monto", value: "monto" },
                { text: "Observaciones", value: "observaciones" },
                { text: "Folio fiscal", value: "folioFiscal" },
                { text: "Ingresado por", value: "usuario" },
                {
                    text: "Acciones",
                    value: "acciones",
                    sortable: false,
                },
            ],
            headersEliminados: [
                { text: "Fecha pago", value: "fechaRegistro" },
                { text: "Medio de pago", value: "medioPago.nombre" },
                { text: "Monto", value: "monto" },
                { text: "Observaciones", value: "observaciones" },
                { text: "Ingresado por", value: "usuario" },
                {
                    text: "Fecha de eliminación",
                    value: "fechaEliminacion",
                },
            ],
            pagoPlan: undefined,
            planId: undefined,
            plan: undefined,
            dialogEditarPago: false,
            dialogDetallePago: false,
            dialogEnviar: false,
            confirmDelete: false,
            imprimir: true,
            correo: undefined,
            openGenerarCFDI:false,
            typename:'pay'
        };
    },
    components: {
        DialogEditarPago,
        DialogDetallePago,
        DialogEnviar,
        Confirm,
        PagoTemplate,
        "opcion-cfdi": OpcionCFDI,
    },
    computed:{
        usuario: function () {
            return this.$store.state.usuario;
        },
    },
    methods: {
        imprimirPago(pagoPlan) {
            this.pagoPlan = pagoPlan;
            this.imprimir = true;
            this.$nextTick(function () {
                this.$refs.template.$refs.html2pdf.generatePdf();
            });
        },
        getPlan() {
            PlanesService.getPlan(this.planId).then((data) => {
                if (!data) return;
                this.plan = data;
            });
        },
        getPagos() {
            this.$http.get(Urls.PAGOS_URL + "/" + this.planId).then((data) => {
                if (!data) return;
                this.pagos = data;
            });
        },
        getTotalPago(pago) {
            let total = pago.pagoPrestaciones
                .filter((pp) => (pago.status ? pp.status : true))
                .reduce((total, pp) => total + parseFloat(pp.monto || 0), 0);
            return total;
        },
        formatearDateTimeIsoAMx(fecha) {
            return formatearDateTimeIsoAMx(fecha);
        },
        eliminar() {
            this.$http
                .patch(Urls.PAGOS_URL + "/eliminar/" + this.pagoPlan.id)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Pago eliminado exitosamente", "success");
                    this.getPagos();
                });
        },
        verDetalle(ff){
            window.open(process.env.VUE_APP_COMPRAS_URL + "/cfdi/detail/"+ff+"?at="+this.usuario.sessionUuid);
        }
    },
    mounted() {
        this.planId = this.$route.params.planId;
        this.getPlan();
        this.getPagos();
    },
};
</script>
<template>
    <v-form ref="form">
        <v-data-table
            :headers="headers"
            :items="prestacionesPlan"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:[`item.dientes`]="{ item }">
                <div v-if="item.dientes.find((diente) => diente.caras)">
                    <div v-for="(diente, i) in item.dientes" :key="i">
                        {{ formatDiente(diente) }}
                    </div>
                </div>
                <div v-else>
                    <span v-for="(diente, i) in item.dientes" :key="i">
                        {{
                            formatDiente(diente) +
                            (i == item.dientes.length - 1 ? "" : ",")
                        }}
                    </span>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                {{ "$" + item.total.toFixed(2) }}
            </template>
            <template v-slot:[`item.abonado`]="{ item }">
                {{ "$" + getAbonado(item).toFixed(2) }}
            </template>
            <template v-slot:[`item.porAbonar`]="{ item }">
                {{ "$" + getPorAbonar(item).toFixed(2) }}
            </template>
            <template v-slot:[`item.estado`]="{ item }">
                {{ estados.find((e) => e.value == item.estado).text }}
            </template>
            <template v-slot:[`item.monto`]="{ item }">
                <v-text-field
                    v-model="item.monto"
                    maxlength="11"
                    prefix="$"
                    :rules="[
                        rules.positiveNumber,
                        rules.menorAPorAbonar(item.monto, getPorAbonar(item)),
                    ]"
                ></v-text-field>
            </template>
            <template slot="no-data">
                <div>Sin prestaciones a pagar</div>
            </template>
            <template slot="body.append">
                <tr>
                    <td></td>
                    <td></td>
                    <td class="font-weight-medium">Total</td>
                    <td class="font-weight-medium">
                        {{
                            "$" +
                            prestacionesPlan
                                .reduce((total, pp) => total + pp.total, 0)
                                .toFixed(2)
                        }}
                    </td>
                    <td class="font-weight-medium">
                        {{
                            "$" +
                            prestacionesPlan
                                .reduce(
                                    (total, pp) => total + getAbonado(pp),
                                    0
                                )
                                .toFixed(2)
                        }}
                    </td>
                    <td class="font-weight-medium">
                        {{
                            "$" +
                            prestacionesPlan
                                .reduce(
                                    (total, pp) => total + getPorAbonar(pp),
                                    0
                                )
                                .toFixed(2)
                        }}
                    </td>
                    <td class="font-weight-black">${{ totalFormateado }}</td>
                </tr>
            </template>
        </v-data-table>

        <v-row class="mt-10">
            <v-col cols="3">
                <v-text-field
                    v-model="totalFormateado"
                    readonly
                    maxlength="11"
                    prefix="$"
                    label="Total a abonar"
                    :rules="[rules.positiveNumber]"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-select
                    :items="mediosPago"
                    item-value="id"
                    item-text="nombre"
                    v-model="pago.medioPago.id"
                    label="Medio de pago"
                ></v-select>
            </v-col>
            <v-col cols="3">
                <v-textarea
                    rows="3"
                    label="Observaciones"
                    v-model="pago.observaciones"
                >
                </v-textarea>
            </v-col>
            <v-col cols="3">
                <v-btn color="success" @click="guardarPago">Guardar pago</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { Urls } from "@/consts.js";
import { ESTADOS } from "@/consts.js";
import PlanesService from "@/services/planes.service.js";
import { DIENTES } from "@/consts.js";

export default {
    data: () => ({
        prestacionesPlan: [],
        mediosPago: [],
        pago: { medioPago: {} },
        planId: undefined,
        headers: [
            { text: "Prestación", value: "prestacion.nombre" },
            { text: "Dientes", value: "dientes" },
            { text: "Estado", value: "estado" },
            { text: "Total", value: "total" },
            { text: "Abonado", value: "abonado" },
            { text: "Por abonar", value: "porAbonar" },
            {
                text: "Monto a abonar",
                value: "monto",
                sortable: false,
                width: "15%",
            },
        ],
        rules: {
            positiveNumber: function (val) {
                return !val || (!isNaN(val) && val >= 0) || "Formato inválido";
            },
            menorAPorAbonar: function (val, porAbonar) {
                return (
                    !val ||
                    val <= porAbonar ||
                    "El monto debe ser menor o igual al monto por abonar"
                );
            },
        },
    }),
    props: {
        pagoPlan: Object,
    },
    computed: {
        estados() {
            return ESTADOS;
        },
        total() {
            let total = this.prestacionesPlan.reduce(
                (total, pp) => total + parseFloat(pp.monto || 0),
                0
            );
            return total;
        },
        totalFormateado() {
            return this.total.toFixed(2);
        },
    },
    methods: {
        getAbonado(pp) {
            let abonado = pp.pagos.reduce((a, p) => a + p.monto, 0);
            if (this.pagoPlan) {
                return (
                    abonado -
                    this.pagoPlan.pagoPrestaciones.find(
                        (ppp) => ppp.prestacionPlanId == pp.id
                    ).monto
                );
            } else {
                return abonado;
            }
        },
        getPorAbonar(pp) {
            return pp.total - this.getAbonado(pp);
        },
        getPrestacionesPlan() {
            PlanesService.getPrestacionesPlan(this.planId).then((data) => {
                if (!data) return;
                if (this.pagoPlan) {
                    this.prestacionesPlan = data.filter((pp) =>
                        this.pagoPlan.pagoPrestaciones.find((ppp) => {
                            if (ppp.prestacionPlanId == pp.id && ppp.status) {
                                pp.monto = ppp.monto;
                                return true;
                            }
                        })
                    );
                } else {
                    this.prestacionesPlan = data;
                }
            });
        },
        getMediosPago() {
            this.$http.get(Urls.PAGOS_URL + "/mediosPago").then((data) => {
                if (!data) return;
                this.mediosPago = data;
                this.pago.medioPago.id = data[0].id;
            });
        },
        guardarPago() {
            if (!this.$refs.form.validate()) return;

            if (!this.total || this.total <= 0) {
                this.$root.showMessage("El total a abonar debe ser mayor a 0");
                return;
            }

            let filtered = this.prestacionesPlan.filter((pp) => {
                let monto = parseFloat(pp.monto);
                return monto && !isNaN(monto);
            });

            this.pago.pagoPrestaciones = filtered.map((pp) => {
                let monto = parseFloat(pp.monto);
                return { prestacionPlanId: pp.id, monto: monto };
            });

            if (this.pagoPlan) {
                this.$http.put(Urls.PAGOS_URL, this.pago).then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Pago registrado con éxito", "success");
                    this.$emit("success");
                });
            } else {
                this.$http.post(Urls.PAGOS_URL, this.pago).then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Pago registrado con éxito", "success");
                    this.$router.push({
                        name: "pagosPlan",
                        params: { planId: this.planId },
                    });
                });
            }
        },
        formatDiente(diente) {
            if (!diente.caras) return diente.numero;
            let caras = diente.caras
                .split(",")
                .map((c) => DIENTES[diente.numero][c])
                .join(", ");
            return diente.numero + " - " + caras;
        },
    },
    mounted() {
        this.planId = this.$route.params.planId;
        this.pago.planId = this.planId;
        this.pago.id = this.pagoPlan?.id;
        this.getPrestacionesPlan();
        this.getMediosPago();
    },
};
</script>

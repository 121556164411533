import axios from "axios";
import { Urls } from "@/consts.js";

const PacienteService = {
    findPaisesDefecto: function () {
        return axios.get(Urls.PACIENTE_URL + "/paises/");
    },
    guardar:function(params){
        return axios.post(Urls.PACIENTE_URL+"/guardar/", params);
    },
    notasPersonales:function(pacienteId){
        return axios.get(Urls.PACIENTE_URL+"/notasPersonales/"+ pacienteId);
    },
    guardarNotasPersonales:function(pacienteId, notas){
        return axios.put(Urls.PACIENTE_URL+"/notasPersonales/notas", {p:pacienteId, n: notas});
    },
    eliminar:function(pacienteId){
        return axios.delete(Urls.PACIENTE_URL+"/borrar/"+pacienteId);
    },
    obtGenerales:function(pacienteId){
        return axios.get(Urls.PACIENTE_URL+"/generales/"+pacienteId);
    },
    getAntecedentes:function(pacienteId){
            return axios.get(Urls.PACIENTE_URL+"/antecedentes/"+pacienteId);
    },
    guardarAntecedentes:function(params){
        return axios.post(Urls.PACIENTE_URL+"/antecedentes/guardar/", params);
    },
    getAlergias:function(pacienteId){
        return axios.get(Urls.PACIENTE_URL+"/alergias/"+pacienteId);
    },
    guardarAlergia:function(pacienteId, params){
        return axios.put(Urls.PACIENTE_URL+"/alergias/"+pacienteId, params);
    },
    guardarOtrasAlergias:function(pacienteId, otrasAlergias){
        return axios.put(Urls.PACIENTE_URL+"/alergias/o", {p:pacienteId, oa:otrasAlergias})
    },
    eliminarAlergia:function(pacienteId, params){
        return axios.put(Urls.PACIENTE_URL+"/alergias/del/"+pacienteId, params);
    }



};

export default PacienteService;
<template>
    <v-autocomplete
        ref="ac"
        v-model="item"
        :autofocus="autofocus"
        :cache-items="false"
        :loading="loading"
        :search-input.sync="search"
        :items="items"
        :label="label"
        :placeholder="placeholder"
        :disabled="disabled"
        :item-text="text"
        :item-value="value"
        @change="change($event)"
        class="mt-3 mb-2"
        hide-no-data
        hide-details
        no-filter
        return-object
        dense
        solo
        :clearable="optClear"
        @blur="reset"
        click:clear="clear"
    >
        <!--template slot="selection" slot-scope="{ item }">
            {{ tipo!=1? item.name:item.cp }}
        </template-->
        <template v-slot:item="data">
            <v-list-item-icon >
                <v-icon v-if="icon && icon.key && data.item[icon.key]" v-text="icon.name" :color="icon.color" small :title="icon.title?icon.title:''"></v-icon>
            </v-list-item-icon>
            <v-list-item-content style="align-items:center">
                {{data.item[text]}}
            </v-list-item-content>

        </template>
    </v-autocomplete>
</template>
<script>
export default {
    name: "RemoteSearch",
    props: {
        url: {},
        text: { default: "name", type: String },
        value: { default: "id", type: String },
        label: { default: "Búsqueda" },
        placeholder: { default: "Teclee aquí" },
        min: { default: 4 },
        disabled: {},
        tipo: { default: 0, type: Number },
        busqueda: { default: "" },
        coloniaId: { default: 0 },
        optClear:{default:true},
        icon: {
                type:Object,
                required:false
              }
    },
    data: () => ({
        loading: false,
        item: {},
        items: [],
        search: "",
        timerId: -1,
        autofocus: true,
    }),
    watch: {
        busqueda: function (val) {
            this.search = val;
        },
        search(value) {
            if (value && !this.items.find((item) => item[this.text] == value)) {
                this.debounce(value);
            }
        },
        item: function (value) {
            this.$nextTick(() => {
                this.$emit("input", value ? value.cp : null);
            });
        },
    },
    methods: {
        // Simple forma de resolverlo
        // https://stackoverflow.com/a/56822735
        debounce: function (value) {
            if (this.timerId > -1) {
                clearTimeout(this.timerId);
            }
            this.timerId = setTimeout(() => {
                this.fnSearch(value);
            }, 500);
        },
        fnSearch(value) {
            if (value == null || value.trim().length < this.min) {
                return;
            }
            if (this.loading) {
                this.debounce(this.search); // Esperar otros milisegundos
                return;
            }
            this.loading = true;

            this.$http.get(this.url + this.search.trim()).then((data) => {
                if (data) {
                    this.items = data;
                    if (this.tipo == 1 && this.coloniaId != 0) {
                        //Búsqueda por código postal
                        data.forEach((element) => {
                            if (element.id == this.coloniaId) {
                                this.$nextTick(() => {
                                    this.item = element;
                                });
                            }
                        });
                    }
                } else {
                    this.items = [];
                }
                this.loading = false;
            });
        },
        focus: function () {
            this.$refs.ac.focus();
        },
        reset: function () {
            if(this.tipo == 2){ //Alergia
                this.clear();
            }
        },
        change:function(evt){
            this.$emit('change', evt);
            this.reset();

        },
        clear:function(){
            this.items = [];
            this.$refs.ac.reset();

        },
        setItem:function(obj) {
            if (obj.id && obj.name)
                this.$nextTick(function(){
                    this.items=[obj];
                    this.item=obj
                    this.$emit('change', obj);
                });
        },
    },
    beforeMount() {
        this.autofocus = this.tipo != 1;
    },
    mounted() {},
};
</script>
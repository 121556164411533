import axios from "axios";
import { Urls } from "@/consts.js";

const PlanesService = {
    getPlan: function (planId) {
        return axios.get(Urls.PLANES_URL + "/plan/" + planId);
    },
    getPlanes: function (pacId) {
        return axios.get(Urls.PLANES_URL + "/" + pacId);
    },
    getPrestacionesPlan: function (planId) {
        return axios.get(Urls.PLANES_URL + "/prestaciones/" + planId);
    },
};

export default PlanesService;

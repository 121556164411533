var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"800px"},on:{"input":function($event){return _vm.$emit('close')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Nuevo asistente ")])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.validform),callback:function ($$v) {_vm.validform=$$v},expression:"validform"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{ref:"nombre",attrs:{"label":"* Nombres","maxlength":"32","autofocus":"","rules":[_vm.rules.required, _vm.rules.formatoNombreInvalido],"required":""},model:{value:(_vm.asistente.nombres),callback:function ($$v) {_vm.$set(_vm.asistente, "nombres", $$v)},expression:"asistente.nombres"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Apellidos","maxlength":"64","rules":[_vm.rules.required, _vm.rules.formatoNombreInvalido],"required":""},model:{value:(_vm.asistente.apellidos),callback:function ($$v) {_vm.$set(_vm.asistente, "apellidos", $$v)},expression:"asistente.apellidos"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-select',{attrs:{"items":[
                                    { text: 'Masculino', value: 'M' },
                                    { text: 'Femenino', value: 'F' },
                                ],"label":"* Género","required":"","rules":[_vm.rules.generoRequired]},model:{value:(_vm.asistente.genero),callback:function ($$v) {_vm.$set(_vm.asistente, "genero", $$v)},expression:"asistente.genero"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('date-picker',{attrs:{"label":"* Fecha de nacimiento","fechaString":_vm.asistente.fechaNacimiento,"rules":[_vm.rules.required],"maxDate":_vm.maxDate,"minDate":_vm.minDate},model:{value:(_vm.asistente.fechaNacimiento),callback:function ($$v) {_vm.$set(_vm.asistente, "fechaNacimiento", $$v)},expression:"asistente.fechaNacimiento"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Teléfono","maxlength":_vm.maxTel,"rules":_vm.ruleTelefono},model:{value:(_vm.asistente.telefono),callback:function ($$v) {_vm.$set(_vm.asistente, "telefono", $$v)},expression:"asistente.telefono"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Dirección","maxlength":"256","rules":[
                                                    (v) => !!v || 'Este campo es obligatorio',
                                                    (v) =>
                                                      (v && v.trim().length >= 2) || 'Al menos 2 caracteres',
                                                  ]},model:{value:(_vm.asistente.direccion),callback:function ($$v) {_vm.$set(_vm.asistente, "direccion", $$v)},expression:"asistente.direccion"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":"* Correo","maxlength":"64","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.asistente.correo),callback:function ($$v) {_vm.$set(_vm.asistente, "correo", $$v)},expression:"asistente.correo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"d-flex"},[_c('v-switch',{attrs:{"disabled":!_vm.esEdicion},model:{value:(_vm.editPwd),callback:function ($$v) {_vm.editPwd=$$v},expression:"editPwd"}}),_c('v-text-field',{attrs:{"disabled":_vm.esEdicion && !_vm.editPwd,"label":(!_vm.esEdicion || _vm.editPwd ? '*' : '') + 'Contraseña',"placeholder":"Contraseña","append-icon":_vm.showPwdText ? 'mdi-eye' : 'mdi-eye-off',"type":"password","rules":!_vm.esEdicion || _vm.editPwd ? [_vm.rules.required, _vm.rules.min6]:[],"type":_vm.showPwdText ? 'text' : 'password',"counter":""},on:{"click:append":function($event){_vm.showPwdText = !_vm.showPwdText}},model:{value:(_vm.asistente.contrasena),callback:function ($$v) {_vm.$set(_vm.asistente, "contrasena", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"asistente.contrasena"}})],1)])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":!_vm.validform},on:{"click":_vm.guardar}},[_vm._v(" Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
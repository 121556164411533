<template>
    <v-card class="mx-16 mt-6">
        <v-card-title> Prestaciones - {{ categoria.nombre }} </v-card-title>
        <v-data-table
            :headers="headers"
            :items="prestaciones"
            :items-per-page="100"
            :search="busqueda"
            style="width: 100%"
            :footer-props="{
                itemsPerPageText: 'Prestaciones por página',
                itemsPerPageOptions: [10, 50, 100],
            }"
        >
            <template v-slot:[`footer.page-text`]="item">
                {{ item.pageStart }} - {{ item.pageStop }} de
                {{ item.itemsLength }}
            </template>
            <template v-slot:top>
                <v-toolbar flat dense>
                    <v-text-field
                        v-model="busqueda"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        style="max-width: 40%"
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        class="mb-2"
                        @click="
                            prestacion = { categoriaId: categoria.id };
                            dialogPrestacion = true;
                        "
                    >
                        Nueva prestación
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.precio`]="{ item }">
                {{ "$" + item.precio.toFixed(2) }}
            </template>
            <template v-slot:[`item.editar`]="{ item }">
                <v-icon color="primary" @click="editar(item)">
                    mdi-pencil
                </v-icon>
            </template>
            <template v-slot:[`item.eliminar`]="{ item }">
                <v-icon
                    color="red"
                    @click="
                        prestacion = item;
                        confirmDelete = true;
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
            <template slot="no-data">
                <div>Sin prestaciones</div>
            </template>
        </v-data-table>

        <dialog-prestacion
            v-if="dialogPrestacion"
            :prestacion="prestacion"
            @close="dialogPrestacion = false"
            @success="
                dialogPrestacion = false;
                loadPrestaciones();
            "
        ></dialog-prestacion>

        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminar();
            "
        ></confirm>
    </v-card>
</template>

<script>
import DialogPrestacion from "../components/DialogPrestacion.vue";
import Confirm from "../components/Confirm.vue";
import PrestacionesService from "@/services/prestaciones.service.js";
import { Urls } from "@/consts.js";

export default {
    data: () => ({
        prestaciones: [],
        prestacion: {},
        categoria: {},
        dialogPrestacion: false,
        confirmDelete: false,
        busqueda: "",
        headers: [
            { text: "Prestación", value: "nombre", width: "60%" },
            { text: "Precio", value: "precio" },
            { text: "Editar prestación", value: "editar", sortable: false },
            { text: "Eliminar prestación", value: "eliminar", sortable: false },
        ],
    }),
    components: {
        DialogPrestacion,
        Confirm,
    },
    methods: {
        loadPrestaciones() {
            PrestacionesService.getPrestaciones(
                this.$route.params.categoriaId
            ).then((data) => {
                if (!data) return;
                this.prestaciones = data.prestaciones;
                this.categoria = data.categoria;
            });
        },
        editar(prestacion) {
            this.prestacion = JSON.parse(JSON.stringify(prestacion));
            this.dialogPrestacion = true;
        },
        eliminar() {
            this.$http
                .delete(Urls.PRESTACIONES_URL + "/delete/" + this.prestacion.id)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Prestación eliminada exitosamente");
                    this.loadPrestaciones();
                });
        },
    },
    mounted() {
        this.loadPrestaciones();
    },
};
</script>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: null,
    paciente: null,
    simbologia: null,
    group: false,
    paises: [],
    especialidades: [],
  },
  mutations: {
    usuario(state, data) {
      state.usuario = data
    },
    paciente(state, data) {
      state.paciente = data
    },
    simbologia(state, data) {
      state.simbologia = data
    },
    paises(state, data) {
      state.paises = data
    },
    especialidades(state, data) {
      state.especialidades = data
    },
    group(state, data) {
      state.group = data;
    },
    
  },
  actions: {
  },
  modules: {
  }
})
<template>
    <div class="mx-auto mt-2 mycontainer" v-if="ready">
        <div height="54" class="light-blue accent-1 d-flex mx-auto consultorio">
            <v-btn icon class="ma-2" @click="prevP()" v-if="places.length > 1">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <span class="ma-3 mx-auto">{{ place.lugar }}</span>

            <v-btn icon class="ma-2" @click="nextP()" v-if="places.length > 1">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>

            <v-toolbar
                    flat
                    color="light-blue lighten-5 text-center"
                  >

                <div >
                    <v-btn small icon @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-menu
                        v-model="dpmenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn color="secondary" dark v-on="on" small outlined>
                                <v-icon>mdi-calendar</v-icon>
                                {{ date }}
                            </v-btn>
                        </template>
                        <v-date-picker
                            dense
                            v-model="date"
                            @input="dpmenu = false"
                            locale="es"
                        ></v-date-picker>
                    </v-menu>
                    <v-btn small icon @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
                <div class="flex-grow-1"></div>
                <v-btn
                      outlined

                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                      style="float:right"

                >
                      Hoy
                </v-btn>
            </v-toolbar>
        <v-sheet :height="sheetHeight" class="ma-0 pa-0">
            <v-calendar
                type="4day"
                locale="es"
                v-model="date"
                ref="calendar"
                :interval-minutes="mins"
                :interval-count="intervalCount"
                :first-interval="firstInterval"
                :interval-height="60"
                :events="events"
                event-text-color="darkgray"
                :event-color="getEventColor"
                @click:time="slotClicked"
                @click:event="eventClicked"
                @change="loadAgenda"
                class="mycalendar"
                light
                @contextmenu:event="eventCMenu"
                @contextmenu:time="timeCMenu"
            >
                <template v-slot:interval="date">
                    <span v-html="intervalColor(date)"></span>
                </template>
                <template v-slot:event="{event}">
                    <b>{{event.name}}</b>
                    <div class="pa-1">
                        <div class="text-left">
                            {{event.start.split(' ')[1]}} - {{event.end.split(' ')[1]}}
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-if="event.terminada===true" v-bind="attrs" v-on="on">
                                        <v-icon color="success" small>mdi-check-circle-outline</v-icon>
                                    </span>
                                </template>
                                <span>Consulta terminada</span>
                            </v-tooltip>

                        </div>
                    </div>
                </template>
                <template v-slot:day-body="{ date, week}">
                    <div v-if="date === week[0].date && nowY" :style="{ top: nowY }" class="v-current-time-dot" :data-before='(Math.abs(new Date().getHours()) < 10 ? "0": "")+new Date().getHours()+":"+(Math.abs(new Date().getMinutes()) < 10 ? "0": "")+new Date().getMinutes()'></div>
                    <div v-if="nowY" :style="{ top: nowY }" class="v-current-time"></div>
                </template>
            </v-calendar>
        </v-sheet>
        <v-progress-linear
            indeterminate
            color="cyan"
            :active="working"
        ></v-progress-linear>
        <v-dialog
            v-model="editAppointment"
            max-width="550"
            v-if="editAppointment"
        >
            <cita
                :data="data"
                @appointment-added="onAppointmentAdded"
                @appointment-deleted="onAppointmentDeleted"
                @message="message"
                @cancel="editAppointment = false"
                @add-patient="add=true"
                ref="cita"
            ></cita>
        </v-dialog>
        <v-dialog v-model="showEvent" class="mx-auto" max-width="344">
            <v-card class="mx-auto" max-width="344" shaped>
                <v-card-text>
                    <v-subheader>Evento</v-subheader>
                    <v-divider></v-divider>

                    <p class="headline text--primary">
                        {{ selectedEvent.name }}
                    </p>
                    <p>{{ selectedEvent.start }} - {{ selectedEvent.end }}</p>
                    <div class="text--primary" v-if="selectedEvent.ubicacion">
                        {{ selectedEvent.ubicacion }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="blue accent-4" @click="showEvent = false"
                        >Cerrar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="add" v-if="add" max-width="690" @keydown:esc="add=false">
            <v-card>
            <v-card-text>
            <paciente @cancel="add=false"
                @new-patient="setNuevoPaciente"></paciente>

            </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="sb.show" :color="sb.color" :timeout="sb.to">
            {{ sb.msg }}
            <v-btn color="white" text @click="sb.show = false">Enterado</v-btn>
        </v-snackbar>

        <context-menu v-if="rc" :nativeEvent="rc.nativeEvent" :event="rc.event" :action="rc.action" @selected="selectedOption" @destroy="rc=null"></context-menu>
    </div>
</template>
<style>
.mycontainer {
    max-width: 800px;
}
</style>
<script>
function leftPad(n) {
    return Math.abs(n) < 10 ? "0" + n : n;
}
let rowIdx = -1;
let day = -1;
function isAvailable(hr, hrs) {
    if (rowIdx < hrs.length && hr >= hrs[rowIdx][0]) {
        if (hr >= hrs[rowIdx][1]) {
            rowIdx++;
            return isAvailable(hr, hrs);
        }
        return true;
    }
    return false;
}
let localtime = new Date().getTime(); // Esta fecha es incorrecta respecto del localtime
let placeIndex = -1;

import { getTop } from "@/utils.js";
import { getIntervals } from "@/utils.js";
import { getFirstInterval } from "@/utils.js";
export default {
    name: "Agenda",
    components: {
        Cita: () => import("@/components/Cita"),
        Paciente: () => import("@/components/Paciente"),
        ContextMenu: () => import("@/components/ContextMenu"),
    },
    props: {
        places: Array,
        events: Array,
    },
    data: () => ({
        dpmenu: false,
        showEvent: false,
        date: null,
        data: {
            datetime: { date: new Date().toISOString().substr(0, 10) },
            event: null,
            place: null,
            z: null,
        },
        confirmDelete: false,
        place: {},
        working: false,
        sb: { show: false, msg: "", color: "success", to: 3000 },
        selectedEvent: {},
        editAppointment: false,
        sheetHeight: 420,
        ready: false,
        add:false,
        nowY:null,
        rc:null,
        pos:null,
        tipo:0
    }),
    computed: {
        firstInterval: function () {
            return this.place ? getFirstInterval(this.place, this.date) : 0;//return this.place ? this.place.min : 15; //return this.place ? this.place.min : 15;
        },
        intervalCount: function () {
            return this.place ? getIntervals(this.place, this.date, true).items.length : 48;//return this.place ? this.place.max - this.place.min : 30; //return this.place ? this.place.max - this.place.min : 30;
        },
        mins: function() {
            return this.place ? this.place.inter : 30;//return 30;
        },
    },
    watch: {
        places() {
            // Cargar consultorios y sus horarios de servicio
            this.working = true;
            let first;
            let start;
            let end;

            this.places.forEach((place) => {
                let week = {};
                let min = 25; // No hay una hora 25:00
                let max = -1; // No hay un horario -1
                for (let i = 0; i < 7; i++) {
                    // La semana tiene 7 días, esto hará que si empieza en el día 6 (sábado)
                    // continúe en el día 7=0, 8=1, 9=2
                    let dow = String(i);
                    let arr = place[dow];
                    let hrs = [];
                    if (arr) {
                        arr.forEach((e) => {
                            let interval = e.replace(/:/g, ".").split("-"); // Inicio y fin
                            start = parseFloat(interval[0]);
                            end = parseFloat(interval[1]);
                            if (start < min) {
                                // Puede ser el primer intervalo
                                min = start; //Math.ceil(start * 2); // Convierte el horario a un intervalo de inicio
                                // Como los intervalos son cada 30 minutos, entonces habrá 48 intervalos
                                // para todo el día.
                                // Hora  | Intervalo
                                // ------+----------
                                // 00:00 - 0
                                // 00:30 - 1
                                // 01:00 - 2
                                // 01:30 - 3
                                // 02:00 - 4
                                // ...
                                // Así por ejemplo, 1.30 deberá corresponder al intervalo 3,
                                // esto se logra con:
                                // 1.30 * 2 = 2.6 --> ceil(2.6)=3
                            }
                            if (end > max) {
                                max = end; //Math.ceil(end * 2); // Esto es para encontrar el número de intervalos
                                // Aquí es la misma regla que para el anterior. El componente
                                // requiere que se indique el primer intervalo y el número de intervalos
                                // a mostrar, esto se logra con: max-min
                            }
                            hrs.push([start, end]);
                        });
                    }
                    week[dow] = hrs;
                }
                place.hrs = week;
                place.min = Math.ceil(min * 2);
                place.max = Math.ceil(max * 2);
            });

            placeIndex = 0;
            this.setPlace();
            let now = new Date();
            // Aquí es importante "settear" working porque la asignación de "date"
            // Disparará loadAgenda
            this.working = false;
            // Esto disparará getEvents
            this.date =
                now.getFullYear() +
                "-" +
                leftPad(now.getMonth() + 1) +
                "-" +
                leftPad(now.getDate());
            this.ready = true;
            //this.loadAgenda();

            this.onResize;
            this.working = false;
        },
        events() {
            // ## Listo para llenar los espacios
            rowIdx = -1; // Importantes para
            day = -1; // repintar el calendario
            const now = new Date();
            this.$refs.calendar.scrollToTime(
                now.getHours() + ":" + now.getMinutes()
            );
            this.formDisabled = true;
            this.working = false;
        },
    },
    methods: {

        paintCurrentTimeDot(){
            var n = new Date();
            const str = n.getFullYear() + "-" + leftPad(n.getMonth() + 1) + "-" + leftPad(n.getDate())
            this.nowY = null;
            let p = (placeIndex > -1 && this.places[placeIndex] && this.places[placeIndex][n.getDay()] && this.date == str) ? getTop(this.places[placeIndex], this.date) : '-50px';
            this.$nextTick(()=>{
                this.nowY = p;
                if(this.$refs.calendar){
                    n.setMinutes( n.getMinutes() - 15);
                    this.$refs.calendar.scrollToTime(
                        n.getHours() + ":" + n.getMinutes()
                    );
                }
            });
        },
        /*
        getEvents:function(date){
            this.events = [
                {name:'Mujer',start:'2020-05-19 15:18:00',end:'2020-05-19 15:18:30',sexo:0},
                {name:'Hombre',start:'2020-05-20 14:18:00',end:'2020-05-20 15:18:30',sexo:1},
                {name:'Videoconsulta',start:'2020-05-19 13:18:00',end:'2020-05-19 13:18:30',room:"123"},
                {name:'Evento',start:'2020-05-21 13:18:00',end:'2020-05-21 13:18:30',tipo:"1"},
                {name:'Pasado',start:'2020-05-18 01:18:00',end:'2020-05-18 02:18:30'}
            ]
        },*/
        intervalColor: function (date) {
            if (day != date.day) {
                rowIdx = 0; // Importantes para pintar el calendario
                day = date.day;
            }
            let valid =
                date.past && new Date().getHours() !== date.hour
                    ? false
                    : isAvailable(
                          parseFloat(date.time.replace(":", ".")),
                          this.place.hrs[date.weekday]
                      );

            return valid
                ? '<div style="height:100%; cursor: pointer;"><div>'
                : '<div style="background-color:#f7f7f7;height:100%; cursor: not-allowed;"><div>';
        },
        getEventColor: function (event) {
            let et = Date.parse(event.start.substring(0, 10));
            if (et < localtime) {
                 return "grey lighten-4";
            }
            // // tipo=1 (evento), tipo=0 (cita)
            // return event.room
            //     ? "orange lighten-5"
            //     : event.tipo == 1
            //     ? "green lighten-5"
            //     : event.sexo === 1
            //     ? "blue lighten-5"
            //     : "pink lighten-5";
            return event.tipo == 1
                    ? "green lighten-5"
                    :event.sexo === 1
                    ? "blue lighten-5" : "pink lighten-5";
        },
        slotClicked: function (datetime) {
            if (this.rc) { //still show up
                this.rc=null; return;
            }
            rowIdx = 0; // <--- Importante para pintar el calendario
            if (
                isAvailable(
                    parseFloat(datetime.time.replace(":", ".")),
                    this.place.hrs[datetime.weekday]
                )
            ) {
                let now = new Date();
                let offset = now.getTimezoneOffset() / 60;
                let et = Date.parse(
                    datetime.date +
                        "T" +
                        datetime.time +
                        ":00" +
                        (offset < 0 ? "+" : "-") +
                        leftPad(offset) +
                        ":00"
                ); // Fecha y hora completa para compatibilidad con otros navegadores
                if (now.getTime() - 30 * 60 * 1000 <= et) {
                    // 30 minutos
                    this.data = {
                        datetime: datetime,
                        event: null,
                        place: this.place.lugar_id,
                        z: this.place.zona_horaria,
                        tipo: this.tipo
                    };
                    this.editAppointment = true;
                } else {
                    this.message(
                        "Ya no puede agendar en esta fecha y hora",
                        "warning"
                    );
                }
            }
        },
        eventClicked: function ({ nativeEvent, event }) {
            // Tipo: 0 = Cita, 1=Evento
            console.log('eventClicked: '+event.tipo);
            //if (event.tipo === 1) {
            //    this.selectedEvent = event;
            //    this.showEvent = true;
            //} else {
                const isAppointment = event.tipo == 0;
                let et = Date.parse(event.start.substring(0, 10));
                if (et >= localtime) {
                    let dt = event.start.split(" ");
                    let date = dt[0].split("-");
                    let time = dt[1].split(":");
                    let cdt = {};
                    cdt.hour = time[0];
                    cdt.minute = time[1];
                    cdt.year = date[0];
                    cdt.month = date[1];
                    cdt.day = date[2];
                    cdt.date = dt[0];
                    cdt.time = dt[1];
                    this.data = {
                        datetime: cdt,
                        event: event,
                        place: this.place.lugar_id,
                        z: this.place.zona_horaria,
                    };
                    this.editAppointment = true;

                } else {
                    this.message(
                        "La cita ha pasado, ya no se puede acceder a ella",
                        "warning"
                    );
                }
            //}
            nativeEvent.stopPropagation();
        },
        nextP: function () {
            if (!this.working) {
                if (++placeIndex >= this.places.length) {
                    placeIndex = 0;
                }
                this.setPlace();
                this.loadAgenda();
            }
        },
        prevP: function () {
            if (!this.working) {
                if (--placeIndex < 0) {
                    placeIndex = this.places.length - 1;
                }
                this.setPlace();
                this.loadAgenda();
            }
        },
        setPlace: function () {
            this.place = this.places[placeIndex];
            this.$nextTick(()=>
                this.paintCurrentTimeDot());
        },
        onAppointmentAdded: function (data) {
            this.events.push(data);
            let msg = data.tipo == 0? "La cita ha sido programada":"El evento ha sido programado";
            this.message(msg, "success");
            this.editAppointment = false;
        },
        onAppointmentDeleted: function (id) {
            let array = this.events; // Esto para no complicar |
            let filtered = array.filter(
                function (value, index, arr) {
                    return value.id != id;
                }.bind(this)
            );
            this.events = filtered;
            this.message("Evento borrado con éxito", "success");
            this.editAppointment = false;
        },
        message: function (msg, level) {
            this.sb.color = level;
            this.sb.msg = msg;
            this.sb.show = true;
        },
        error: function (err) {
            this.message(err, "error");
        },
        loadAgenda: function (e) {
            this.nowY=null;
            if (!this.working) {
                if (this.places.length > 0) {
                    // ### Esto es para el uso de los colores (la fecha en milesegundos)
                    let now = new Date(); // en "mi" horario (local)
                    localtime = Date.parse(
                        now.getFullYear() +
                            "-" +
                            leftPad(now.getMonth() + 1) +
                            "-" +
                            leftPad(now.getDate())
                    );
                    // ### Se debe actualizar cada que se pinte la lista de eventos

                    this.working = true;

                    this.$emit(
                        "change",
                        this.date,
                        this.place.lugar_id,
                        this.place.zona_horaria
                    );
                } else {
                    this.message(
                        "Primero debe registrar un consultorio",
                        "warning"
                    );
                }
                this.paintCurrentTimeDot();
                this.$nextTick(()=>{
                    setInterval(() => this.paintCurrentTimeDot(), 30*1000);
                });
            }
        },
        setNuevoPaciente(obj){
            this.add= false;
            if (this.$refs.cita)
                this.$refs.cita.setNuevoPaciente(obj);
        },
        eventCMenu:function({nativeEvent, event}){

            console.log('eventCMenu');
            if (this.rc) { //still show up
                this.rc=null;
                this.$nextTick(() => {
                    this.rc={nativeEvent: nativeEvent, event: event, action: "event-options"};
                });
            } else {
                this.rc={nativeEvent: nativeEvent, event: event, action: "event-options"};
            }
            nativeEvent.stopPropagation();
        },
        timeCMenu(event) {

            let nativeEvent = this.pos;
            rowIdx = 0; // <--- Importante para pintar el calendario
            if (
                isAvailable(
                parseFloat(event.time.replace(":", ".")),
                this.place.hrs[event.weekday]
                )
            ) {
                let now = new Date();
                let offset = now.getTimezoneOffset() / 60;
                let et = Date.parse(
                    event.date +
                        "T" +
                        event.time +
                        ":00" +
                        (offset < 0 ? "+" : "-") +
                        leftPad(offset) +
                        ":00"
                ); // Fecha y hora completa para compatibilidad con otros navegadores

                let inter = this.place.inter;//30 * 60 * 1000 <= et
                if (now.getTime() - inter * 60 * 1000 <= et) {
                    if(this.rc) this.rc=undefined;
                    this.$nextTick(() => {
                        this.rc = {
                        nativeEvent: nativeEvent,
                        event: event,
                        action: "create-event",
                        };
                    });
                } else {
                    this.message(
                        "Ya no puede agendar en esta fecha y hora",
                        "error"
                    );
                }
            }
        },
        selectedOption:function(key) {
            console.log('selectedOption: '+key);

            let event = this.rc.event;
            this.rc=null;
            switch (key) {
                case 'agendar-0': //cita
                case 'agendar-1': //evento
                case 'agendar-2': //evento todo el día.
                    //console.log(key);
                    this.tipo=key.split('-')[1];
                    this.slotClicked(event);
                    break;
                default:
                    console.log("opción no reconocida {"+key+"}");
                    break;
            }
        },
        addListnrCalendarBodyPosition() {
            if (!this.pos) {
                this.$nextTick(() => {
                    this.pos= {};
                    let element = document.getElementsByClassName("v-calendar-daily__body")[0];
                    element.addEventListener('mousemove', e => {
                        this.pos=e; //console.log(e);
                    });
                });
            }
        },
        setToday:function(){

            const now = new Date();
            this.date = now.getFullYear() +
                            "-" +
                            leftPad(now.getMonth() + 1) +
                            "-" +
                            leftPad(now.getDate());

        }
    },

    mounted() {
        this.sheetHeight = window.innerHeight - 32 - 56 - 56 - 30;
        localtime = new Date().getTime();
    },
};
</script>

<style>
.consultorio {
    border: 1px solid #82b1ff;
    border-radius: 8px 8px 0px 0px;
}
.mycalendar {
    border: 1px solid #82b1ff;
    border-radius: 0px 0px 8px 8px;
}
.v-calendar-daily__scroll-area {
    margin-bottom: 12px;
    border-top: 1px solid #82b1ff;
    border-bottom: 1px solid lightgray;
}
</style>

<style lang="scss">
.v-current-time {
    height: 2px;
    background-color: #ea44359e;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;
}

.v-current-time-dot::before{
    content: attr(data-before);
    position: absolute;
    left: -33px;
    top: -7px;
    font-weight: 450;
    font-size: 10.5px;
    color: #ea4335;
    background-image:  linear-gradient(to top, rgba(255,255,255,0.75), rgba(255, 255, 255, 0.85));
    padding-block: 5.5px;
    padding-inline: 2px;
}

.v-current-time-dot {
    background-color: #ea4335;
    left: -1px;
    right: 0;
    pointer-events: none;
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
}
</style>
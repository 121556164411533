<template>
    <v-card style="width: 100%">
        <v-card-title>Prestaciones a pagar - {{ plan.nombre }}</v-card-title>

        <v-divider class="mb-6"></v-divider>

        <v-card-text>
            <pagar-component></pagar-component>
        </v-card-text>
    </v-card>
</template>

<script>
import PlanesService from "@/services/planes.service.js";
import PagarComponent from "@/components/PagarComponent.vue";
export default {
    data: () => ({
        planId: undefined,
        plan: {},
    }),
    components: { PagarComponent },
    methods: {
        getPlan() {
            PlanesService.getPlan(this.planId).then((data) => {
                if (!data) return;
                this.plan = data;
            });
        },
    },
    mounted() {
        this.planId = this.$route.params.planId;
        this.getPlan();
    },
};
</script>

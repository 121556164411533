import Vue from 'vue'
import VueRouter from 'vue-router'
import Agenda from '../views/Agenda.vue'
import PlanesTratamiento from '../views/PlanesTratamiento.vue'
import PlanTratamiento from '../views/PlanTratamiento.vue'
import PacienteLayout from '../components/PacienteLayout.vue'
import CategoriasPrestaciones from '../views/CategoriasPrestaciones.vue'
import Prestaciones from '../views/Prestaciones.vue'
import Diagnosticos from '../views/Diagnosticos.vue'
import Evoluciones from '../views/Evoluciones.vue'
import Inicio from '@/views/Inicio.vue'
import Paquetes from '@/views/Paquetes.vue'
import ActivarCuenta from '../views/ActivarCuenta.vue'
import Pagos from '@/views/Pagos.vue'
import PagosPlan from '@/views/PagosPlan.vue'
import Pagar from '@/views/Pagar.vue'
import Logotipo from "@/views/Logotipo";
import Pacientes from '@/views/Pacientes.vue'
import Asistentes from '@/views/Asistentes.vue'
import Paciente from '@/components/Paciente.vue'
import Antecedentes from "@/components/Antecedentes";
import Alergias from "@/components/Alergias";
import Galeria from "@/components/Galeria";

Vue.use(VueRouter)

const routes = [
  {
    path: '/public/paquetes', name: 'paquetes', component: () => import(/* webpackChunkName: "Paquetes" */ '@/views/Paquetes.vue'), //Para el público
  },  
  {
    path: '/inicio', redirect: '/'
  },  
  {
    path: '/', name: 'inicio', component: Inicio,
  },
  {
    path: '/activarCuenta', name: 'activarCuenta', component: ActivarCuenta,
  },
  {
    path: '/agenda', name: 'agenda', component: Agenda
  },
  {
    path: '/pacientes', name: 'pacientes', component: Pacientes
  },
  {
    path: '/prestaciones', name: 'categorias', component: CategoriasPrestaciones,
  },
  {
    path: '/asistentes', name: 'asistentes', component: Asistentes,
  },
  {
    path: '/comprar', name: 'comprar', component: Paquetes,
  },
  {
    path: '/logotipo', name: 'logotipo', component: Logotipo,
  },
  {
    path: '/prestaciones/:categoriaId',
    name: 'prestaciones',
    component: Prestaciones
  },
  {
    path: '/paciente/:pacienteId/:tareaId?', component: PacienteLayout,
    children: [
      {
        path: '/',
        name: 'paciente',
        component: Paciente
      },
      {
        path: 'antecedentes',
        name: 'antecedentes',
        component: Antecedentes
      },
      {
        path: 'alergias',
        name: 'alergias',
        component: Alergias
      },
      {
        path: 'galeria',
        name: 'galeria',
        component: Galeria
      },
      {
        path: 'planes',
        name: 'planes',
        component: PlanesTratamiento
      },
      {
        path: 'plan/:planId',
        name: 'plan',
        component: PlanTratamiento
      },
      {
        path: 'diagnosticos',
        name: 'diagnosticos',
        component: Diagnosticos
      },
      {
        path: 'evoluciones',
        name: 'evoluciones',
        component: Evoluciones
      },
      {
        path: 'pagos',
        name: 'pagos',
        component: Pagos
      },
      {
        path: 'pagos/:planId',
        name: 'pagosPlan',
        component: PagosPlan
      },
      {
        path: 'pagar/:planId',
        name: 'pagar',
        component: Pagar
      },
      {
        path: 'vitalsigns',
        name: 'vitalsigns',
        component: () => import('@/views/VitalSignsGlucoseView.vue')
      },
      {
        path: 'preescription',
        name: 'preescription',
        component: () => import('@/views/RecetaView.vue')
      }
    ]
  },
  {
    path: '/consultorios',
    name: 'Consultorios',
    component: () => import(/* webpackChunkName: "consultorios" */ '@/views/Consultorios.vue')
  },
  {
    path: '/formatoReceta',
    name: 'FormatoReceta',
    component: () => import('@/views/FormatoReceta.vue')
  },
  {
    path:'/permissions',
    name:'permisos',
    component: ()=> import('@/components/Permisos.vue')
  },
  { path: "*", redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

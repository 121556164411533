import axios from "axios";
import { Urls } from "@/consts.js";

const DiagnosticosService = {
    getDiagnosticos: function (pacId) {
        return axios.get(Urls.DIAGNOSTICOS_URL + "/" + pacId)
    },
};

export default DiagnosticosService;

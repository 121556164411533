var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-16 mt-6"},[_c('v-card-title',[_vm._v(" Prestaciones - "+_vm._s(_vm.categoria.nombre)+" ")]),_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.prestaciones,"items-per-page":100,"search":_vm.busqueda,"footer-props":{
            itemsPerPageText: 'Prestaciones por página',
            itemsPerPageOptions: [10, 50, 100],
        }},scopedSlots:_vm._u([{key:`footer.page-text`,fn:function(item){return [_vm._v(" "+_vm._s(item.pageStart)+" - "+_vm._s(item.pageStop)+" de "+_vm._s(item.itemsLength)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-text-field',{staticStyle:{"max-width":"40%"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","hide-details":""},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success"},on:{"click":function($event){_vm.prestacion = { categoriaId: _vm.categoria.id };
                        _vm.dialogPrestacion = true;}}},[_vm._v(" Nueva prestación ")])],1)]},proxy:true},{key:`item.precio`,fn:function({ item }){return [_vm._v(" "+_vm._s("$" + item.precio.toFixed(2))+" ")]}},{key:`item.editar`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:`item.eliminar`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){_vm.prestacion = item;
                    _vm.confirmDelete = true;}}},[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Sin prestaciones")])])],2),(_vm.dialogPrestacion)?_c('dialog-prestacion',{attrs:{"prestacion":_vm.prestacion},on:{"close":function($event){_vm.dialogPrestacion = false},"success":function($event){_vm.dialogPrestacion = false;
            _vm.loadPrestaciones();}}}):_vm._e(),(_vm.confirmDelete)?_c('confirm',{on:{"cancel":function($event){_vm.confirmDelete = false},"confirm":function($event){_vm.confirmDelete = false;
            _vm.eliminar();}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container>

    <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
    </v-overlay>

    </v-container>

</template>

<script>
import { Urls } from "@/consts.js";

export default {
    data: () => ({
        overlay:true,
        error:false,
        mensaje:null
    }),
    methods: {

        activarCuenta() {

            let rdm = this.$route.query.rdm;

            this.$http
                .post("session/sign-up-activation", rdm, {
                    headers: { "Content-Type": "application/text" },
                })
                .then(function({data}){
                    if (!data) return;
                    let code = data.code;

                    this.showMessage(code);

                    if(code && code > 0 && data.wat){
                        let r = this.$router.resolve({name: "inicio",
                                                      query: {at:data.wat}
                                      });
                        window.location.assign(r.href)
                    }
                    else{
                        this.$router.push({ name: "inicio"});
                    }
                }.bind(this)).catch(function (error) {
                    var mensaje = "Ocurrió un error al activar la cuenta, favor de contactar al proveedor";
                    if (error.response && error.response.headers && error.response.headers["err-msg"]) {
                          mensaje = error.response.headers["err-msg"];

                    }
                    this.$root.showMessage(mensaje, 'error');
                    this.$router.push({ name: "inicio"});
                }.bind(this));
        },
        showMessage:function(code){
            var msg ="Ocurrió un error en la activación";
            var tipo = "error";
            switch(code){
                case 1:
                    msg="La cuenta ha sido activada. !Gracias por confiar en nosotros!";
                    tipo="success";
                break;
                case 0:
                    msg="Ocurrió un error en la activación, favor de contactarnos";
                break;
                case -1:
                    msg ="Link de activación inválido";
                break;
                case -2:
                    msg ="Este link ya fue utilizado anteriormente";
                    tipo ="warning";

                break;
                case -3:
                    msg ="El usuario asociado a este link de activación, no fue encontrado";
                break;
            }

            this.$root.showMessage(msg, tipo);

        }
    },
    mounted() {
        this.activarCuenta();
    },
};
</script>

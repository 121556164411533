<template>
    <v-dialog v-model="dialog" max-width="500px" @input="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="headline">{{
                    categoria.id ? "Editar categoría" : "Nueva categoría"
                }}</span>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-container>
                    <v-form ref="formCategoria" @submit="guardar">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="categoria.nombre"
                                    label="Nombre de la categoría"
                                    maxlength="128"
                                    autofocus
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('close')">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="guardar">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { Urls } from "@/consts.js";
export default {
    data() {
        return {
            dialog: true,
            rules: {
                required: function (val) {
                    return !!val || "Campo requerido";
                },
            },
        };
    },
    props: {
        categoria: Object,
    },
    methods: {
        guardar(event) {
            event.preventDefault(); //Previene que se refresque la página al presionar enter
            if (!this.$refs.formCategoria.validate()) return;

            let endpoint = this.categoria.id
                ? "/updateCategoria"
                : "/insertCategoria";

            this.$http({
                method: this.categoria.id ? "put" : "post",
                url: Urls.PRESTACIONES_URL + endpoint,
                data: {
                    id: this.categoria.id,
                    nombre: this.categoria.nombre,
                },
            }).then((data) => {
                if (data) {
                    this.$root.showMessage("Categoría guardada exitosamente");
                    this.$emit("success");
                } else {
                    this.$emit("close");
                }
            });
        },
    },
};
</script>
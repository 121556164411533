<template>
    <div class="root">
        <div class="grupos-1-2 d-flex justify-center">
            <div
                class="grupo1 pr-2 pb-2"
                style="border-right: 1px solid; border-bottom: 1px solid"
            >
                <div class="adulto d-flex">
                    <div v-for="n in grupo1A" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
                <div class="infantil d-flex justify-end">
                    <div v-for="n in grupo1I" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
            </div>

            <div class="grupo2 pl-2 pb-2" style="border-bottom: 1px solid">
                <div class="adulto d-flex">
                    <div v-for="n in grupo2A" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
                <div class="infantil d-flex">
                    <div v-for="n in grupo2I" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
            </div>
        </div>

        <div class="grupos-3-4 d-flex justify-center">
            <div class="grupo3 pr-2 pb-2 pt-2">
                <div class="infantil d-flex justify-end">
                    <div v-for="n in grupo3I" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
                <div class="adulto d-flex">
                    <div v-for="n in grupo3A" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
            </div>

            <div class="grupo4 pl-2 pb-2 pt-2" style="border-left: 1px solid">
                <div class="infantil d-flex">
                    <div v-for="n in grupo4I" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
                <div class="adulto d-flex">
                    <div v-for="n in grupo4A" :key="n" class="text-center">
                        <diente
                            ref="dientes"
                            class="diente"
                            :n="n"
                            :diagnosticos="diagnosticos"
                            :prestacionesPlan="prestacionesPlan"
                        ></diente>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.diente {
    margin: 2px;
}
</style>

<script>
import Diente from "./Diente.vue";

export default {
    components: { Diente },
    data() {
        return {
            grupo1A: [18, 17, 16, 15, 14, 13, 12, 11],
            grupo1I: [55, 54, 53, 52, 51],
            grupo2A: [21, 22, 23, 24, 25, 26, 27, 28],
            grupo2I: [61, 62, 63, 64, 65],
            grupo3I: [85, 84, 83, 82, 81],
            grupo3A: [48, 47, 46, 45, 44, 43, 42, 41],
            grupo4I: [71, 72, 73, 74, 75],
            grupo4A: [31, 32, 33, 34, 35, 36, 37, 38],
        };
    },
    props: ["diagnosticos", "prestacionesPlan"],
    methods: {
        addDiagnostico() {},
        getDientesSeleccionados() {
            let a = [];
            this.$refs.dientes.forEach((d) => {
                if (d.seleccionado) {
                    let dienteSeleccionado = {};
                    dienteSeleccionado.numero = d.n;
                    if (d.getCarasSeleccionadas().length > 0) {
                        dienteSeleccionado.caras = d.getCarasSeleccionadas();
                    }
                    a.push(dienteSeleccionado);
                }
            });
            return a;
        },
    },
};
</script>
<template>
<v-container class="mx-auto">
     <v-card :loading="loading" shape elevation="0" :disabled="loading">
     <template slot="progress">
             <v-progress-linear
               color="cyan"
               indeterminate
               stripped
             ></v-progress-linear>
           </template>
        <v-card-title class="text-color-title text-xs-subtitle-2 text-lg-h5 text-wrap">Antecedentes</v-card-title>
        <v-spacer></v-spacer>
     <v-card-text>
        <v-row>
            <v-col cols="12">
                <v-textarea v-model="antecedentes.generales"
                    label="Antecedentes generales"
                    :rows="5"
                    outlined
                    :counter="maxCarateresCampoTexto"
                ></v-textarea>
            </v-col>
        </v-row>
     </v-card-text>
     <v-divider class="mx-4"></v-divider>

     <v-card-subtitle>Enfermedades</v-card-subtitle>
     <v-card-text>
        <v-row class="d-flex justify-start mb-6">

                  <v-checkbox
                    v-model="antecedentes.diabetes"
                    label="Diabetes"
                    color="info"
                    hide-details
                  ></v-checkbox>

                  <v-checkbox
                    v-model="antecedentes.obesidad"
                    label="Obesidad"
                    color="info"
                    hide-details
                  ></v-checkbox>


                  <v-checkbox
                    v-model="antecedentes.hipertension"
                    label="Hipertensión arterial"
                    color="info"
                    hide-details

                  ></v-checkbox>

        </v-row>



        <v-row>
            <v-col cols="12">
                <v-textarea v-model="antecedentes.enfermedades"
                    label="Otras enfermedades"
                    :rows="5"
                    outlined
                    :counter="maxCarateresCampoTexto"
                ></v-textarea>
            </v-col>
        </v-row>
     </v-card-text>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="success"
                  class="ma-2"
                  @click="guardar"
                >
                  Guardar
                </v-btn>

    </v-card-actions>
</v-card>
</v-container>

</template>

<script>
import PacienteService from "@/services/paciente.service.js";


  export default {
      name:'Antecedentes',
      components:{

      },
    data: () => ({
        antecedentes:{diabetes:false, hipertension:false, obesidad:false},
        loading:false,
        maxCarateresCampoTexto: 1024
    }),

    computed:{

    },

    watch:{
        "antecedentes.generales":function(){
            if(this.antecedentes.generales && this.antecedentes.generales.length > this.maxCarateresCampoTexto)
            {

                this.$nextTick(() => {
                    this.antecedentes.generales =  this.antecedentes.generales.substring(0, this.maxCarateresCampoTexto);
                });
            }
        },
        "antecedentes.enfermedades":function(){
                    if(this.antecedentes.enfermedades && this.antecedentes.enfermedades.length > this.maxCarateresCampoTexto)
                    {

                        this.$nextTick(() => {
                            this.antecedentes.enfermedades =  this.antecedentes.enfermedades.substring(0, this.maxCarateresCampoTexto);
                        });
                    }
                }

    },
    methods: {
        guardar: function(){
            var params={id:this.antecedentes.id,
                        enfermedades:this.antecedentes.enfermedades,
                        generales:this.antecedentes.generales,
                        diabetes: this.antecedentes.diabetes,
                        obesidad: this.antecedentes.obesidad,
                        hipertension: this.antecedentes.hipertension,
                        pacienteId: this.id
                    };

                    PacienteService.guardarAntecedentes(params).then((data) =>{
                        if(data && data > 0){
                            this.$root.showMessage('Antecedentes guardados');
                            this.$emit('updated-diseases', params.diabetes, params.obesidad, params.hipertension, params.enfermedades);
                        }
                        else{
                            this.$root.showMessage('Ocurrió un error al guardar los antecedentes, es paciente del médico? existe el paciente?', 'error');
                        }
                    });

        }
    },
    beforeMount(){
      this.id = this.$route.params.pacienteId;
      if(this.id){
        this.loading=true;


        PacienteService.getAntecedentes(this.id).then((data) =>{
          this.loading=false;
          if(data){
            this.antecedentes = data;
          }

        });


      }
      else{
        console.log('No hay referencia de paciente, a agenda debo redireccionar?');
      }
    }
  }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.planes,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Pagos")])],1)]},proxy:true},{key:`item.usuario`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.usuario.nombres + " " + item.usuario.apellidos)+" ")]}},{key:`item.presupuesto`,fn:function({ item }){return [_vm._v(" $"+_vm._s(item.prestaciones .reduce((total, pp) => total + pp.total, 0) .toFixed(2))+" ")]}},{key:`item.realizado`,fn:function({ item }){return [_vm._v(" $"+_vm._s(_vm.getRealizado(item).toFixed(2))+" ")]}},{key:`item.abonado`,fn:function({ item }){return [_vm._v(" $"+_vm._s(_vm.getAbonado(item).toFixed(2))+" ")]}},{key:`item.saldo`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold",class:_vm.getSaldo(item) > 0
                        ? 'green--text'
                        : _vm.getSaldo(item) < 0
                        ? 'red--text'
                        : ''},[_vm._v(" $"+_vm._s(_vm.getSaldo(item).toFixed(2))+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticStyle:{"margin-left":"-7px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                                    name: 'pagosPlan',
                                    params: { planId: item.id },
                                })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver pagos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                                    name: 'pagar',
                                    params: { planId: item.id },
                                })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-currency-usd ")])],1)]}}],null,true)},[_c('span',[_vm._v("Pagar")])])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Sin presupuestos")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<v-container class="mx-auto">
     <v-card :loading="loading" shape elevation="0" :disabled="loading">
     <template slot="progress">
             <v-progress-linear
               color="cyan"
               indeterminate
               stripped
             ></v-progress-linear>
           </template>
        <v-card-title class="text-xs-subtitle-2 text-lg-h5 text-wrap">Alergias</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>

        <remote-search :url="url" label="Especifique la sustancia/clasificación de la alergia" min="4" placeholder="Busque y seleccione la sustancia/clasificación de la alergia " @change="onChange" :text="'nombre'" :optClear="false" :tipo="2"></remote-search>
        <v-spacer></v-spacer>
        <v-list two-line style="overflow-y:auto; min-height=48px; max-height:240px;" dense>
          <v-list-item-group v-model="selectedItem" active-class="light-blue lighten-5"  >
            <template v-for="(item, index) in items">
              <v-list-item
                :key="index + '-' + item.id + '-' + item.nombre"
                style="max-height:60px;min-height:60px;"
              >
                <v-list-item-content dense>
                  <v-list-item-title>
                    <v-icon
                      small
                      v-if="
                        item.catalogoSugeridoId &&
                          item.catalogoSugeridoId > 0 &&
                          icono &&
                          icono.name
                      "
                      :color="icono.color"
                    >
                      {{ icono.name }}
                    </v-icon>
                    <span class="caption">{{ item.nombre }}</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                 <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="delitem(item)" v-if="!isDeleting(item)" v-bind="attrs" v-on="on">
                    <v-icon small color="red lighten-1">
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  </template>
                   <span>Eliminar</span>
                  </v-tooltip>
                  <v-progress-circular
                    v-if="isDeleting(item)"
                    indeterminate
                    color="blue lighten-2"
                    title="Eliminando"
                    small
                  ></v-progress-circular>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < items.length"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>

      </v-card-text>
     <v-card-text>
     <v-spacer></v-spacer>
        <v-row>
            <v-col cols="12">
                <v-textarea v-model="otrasAlergias"
                    label="Otras alergias"
                    :rows="5"
                    outlined
                    :counter="maxCarateresCampoTexto"
                ></v-textarea>
            </v-col>
        </v-row>
     </v-card-text>

    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="success"
                  class="ma-2"
                  :disabled="disabled"
                  @click="guardarOtrasAlergias"
                >
                  Guardar
                </v-btn>

    </v-card-actions>
</v-card>
</v-container>

</template>

<script>
import PacienteService from "@/services/paciente.service.js";
import RemoteSearch from '@/components/RemoteSearch'

  export default {
      name:'Alergias',
      components:{
            'remote-search':RemoteSearch
      },
    data: () => ({
        otrasAlergias:"",
        loading:false,
        maxCarateresCampoTexto: 1024,
        items: [],
        initial:"",
        borrando: [],
        selectedItem: undefined,
        icono: {
                key: "catalogoSugeridoId",
                name: "mdi-bookmark-multiple-outline",
                title: "",
                color: "orange darken-2",
              }
    }),

    computed:{
        url:function(){
            return '/api/pacientes/alergias/'+this.id+ '/';
        },
        disabled:function(){
            return this.initial == this.otrasAlergias;
        }
    },

    watch:{
        "otrasAlergias":function(){

            if(this.otrasAlergias && this.otrasAlergias.length > this.maxCarateresCampoTexto)
            {

                this.$nextTick(() => {
                    this.otrasAlergias =  this.otrasAlergias.substring(0, this.maxCarateresCampoTexto);

                });
            }
        }
    },
    methods: {
        guardarOtrasAlergias:function(){
            PacienteService.guardarOtrasAlergias(this.id, this.otrasAlergias).then((data)=>{
                if(data > 0){
                    this.$root.showMessage('Otras alergias han sido guardadas');
                    this.initial = this.otrasAlergias;
                    this.$emit("updated-allergies", "change-others", this.otrasAlergias);
                }
                else{
                    this.$root.showMessage('Otras alergias no pudieron guardarse', 'error');
                }
            });
        },
        isDeleting: function(item) {
              return this.borrando.indexOf(item) > -1;
        },
        onChange:function(item){
            if(item){

            if (this.getindexinitems_byitem(item) > -1) return; //ya existe

            PacienteService.guardarAlergia(this.id, this.buildParams(item)).then((data)=>{
                if (data > 0) {
                          if (this.getindexinitems_byitem(item) == -1) {
                            this.items.push(item);
                            this.selectedItem = this.items.length - 1;
                          }
                    this.$root.showMessage('Alergia guardada');
                    this.$emit("updated-allergies", "add", item);
                }
                else{
                    this.$root.showMessage('La alergia no pudo ser agregada', 'error');
                }
            });
            }


        },
        getindexinitems_byitem: function(item) {
            return this.items.indexOf(item);
        },
        delitem: function(item) {
          if (this.borrando.indexOf(item) == -1) {
            this.borrando.push(item);
          } else {
            return;
          }

            PacienteService.eliminarAlergia(this.id, this.buildParams(item)).then((data)=>{
                if (data && data > 0) {
                    var cp = this.items;
                    cp.splice(cp.indexOf(item), 1);
                    this.$nextTick(() => {
                        this.items = cp;
                        this.$emit("updated-allergies", "delete", item);
                    });
                }
                else{
                    this.$root.showMessage('No fue posible eliminar la alergia', 'error');
                }
            });

        },
        buildParams:function(item){
            var params = { id: item.id, origen: item.origen };
            if (item.catalogoSugeridoId && item.catalogoSugeridoId > 0)
                params.catalogoSugeridoId = item.catalogoSugeridoId;

            return params;
        }


    },
    beforeMount(){
      this.id = this.$route.params.pacienteId;
      if(this.id){
        this.loading=true;
        PacienteService.getAlergias(this.id).then((data) =>{
          this.loading=false;
          if(data){
            this.otrasAlergias = data.otras;
            this.initial = this.otrasAlergias;
            this.items = data.alergias;
          }
        });


      }
      else{
        console.log('No hay referencia de paciente, a agenda debo redireccionar?');
      }
    }
  }
</script>

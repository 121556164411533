<template>
    <v-card width="100%">
        <v-card-title> Evoluciones </v-card-title>
        <v-row class="mx-auto mb-1">
            <v-col cols="4">
                <v-select
                    :items="planes"
                    label="Plan de tratamiento"
                    v-model="evolucion.planId"
                    item-value="id"
                    item-text="nombre"
                    :rules="[rules.required]"
                    class="mb-1"
                ></v-select>
            </v-col>
        </v-row>

        <v-data-table
            :headers="headers"
            :items="evoluciones"
            :items-per-page="10"
            :search="busqueda"
            style="width: 100%"
            hide-default-footer
        >
            <template v-slot:[`item.fecha`]="{ item }">
                <span>{{ formatearFechaIsoAMx(item.fecha) }}</span>
            </template>
            <template v-slot:[`item.usuario`]="{ item }">
                <span>{{
                    item.usuario.nombres + " " + item.usuario.apellidos
                }}</span>
            </template>
            <template v-slot:[`item.eliminar`]="{ item }">
                <v-icon
                    color="red"
                    @click="
                        evolucion = item;
                        confirmDelete = true;
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
            <template slot="no-data">
                <div>Sin evoluciones</div>
            </template>
        </v-data-table>

        <!-- <dialog-prestacion
            v-if="dialogPrestacion"
            :prestacion="prestacion"
            @close="dialogPrestacion = false"
            @success="
                dialogPrestacion = false;
                loadPrestaciones();
            "
        ></dialog-prestacion> -->

        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminar();
            "
        ></confirm>
    </v-card>
</template>

<script>
import Confirm from "../components/Confirm.vue";
import PlanesService from "@/services/planes.service.js";
import { Urls } from "@/consts.js";
import { formatearFechaIsoAMx } from "@/utils.js";

export default {
    data: () => ({
        evoluciones: [],
        evolucion: {},
        planes: [],
        confirmDelete: false,
        busqueda: "",
        rules: {
            required: function (val) {
                return !!val || "Campo requerido";
            },
        },
        headers: [
            { text: "Evolución", value: "evolucion", width: "60%" },
            { text: "Fecha", value: "fecha" },
            { text: "Escrito por", value: "usuario" },
            { text: "Eliminar", value: "eliminar", sortable: false },
        ],
    }),
    components: {
        Confirm,
    },
    methods: {
        formatearFechaIsoAMx(fecha) {
            return formatearFechaIsoAMx(fecha);
        },
        loadEvoluciones() {
            this.$http
                .get(Urls.EVOLUCIONES_URL + "/" + this.$route.params.pacienteId)
                .then((data) => {
                    if (!data) return;
                    this.evoluciones = data;
                });
        },
        loadPlanes() {
            PlanesService.getPlanes(this.$route.params.pacienteId).then(
                (data) => {
                    if (!data) return;
                    this.planes = data;
                }
            );
        },
        insertar() {
            if (!this.$refs.form.validate()) return;
            this.evolucion.pacienteId = this.$route.params.pacienteId;
            this.evolucion.tareaId = this.$route.params.tareaId;
            this.$http
                .post(Urls.EVOLUCIONES_URL, this.evolucion)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Evolución guardada exitosamente");
                    this.loadEvoluciones();
                });
        },
        eliminar() {
            this.$http
                .delete(Urls.EVOLUCIONES_URL + "/" + this.evolucion.id)
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Evolución eliminada exitosamente");
                    this.loadEvoluciones();
                });
        },
    },
    mounted() {
        this.loadEvoluciones();
        this.loadPlanes();
    },
};
</script>

<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="300px"
        @input="$emit('cancel')"
    >
        <v-card>
            <v-card-title> ¿Qué desea realizar? </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-container>
                    <v-row class="text-center">
                        <v-col cols="12" v-if="!tarea.infoCita.pagoEfectuado">
                            <v-btn @click="dialogCosto = true" color="green" outlined>
                                <v-icon>
                                    mdi-cash-check
                                </v-icon>
                                Cobrar
                            </v-btn>
                        </v-col>
                        <v-col cols="12" v-if="usuario.isoPais == 'MX'">
                            <v-btn @click="cobrarYFacturar" color="green" outlined>
                                <v-icon>
                                    mdi-cash-check
                                </v-icon>
                                <v-icon small>
                                    mdi-file-move-outline
                                </v-icon>
                                {{title}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="$emit('cancel')">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="dialogCosto" width="250px">
            <v-form ref="form" @submit="cobrarConsulta">
                <v-card>
                    <v-card-title>Cobrar consulta</v-card-title>
                    <v-card-text>
                        <v-text-field
                            label="Costo de la consulta"
                            v-model="costoConsulta"
                            prefix="$"
                            maxlength="11"
                            :rules="[rules.required, rules.number]"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="cobrarConsulta">
                            Cobrar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-dialog>
</template>

<style scoped>
.cobrar:hover {
    cursor: pointer;
}
.cobrarFacturar:hover {
    cursor: pointer;
}
</style>

<script>
import { Urls } from "@/consts.js";

export default {
    data() {
        return {
            dialog: true,
            dialogCosto: false,
            costoConsulta: 0,

            rules: {
                required: function (val) {
                    return !!val || "Campo requerido";
                },
                number: function (val) {

                    return !isNaN(val) && parseInt(val) >= 0 || "Formato inválido";
                },
            },
            facturar:false,
            title:"Cobrar y facturar"

        };
    },

    props: ["tarea"],
    computed:{
        usuario: function () {
            return this.$store.state.usuario;
        },
    },
    watch:{
        "tarea.infoCita.pagoEfectuado":function(value){
            console.log(value);
            if(this.usuario.isoPais == 'MX'){
                this.title = value?"Facturar":"Cobrar y facturar";
            }
        }
    },
    methods: {
        getCostoConsulta: function () {
            this.$http
                .get(Urls.AGENDA_URL + "/costoConsulta/" + this.tarea.id)
                .then((data) => {
                    if (!data) return;
                    this.costoConsulta = data ?? 0;
                });
        },
        cobrarConsulta: function (event) {
            event.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.$http
                .post(Urls.AGENDA_URL + "/cobrarConsulta", {
                    tareaId: this.tarea.id,
                    costo: this.costoConsulta,
                    facturar: this.facturar?1:0
                })
                .then((data) => {
                    if (!data) return;
                    this.dialogCosto = false;
                    if(data == -1){
                      this.$root.showMessage("La cita que desea cobrar, no fue encontrada", 'warning');

                    }
                    else if(data == -2){
                        this.$root.showMessage("La cita ya fue cobrada anteriormente", 'warning');
                    }
                    else if(data == -3){
                        this.$root.showMessage("La cita ya fue facturada", 'warning');
                    }
                    else{
                        this.$root.showMessage(
                            "Se cobró la consulta correctamente", 'info'
                        );
                        this.$emit("cobro-efectuado");
                    }
                    this.$emit("cancel", data <= 0?false:this.facturar);
                });
        },
        cobrarYFacturar:function(){
            if(!this.tarea.infoCita.pagoEfectuado){
                this.dialogCosto = true;
                this.facturar = true;
            }
            else{
                this.$emit("cancel", true);

            }
        }
    },
    beforeMount() {
        this.getCostoConsulta();
         if(this.usuario.isoPais == 'MX'){
            this. title = this.tarea.infoCita.pagoEfectuado?"Facturar":"Cobrar y facturar";
         }
    },
};
</script>
<template>
    <div
        class="diente"
        @click="seleccionado = !seleccionado"
        :class="seleccionado ? 'seleccionado' : ''"
    >
        <div
            class="numero"
            :class="
                colorNumero
                    ? 'white--text font-weight-medium ' + colorNumero
                    : ''
            "
        >
            {{ n }}
        </div>
        <div class="contenedor">
            <div
                v-if="simboloDiente"
                :style="simboloDiente.style"
                class="simbolo"
            >
                <img
                    v-if="simboloDiente.src"
                    :src="simboloDiente.src"
                    :width="simboloDiente.width"
                />
            </div>
            <div
                v-for="(s, i) in simbolosCaras"
                :key="i"
                :class="'simbolo' + s.cara"
                class="simbolo"
            >
                <v-icon v-if="s.icon" :color="s.color" size="13">
                    {{ s.icon }}
                </v-icon>
            </div>
            <svg
                viewBox="0 0 407 407"
                width="50"
                height="50"
                transform="rotate(45)"
            >
                <svg
                    width="407"
                    height="407"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <cara
                            ref="cara1"
                            :fill="getFill('1')"
                            @seleccionado="seleccionado = true"
                            n="1"
                            d="m203.5,203.5l-200,0a200,200 0 0 1 200,-200l0,200z"
                        />
                        <cara
                            ref="cara2"
                            :fill="getFill('2')"
                            @seleccionado="seleccionado = true"
                            n="2"
                            d="m203.5,203.5l0,-200a200,200 0 0 1 200,200l-200,0z"
                        />
                        <cara
                            ref="cara3"
                            :fill="getFill('3')"
                            @seleccionado="seleccionado = true"
                            n="3"
                            d="m203.5,203.5l200,0a200,200 0 0 1 -200,200l0,-200z"
                        />
                        <cara
                            ref="cara4"
                            :fill="getFill('4')"
                            @seleccionado="seleccionado = true"
                            n="4"
                            d="m203.5,203.5l0,200a200,200 0 0 1 -200,-200l200,0z"
                        />
                        <cara
                            ref="cara5"
                            n="5"
                            :fill="getFill('5')"
                            @seleccionado="seleccionado = true"
                        />
                        <ellipse
                            :stroke="getStroke()"
                            ry="194"
                            rx="194"
                            cy="202.95313"
                            cx="203.33333"
                            style="stroke-width: 20"
                            fill="none"
                        />
                    </g>
                </svg>
            </svg>
        </div>
        <div class="nomenclatura d-flex justify-center align-center">
            <div v-if="simboloDiente && simbolosCarasTexts.length == 0">
                <span
                    v-if="simboloDiente.text"
                    class="font-weight-bold"
                    :style="simboloDiente.style"
                    style="font-size: 1.4em"
                >
                    {{ simboloDiente.text }}
                </span>
                <v-icon
                    v-else-if="simboloDiente.icon"
                    :color="simboloDiente.color"
                    size="20"
                >
                    {{ simboloDiente.icon }}
                </v-icon>
            </div>
            <div v-for="(s, i) in simbolosCarasTexts" :key="i">
                <span
                    v-if="s.text"
                    class="font-weight-bold"
                    :style="s.style"
                    style="font-size: 1.4em"
                >
                    {{ s.text }}
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.diente {
    padding: 2px 3px 7px 3px;
    margin: 2px 0;
}
.diente:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}
.seleccionado {
    background-color: #e6effa !important;
}
.numero {
    color: black;
    width: fit-content;
    padding: 0 5px;
    margin: 0 auto 3px auto;
    border-radius: 20%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.contenedor {
    position: relative;
    margin-bottom: 1px;
}
.nomenclatura {
    background-color: lightgrey;
    height: 26px;
}
.simbolo {
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.simbolo1 {
    top: 11%;
    left: 51%;
}
.simbolo2 {
    top: 44%;
    left: 86%;
}
.simbolo3 {
    top: 75%;
    left: 51%;
}
.simbolo4 {
    top: 44%;
    left: 16%;
}
.simbolo5 {
    top: 44%;
    left: 51%;
}
</style>

<script>
import Cara from "./Cara.vue";
export default {
    data() {
        return {
            caras: [],
            simbolosCarasD: [],
            simboloDienteD: undefined,
            simbolosCarasP: [],
            simboloDienteP: undefined,
            colorNumero: undefined,
            seleccionado: false,
        };
    },
    props: ["n", "diagnosticos", "prestacionesPlan"],
    components: { Cara },
    watch: {
        diagnosticos() {
            this.setSimbolosCarasD();
            this.setSimboloDienteD();
            this.deseleccionar();
        },

        prestacionesPlan() {
            this.setSimbolosCarasP();
            this.setSimboloDienteP();
            this.deseleccionar();
        },
        seleccionado() {
            if (!this.seleccionado) this.deseleccionar();
        },
    },
    computed: {
        simbolosCarasTexts() {
            if (this.simboloDiente?.text) return [];
            return this.uniqBy(this.simbolosCaras, (sc) => sc.text);
        },
        simboloDiente() {
            if (this.simboloDienteP) return this.simboloDienteP;
            if (this.simboloDienteD) return this.simboloDienteD;
            return undefined;
        },
        simbolosCaras() {
            if (this.simbolosCarasP.length) return this.simbolosCarasP;
            if (this.simbolosCarasD.length) return this.simbolosCarasD;
            return [];
        },
        SIMBOLOGIA() {
            return this.$store.state.simbologia;
        },
    },
    methods: {
        uniqBy(a, key) {
            var seen = {};
            return a.filter(function (item) {
                var k = key(item);
                return seen.hasOwnProperty(k) ? false : (seen[k] = true);
            });
        },
        getCarasSeleccionadas() {
            let a = [];
            this.caras.forEach((c) => {
                if (c.seleccionado) a.push(c.n);
            });
            return a.join(",");
        },
        // seleccionarTodos() {
        //     let deseleccionado = this.caras.some((c) => !c.seleccionado);
        //     this.caras.forEach((c) => {
        //         c.seleccionado = deseleccionado;
        //     });
        // },
        deseleccionar() {
            this.seleccionado = false;
            this.caras.forEach((c) => {
                c.seleccionado = false;
            });
        },
        getFill(cara) {
            if (this.hasPrestacionPlanCaras()) {
                let pp = this.getPrestacionPlanCaras(cara);
                if (pp && pp.prestacion.simbologiaId) {
                    return this.SIMBOLOGIA.find(
                        (s) => s.id == pp.prestacion.simbologiaId
                    ).datos.fill;
                }
            } else {
                let diagnostico = this.getDiagnosticoCaras(cara);
                if (diagnostico && diagnostico.simbologia) {
                    return this.SIMBOLOGIA.find(
                        (s) => s.id == diagnostico.simbologia.id
                    ).datos.fill;
                }
            }
            return null;
        },
        getStroke() {
            let stroke = "none";
            let dd = this.getDiagnosticoDiente();
            if (dd && dd.simbologia) {
                stroke = this.SIMBOLOGIA.find((s) => s.id == dd.simbologia.id)
                    .datos.stroke;
                if (!stroke) return "none";
            }
            let ppd = this.getPrestacionPlanDiente();
            if (ppd && ppd.prestacion.simbologiaId) {
                stroke = this.SIMBOLOGIA.find(
                    (s) => s.id == ppd.prestacion.simbologiaId
                ).datos.stroke;
                if (!stroke) return "none";
            }
            return stroke;
        },
        getDiagnosticoCaras(cara) {
            if (this.diagnosticos.length < 1) return null;
            let diagnostico = this.diagnosticos.find((diag) =>
                diag.dientes.find((d) => d.numero == this.n)
            );
            if (
                diagnostico &&
                diagnostico.dientes.find(
                    (d) => d.caras && d.caras.split(",").includes(cara)
                )
            )
                return diagnostico;
            else return null;
        },
        getPrestacionPlanCaras(cara) {
            if (!this.prestacionesPlan || this.prestacionesPlan.length < 1)
                return null;
            let prestacionPlan = this.prestacionesPlan.find((pp) =>
                pp.dientes.find(
                    (d) =>
                        d.numero == this.n &&
                        d.caras &&
                        d.caras.split(",").includes(cara)
                )
            );
            return prestacionPlan;
        },
        hasPrestacionPlanCaras() {
            if (!this.prestacionesPlan || this.prestacionesPlan.length < 1)
                return false;
            let prestacionPlan = this.prestacionesPlan.find((pp) =>
                pp.dientes.find((d) => d.numero == this.n && d.caras)
            );
            return prestacionPlan;
        },
        getDiagnosticoDiente() {
            if (this.diagnosticos.length < 1) return null;
            let diagnosticoDiente = this.diagnosticos.find((diag) =>
                diag.dientes.find((d) => d.numero == this.n)
            );
            return diagnosticoDiente;
        },
        getPrestacionPlanDiente() {
            if (!this.prestacionesPlan || this.prestacionesPlan.length < 1)
                return null;
            let prestacionPlanDiente = this.prestacionesPlan.find((pp) =>
                pp.dientes.find((d) => d.numero == this.n)
            );
            return prestacionPlanDiente;
        },
        setSimbolosCarasD() {
            this.simbolosCarasD = [];
            for (let i = 1; i <= 5; i++) {
                let diagnostico = this.getDiagnosticoCaras(i.toString());
                if (!diagnostico || !diagnostico.simbologia) continue;
                let s = this.SIMBOLOGIA.find(
                    (s) => s.id == diagnostico.simbologia.id
                );
                this.simbolosCarasD.push({
                    text: s.datos.text,
                    color: s.datos.color,
                    icon: s.datos.icon,
                    style: s.datos.style,
                    cara: i,
                });
            }
        },
        setSimbolosCarasP() {
            this.simbolosCarasP = [];
            for (let i = 1; i <= 5; i++) {
                let pp = this.getPrestacionPlanCaras(i.toString());
                if (!pp) continue;
                this.colorNumero = pp.estado ? "success" : "red";
                if (!pp.prestacion.simbologiaId) continue;
                let s = this.SIMBOLOGIA.find(
                    (s) => s.id == pp.prestacion.simbologiaId
                );
                this.simbolosCarasP.push({
                    text: s.datos.text,
                    color: s.datos.color,
                    icon: s.datos.icon,
                    style: s.datos.style,
                    cara: i,
                });
            }
        },
        setSimboloDienteD() {
            this.simboloDienteD = undefined;
            let dd = this.getDiagnosticoDiente();
            if (!dd || !dd.simbologia) return;
            let s = this.SIMBOLOGIA.find((s) => s.id == dd.simbologia.id);
            this.simboloDienteD = {
                src: s.datos.src,
                icon: s.datos.icon,
                text: s.datos.text,
                color: s.datos.color,
                width: s.datos.width,
                style: s.datos.style,
            };
        },
        setSimboloDienteP() {
            this.simboloDienteP = undefined;
            let pp = this.getPrestacionPlanDiente();
            if (!pp) {
                this.colorNumero = undefined;
                return;
            }
            this.colorNumero = pp.estado ? "success" : "red";
            if (!pp.prestacion.simbologiaId) return;
            let s = this.SIMBOLOGIA.find(
                (s) => s.id == pp.prestacion.simbologiaId
            );
            this.simboloDienteP = {
                src: s.datos.src,
                icon: s.datos.icon,
                text: s.datos.text,
                color: s.datos.color,
                width: s.datos.width,
                style: s.datos.style,
            };
        },
    },
    mounted() {
        this.caras.push(this.$refs.cara1);
        this.caras.push(this.$refs.cara2);
        this.caras.push(this.$refs.cara3);
        this.caras.push(this.$refs.cara4);
        this.caras.push(this.$refs.cara5);
    },
};
</script>
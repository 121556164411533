var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Pagos - "+_vm._s(_vm.plan ? _vm.plan.nombre : ""))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'pagos' })}}},[_vm._v(" Volver ")])],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pagos.filter((pp) => pp.status),"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.monto`,fn:function({ item }){return [_vm._v(" "+_vm._s("$" + _vm.getTotalPago(item).toFixed(2))+" ")]}},{key:`item.usuario`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.usuario.nombres + " " + item.usuario.apellidos)+" ")]}},{key:`item.fechaRegistro`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatearDateTimeIsoAMx(item.fechaRegistro))+" ")]}},{key:`item.folioFiscal`,fn:function({ item}){return [(item.folioFiscal)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.verDetalle(item.folioFiscal)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-details")]),_vm._v(" "+_vm._s(item.folioFiscal)+" ")],1):_vm._e()]}},{key:`item.acciones`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.pagoPlan = item;
                                _vm.dialogDetallePago = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])]),(_vm.usuario.isoPais == 'MX' && item.folioFiscal == null)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.pagoPlan = item;
                                _vm.openGenerarCFDI = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-file ")])],1)]}}],null,true)},[_c('span',[_vm._v("Generar CFDI")])])]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.pagoPlan = item;
                                _vm.dialogEditarPago = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.imprimirPago(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-printer ")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.pagoPlan = item;
                                _vm.dialogEnviar = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"yellow darken-2"}},[_vm._v(" mdi-email ")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar por correo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.pagoPlan = item;
                                _vm.confirmDelete = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Sin pagos")])])],2)],1),(_vm.pagos.find((pp) => !pp.status))?_c('div',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Pagos eliminados")])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersEliminados,"items":_vm.pagos.filter((pp) => !pp.status),"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.monto`,fn:function({ item }){return [_vm._v(" "+_vm._s("$" + _vm.getTotalPago(item).toFixed(2))+" ")]}},{key:`item.usuario`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.usuario.nombres + " " + item.usuario.apellidos)+" ")]}},{key:`item.fechaRegistro`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatearDateTimeIsoAMx(item.fechaRegistro))+" ")]}},{key:`item.fechaEliminacion`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatearDateTimeIsoAMx(item.fechaEliminacion))+" ")]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Sin prestaciones a pagar")])])],2)],1)],1):_vm._e(),(_vm.dialogEditarPago)?_c('dialog-editar-pago',{attrs:{"pagoPlan":_vm.pagoPlan,"plan":_vm.plan},on:{"cancel":function($event){_vm.dialogEditarPago = false},"success":function($event){_vm.dialogEditarPago = false;
            _vm.getPagos();}}}):_vm._e(),(_vm.dialogDetallePago)?_c('dialog-detalle-pago',{attrs:{"pagoPlan":_vm.pagoPlan,"plan":_vm.plan},on:{"cancel":function($event){_vm.dialogDetallePago = false},"success":function($event){_vm.dialogDetallePago = false;
            _vm.getPagos();}}}):_vm._e(),(_vm.confirmDelete)?_c('confirm',{on:{"cancel":function($event){_vm.confirmDelete = false},"confirm":function($event){_vm.confirmDelete = false;
            _vm.eliminar();}}}):_vm._e(),(_vm.dialogEnviar)?_c('dialog-enviar',{ref:"dialogEnviarCorreo",attrs:{"html2pdf":_vm.$refs.template.$refs.html2pdf,"titulo":"Envío de comprobante de pago"},on:{"close":function($event){_vm.dialogEnviar = false},"enviar":function($event){_vm.imprimir = false;
            _vm.correo = $event;
            _vm.$nextTick(function () {
                _vm.$refs.template.$refs.html2pdf.generatePdf();
            });}}}):_vm._e(),_c('pago-template',{ref:"template",attrs:{"pagoPlan":_vm.pagoPlan,"plan":_vm.plan,"imprimir":_vm.imprimir,"correo":_vm.correo},on:{"close":function($event){_vm.dialogEnviar = false}}}),(_vm.openGenerarCFDI)?_c('opcion-cfdi',{attrs:{"uuid":_vm.pagoPlan.uuid,"typename":_vm.typename},on:{"cancel":function($event){_vm.openGenerarCFDI= false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container style="max-width: 690px">
        <v-progress-linear
            indeterminate
            color="cyan"
            v-if="working"
        ></v-progress-linear>
        <v-toolbar-title
            class="text-color-title text--darken-4 text-xs-subtitle-2 text-lg-h6 text-wrap text-left"
        >
            Información general del paciente
        </v-toolbar-title>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        ref="name"
                        v-model="paciente.nombres"
                        :counter="32"
                        :rules="rulesNombre(32, true)"
                        label="Nombre"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="paciente.apPaterno"
                        :rules="rulesNombre(64, true)"
                        :counter="64"
                        label="Apellido paterno"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="paciente.apMaterno"
                        :rules="rulesNombre(64, false)"
                        :counter="64"
                        label="Apellido materno"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <v-menu
                        ref="menu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="paciente.fechaNacimiento"
                                label="Fecha de nacimiento"
                                readonly
                                v-on="on"
                                :rules="[(v) => !!v || 'Requerido']"
                                required
                            >
                            </v-text-field>
                            <div class="mt-n6 pt-0 text-left caption">
                                {{ age ? age.human : "" }}
                            </div>
                        </template>
                        <v-date-picker
                            v-model="paciente.fechaNacimiento"
                            no-title
                            scrollable
                            :max="maxDate"
                            :min="minDate"
                            @click="$refs.menu.save(fechaNacimiento)"
                            @input="dateMenu = false"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <v-switch
                        v-model="paciente.masculino"
                        class="mx-2"
                        label="Masculino"
                    ></v-switch>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6" >
                    <v-text-field
                        type="email"
                        v-model="paciente.correo"
                        :rules="emailRules"
                        label="Correo electrónico"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" v-bind:class="isMobile? 'pl-0':'pl-2'">
                    <v-text-field
                        v-model="paciente.telefono"
                        :rules="[
                            (v) => !!v || 'Requerido',
                            (v) =>
                                (v &&
                                    (paciente.pais.codigo != 'MX'
                                        ? /^[1-9][0-9]{5,15}$/.test(v)
                                        : v.length == maxCaracteres)) ||
                                'Es requerido y sólo se permiten números',
                        ]"
                        label="Teléfono"
                        required
                        :counter="maxCaracteres"

                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters >
                <v-col cols="12">
                    <v-select
                        v-model="paciente.pais"
                        :items="paises"
                        item-text="name"
                        item-value="id"
                        label="País"
                        return-object
                        required
                        :rules="[(v) => !!v || 'Requerido']"
                        dense
                        @change="onPaisChange"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="paciente.direccion"
                        :counter="256"
                        label="Calle y número"
                        required
                        clearable
                    ></v-text-field>
                </v-col>
            </v-row>
            <template v-if="!disabled">
                <v-row no-gutters>
                    <v-col cols="12">
                    <remote-search
                        url="/api/pacientes/colonias/"
                        label="Ingrese el código postal"
                        min="4"
                        placeholder="Búsqueda de colonia por código postal"
                        @change="onChange"
                        :text="text"
                        :disabled="disabled"
                        :tipo="tipo"
                        :busqueda="search"
                        :coloniaId="coloniaId"
                    ></remote-search>
                    </v-col>
                </v-row>
                <v-row v-if="paciente && paciente.coloniaId > 0" no-gutters>
                    <v-col cols="12">
                        <v-chip class="primary--text v-chip--active"
                            close @click:close="clearCP()"
                        >
                        <span class="text-truncate">
                            <strong class="mr-1">{{ paciente.cp + ","}}</strong>
                                {{ paciente.colonia + ", " + paciente.municipio +", "+paciente.estado }}
                        </span>
                        </v-chip>
                    </v-col>
                </v-row>

            </template>
            <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <v-btn
                    class="ma-2"
                    @click="
                        reset();
                        $emit('cancel');
                    "
                    v-if="!id"
                >
                    Cancelar
                </v-btn>


                <v-btn
                    class="ma-2"
                    @click="confirmDelete = true"
                    color="red lighten-4"
                    v-if="id && !disableDelete && !isGroup"
                >
                    Eliminar
                </v-btn>
                <v-btn
                    :disabled="!valid"
                    color="success"
                    class="ma-2"
                    @click="validate"
                >
                    Guardar
                </v-btn>
            </v-col>
            </v-row>
        </v-form>
        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                deleteEvent();
            "
        ></confirm>
    </v-container>
</template>

<script>
import PacienteService from "@/services/paciente.service.js";
import PacienteUtils from "@/pacienteUtils.js";
import RemoteSearch from "@/components/RemoteSearch";
import PATTERNS from "@/patterns.js"

function stringOrNull(o){
  return o?new String(o):null;
}

export default {
    name: "Paciente",
    components: {
        Confirm: () => import("@/components/Confirm"),
        "remote-search": RemoteSearch,
    },
    props: { disableDelete: { default: false } },
    data: () => ({
        paciente: { masculino: false, coloniaId: 0, telefono: "", pais: null },
        valid: false,
        id: 0,
        emailRules:[
        (v) =>
                  !v ||
                  (!!v &&
                    /.+@.+\..+/.test(v)) ||
                  'Correo electrónico inválido',
        ],
        direccionRules: [
            (v) => (v && v.length > 256) || "Máximo 256 caracteres",
        ],
        dateMenu: false,
        lazy: false,
        working: false,
        confirmDelete: false,
        paises: [],
        pais_defecto: undefined,
        text: "nombre",
        disabled: false,
        tipo: 1,
        search: "",
        maxCaracteres: 15,
        coloniaId: 0,
        required(){
            return (value) => !!value || 'Campo obligatorio';
        },
    }),

    computed: {
        isGroup: function(){
            return this.$store.state.group;
        },
        isMobile:function(){
            return this.$vuetify.breakpoint.name == 'xs';
        },
        maxDate: function () {
            return new Date().toISOString().substring(0, 10); // Nace hoy
        },
        minDate: function () {
            var d = new Date();
            d.setYear(d.getYear() - 110); // Máximo 110 años de edad
            return d.toISOString().substring(0, 10);
        },
        age: function () {
            if (this.paciente.fechaNacimiento) {
                let fn = this.paciente.fechaNacimiento.split("-");
                let nac = new Date(fn[0], fn[1] - 1, fn[2], 0, 0, 0, 0);
                let ag = PacienteUtils.obt_edad(nac, new Date());
                return ag;
            }
            return null;
        },
    },

    watch: {
        "paciente.masculino": function (g) {
            this.$emit("gender-change", g);
        },
        "paciente.telefono": function (value, oldval) {
            if (this.paciente && this.paciente.pais) {
                let max = this.paciente.pais.codigo != "MX" ? 15 : 10;
                let newValue = PacienteUtils.getValue(value, oldval, max);
                this.$nextTick(() => {
                    this.paciente.telefono = newValue;
                });
            }
        },
        "paciente.pais": function (val) {
            if (val) {
                this.$nextTick(() => {
                    this.maxCaracteres = val.codigo != "MX" ? 15 : 10;
                    if (this.paciente.telefono) {
                        this.paciente.telefono = this.paciente.telefono.substring(
                            0,
                            this.maxCaracteres
                        );
                    }
                });
            }
        },
    },

    methods: {
        clearCP: function () {
            this.asignarColonia(null, null, null, null, null);
        },
        onPaisChange: function (item) {
            this.disabled = item.codigo != "MX";
            this.paciente.pais = item;
        },
        onChange: function (val) {
            if (val) {
                this.asignarColonia(
                    val.id,
                    val.nombre,
                    val.cp,
                    val.municipio,
                    val.estado
                );
            }
        },
        onClear: function (val) {
            this.clearCP();
        },
        searchAndSetPais: function (paisId) {
            this.paises.forEach((element) => {
                if (element.id == paisId) {
                    this.pais_defecto = {
                        id: element.id,
                        name: element.name,
                        codigo: element.codigo,
                    };
                    this.paciente.pais = this.pais_defecto;
                }
            });
        },
        asignarColonia: function (id, nombre, codigoPostal, municipio, estado) {
            this.$nextTick(() => {
                this.paciente.coloniaId = id;
                this.paciente.municipio = municipio;
                this.paciente.colonia = nombre;
                this.paciente.estado = estado;
                this.paciente.cp = codigoPostal;
            });
        },
        validate() {
            if (this.$refs.form.validate()) {
                let apMaterno = stringOrNull(this.paciente.apMaterno);
                let apellidos = (this.paciente.apPaterno + " " + (apMaterno == null? "":apMaterno)).trim();

                var params = {
                    nombres: this.paciente.nombres,
                    apPaterno: this.paciente.apPaterno,
                    apMaterno: apMaterno,
                    fechaNacimiento: this.paciente.fechaNacimiento,
                    masculino: this.paciente.masculino,
                    correo: this.paciente.correo,
                    telefono: this.paciente.telefono,
                    paisId: this.paciente.pais.id,
                    id: this.id,
                    direccion: this.paciente.direccion,
                    coloniaId: this.paciente.coloniaId,
                };

                PacienteService.guardar(params).then((data) => {

                    if (data && data > 0) {
                        this.$store.commit("paciente", {
                            nombres: this.paciente.nombres,
                            apellidos: apellidos,
                            email: this.paciente.correo,
                            masculino: this.paciente.masculino,
                            age: this.age,
                            nacimiento: this.paciente.fechaNacimiento,
                        });

                        this.$root.showMessage("El paciente ha sido guardado", "success");
                        if (!this.id) {
                            this.$emit('new-patient',{id: data, name: this.paciente.nombres+' '+apellidos})
                            this.reset();
                        }
                    } else {
                        this.$root.showMessage('No se pudo '+(this.id?'editar':'crear')+' el paciente','error');
                    }
                });
            }
        },
        deleteEvent: function () {
            if (this.id) {
                PacienteService.eliminar(this.id).then((data) => {
                    if (data && data > 0) {
                        this.$router.push({ name: "agenda" });
                        this.reset();
                    }
                    else {
                        this.$root.showMessage('El paciente no pudo eliminarse','error');
                    }
                });
            }
        },
        reset() {
            //this.id=null;
            this.paciente.masculino = false;
            this.$refs.form.reset();
            this.clearCP();
            if (this.pais_defecto) {
                this.$nextTick(() => {
                    this.paciente.pais = this.pais_defecto;
                });
            }

        },
        error: function (err) {
            this.$root.showMessage(err);
        },
        setColonia: function (id, nombre, cp, municipio, estado) {},
        rulesNombre(length, required){
            var rules = [];
            let match = (value)=>(value && value.trim().length > 3 && value.trim().length <= length && PATTERNS.NOMBRES.test(value)) || `Formato inválido, máximo ${length} caracteres`;
            if(required){
                rules.push(this.required());
            }
            else{
                match =(value) => ( !value || value.trim().length >3 && value.trim().length <= length && PATTERNS.NOMBRES.test(value)) || `Formato inválido, máximo ${length} caracteres`;
            }
            rules.push(match);
            return rules;
        },

    },
    beforeMount() {
        this.id = this.$route.params.pacienteId;
        if (this.id) {
            this.working = true;

            PacienteService.obtGenerales(this.id).then((data) => {
                if (data && data.patient) {
                    this.paciente = data.patient;
                    this.paises = data.paises;
                    if (this.paciente.cp) {
                        this.search = this.paciente.cp.toString();
                    }

                    if (this.paciente.coloniaId) {
                        this.coloniaId = this.paciente.coloniaId;
                    }
                    this.searchAndSetPais(this.paciente.paisId);
                }

            }).then(function(){
                this.working = false;
            }.bind(this));
        } else {
            PacienteService.findPaisesDefecto().then((data) => {
                if (data) {
                    this.paises = data;

                    data.forEach((element) => {
                        if (element.defecto && element.defecto == element.id) {
                            this.pais_defecto = {
                                id: element.id,
                                name: element.name,
                                codigo: element.codigo,
                            };
                            this.paciente.pais = this.pais_defecto;
                        }
                    });
                }
            });
        }
    },
    mounted() {
        this.$refs.name.focus();
    },
};
</script>
<template>
    <v-container class="mt-0 mx-auto">
        <div class="mycontent">
            <div class="cover-div">
                <div class="cover-title">
                    El sistema para profesionales en odontología
                </div>
                <div class="cover-text">
                    Mantén comunicación con tus pacientes y ten al día su
                    expediente; administra tu agenda y promociona tu consulta
                    médica para llegar a más pacientes.
                </div>
            </div>
            <div class="text-center cover">
                <img src="assets/img/computadora.jpg" class="photo" />
            </div>

            <div class="product-info">
                <div>
                    <h3>Página personal</h3>
                    Crea tu página web de contacto con información relevante de
                    tu consulta para que posibles pacientes te encuentren.
                </div>
                <div>
                    <h3>Directorio</h3>
                    Permite que nuevos pacientes te encuentren, así como a tus
                    promociones y servicios.
                </div>

                <div>
                    <h3>Opiniones</h3>
                    Conoce que piensan de tu consulta tus pacientes y demuestra
                    tu experiencia al contestar preguntas que tengan en línea.
                </div>
            </div>
        </div>

        <div class="mt-6 text-center">
            <div class="mb-4">
                <v-btn color="info" @click="goToPanel">Iniciar sesión</v-btn>
            </div>
            <div>
                <v-btn color="success" @click="registrar">Registrarme</v-btn>
            </div>
        </div>
    </v-container>
</template>
<style scoped>
.mycontent {
    position: relative;
}

.product-info > div {
    background-color: white;
    padding: 10px;
    min-width: 33%;
    margin-top: 8px;
}
    .photo {
        margin-left: auto;
        margin-right: auto;

    }
@media only screen and (max-width: 600px) {
    img {
        max-width: 300px;
    }
    .cover {
        max-width: 98vw;
    }
    .cover-title {
        color: darkslategray;
        font-size: large;
        font-weight: bold;
        text-align: center;
        padding: 0 8px 0 8px;
    }
    .cover-text {
        font-size: small;
        text-align: center;
        padding: 0 32px 0 32px;
        margin-bottom: 8pt;
    }
    .product-info {
        display: flex;
        justify-content: space-between;
        flex-basis: 20%;
        flex-flow: column;
    }
}
@media only screen and (min-width: 600px) {
    .product-info {
        display: flex;
        justify-content: space-between;
        flex-basis: 20%;
        flex-flow: row;
    }
    img {
        max-width: 400px;
    }
    .cover {
        width: 99%;
    }
    .cover-title {
        color: darkslategray;
        font-size: xx-large;
        font-weight: bold;
        text-align: center;
    }
    .cover-text {
        margin-top: 2pt;
        font-size: large;
        text-align: center;
        padding: 0 32px 0 32px;
        margin-bottom: 8pt;
    }
}
</style>
<!--
https://unsplash.com/photos/gFixufaXB0k?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink
-->
<script>
export default {
    data: () => ({}),
    methods: {
        goToPanel() {
            window.location.href = process.env.VUE_APP_SSO_URL;
        },
        registrar() {
            window.location.href = "/registro.html";
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.planes,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.fecha`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatearFechaIsoAMx(item.fecha)))])]}},{key:`item.usuario`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.usuario.nombres + " " + item.usuario.apellidos))])]}},{key:`item.costo`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getTotalFormateado(item)))])]}},{key:`item.saldo`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold",class:_vm.getSaldo(item) > 0
                        ? 'green--text'
                        : _vm.getSaldo(item) < 0
                        ? 'red--text'
                        : ''},[_vm._v("$"+_vm._s(_vm.getSaldo(item).toFixed(2)))])]}},{key:`item.progreso`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getProgreso(item)))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){_vm.plan = item;
                    _vm.confirmDelete = true;}}},[_vm._v(" mdi-delete ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Planes de tratamiento")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success"},on:{"click":_vm.nuevoPlan}},[_vm._v(" Nuevo plan de tratamiento")])],1)]},proxy:true}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Sin planes de tratamiento")])])],2),(_vm.confirmDelete)?_c('confirm',{on:{"cancel":function($event){_vm.confirmDelete = false},"confirm":function($event){_vm.confirmDelete = false;
            _vm.eliminar();}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card class="mx-16 mt-6">
        <v-card-title> Categorías de prestaciones </v-card-title>
        <v-data-table
            :headers="headers"
            :items="categorias"
            :items-per-page="10"
            :search="busqueda"
            style="width: 100%"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:top>
                <v-toolbar flat dense>
                    <v-text-field
                        v-model="busqueda"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        style="max-width: 40%"
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        class="mb-2"
                        @click="
                            categoria = {};
                            dialogCategoria = true;
                        "
                    >
                        Nueva categoría
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.ver`]="{ item }">
                <v-icon color="green" @click="ver(item)"> mdi-eye </v-icon>
            </template>
            <template v-slot:[`item.editar`]="{ item }">
                <v-icon color="primary" @click="editar(item)">
                    mdi-pencil
                </v-icon>
            </template>
            <template v-slot:[`item.eliminar`]="{ item }">
                <v-icon
                    color="red"
                    @click="
                        categoria = item;
                        confirmDelete = true;
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
            <template slot="no-data">
                <div>Sin categorías</div>
            </template>
        </v-data-table>

        <dialog-categoria
            v-if="dialogCategoria"
            :categoria="categoria"
            @close="dialogCategoria = false"
            @success="
                dialogCategoria = false;
                loadCategorias();
            "
        ></dialog-categoria>

        <confirm
            v-if="confirmDelete"
            @cancel="confirmDelete = false"
            @confirm="
                confirmDelete = false;
                eliminar();
            "
        ></confirm>
    </v-card>
</template>

<script>
import DialogCategoria from "../components/DialogCategoria.vue";
import Confirm from "../components/Confirm.vue";
import { Urls } from "@/consts.js";

export default {
    data: () => ({
        categorias: [],
        categoria: {},
        dialogCategoria: false,
        confirmDelete: false,
        busqueda: "",
        headers: [
            { text: "Categoría", value: "nombre", width: "60%" },
            {
                text: "Ver/Editar Prestaciones",
                value: "ver",
                sortable: false,
            },
            { text: "Editar categoría", value: "editar", sortable: false },
            { text: "Eliminar categoría", value: "eliminar", sortable: false },
        ],
    }),
    components: {
        DialogCategoria,
        Confirm,
    },
    methods: {
        loadCategorias() {
            this.$http
                .get(Urls.PRESTACIONES_URL + "/categorias")
                .then((data) => {
                    if (!data) return;
                    this.categorias = data;
                });
        },
        editar(cat) {
            this.categoria = JSON.parse(JSON.stringify(cat));
            this.dialogCategoria = true;
        },
        eliminar() {
            this.$http
                .delete(
                    Urls.PRESTACIONES_URL +
                        "/deleteCategoria/" +
                        this.categoria.id
                )
                .then((data) => {
                    if (!data) return;
                    this.$root.showMessage("Categoría eliminada exitosamente");
                    this.loadCategorias();
                });
        },
        ver(cat) {
            this.$router.push({
                name: "prestaciones",
                params: { categoriaId: cat.id },
            });
        },
    },
    mounted() {
        this.loadCategorias();
    },
};
</script>
